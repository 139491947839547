import powered_by_tarrakki from '../../assets/elevo/powered_by_tarrakki.svg';
import { useAppSelector } from '../../redux/hooks';
import config from '../../whitelabel-config';

const Logo = () => {
  const { mf } = useAppSelector((state) => state.whitelabelReducers);

  const logo = mf?.logo ?? config.LOGO;
  let powered_by = mf?.powered_by;
  
  if(!logo){
    if (typeof powered_by !== 'boolean') powered_by = config.POWERED_BY_BADGE;
  }
  
  return (
    <>
      <div className='header_logo_wrapper'>
        <img src={logo} className={'logo'} alt={'App Logo'} />
        {powered_by && (
          <>
            <div className='vertical_line'></div>
            <div className='powered_by_wrapper'>
              <p>powered by</p>
              <img
                src={powered_by_tarrakki}
                className={'powered_by_logo'}
                alt='powered by tarrakki'
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Logo;
