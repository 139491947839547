import React, { useState, useMemo, useEffect } from 'react';
import '../../../src/scss/InvestFundAll.scss';

import {
  Accordion,
  Button,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import { getFunds } from '../../api/fund';
import { CheckboxGroups, DropdownList, RadioGroups } from '../../common/form';
import InvestFundList from '../../component/invest-fund-list/InvestFundList';
import { decryptData } from '../../utility/DecryptHelper';
import { FundSizeList, returnList } from '../../utility/utility';
import serachIcon from '../../assets/search.svg';
import { resultLimit } from '../../utility/constant';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
import { useLocation, useHistory } from 'react-router-dom';

const InvestFundAll = () => {
  const history = useHistory();
  const location = useLocation();
  const data = location.state;
  const qsParams = new URLSearchParams(location.search);
  const subCategoriesParam = qsParams.get('sub_categories');
  const nfoParam = qsParams.get('nfo');
  const categoriesParam = qsParams.get('categories');
  const riskLevelParam = qsParams.get('risk_level_ids');
  const amsIdsParam = qsParams.get('amc_ids');
  const fundSizeParam = qsParams.get('fund_size')
  const [returnObj, setReturnObj] = useState({
    label: '3Y Returns',
    value: 'ttr_return_3_yr',
    mobileLabel: '3Y%',
  });
  const [fundsResponse, setFundsResponse] = useState({});
  const [fundsList, setFundList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [amcString, setAmcSearchString] = useState('');
  const [amcFilteredList, setAmcFilteredList] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const dicFilter = useMemo(() => {
    const filters = {
      sub_categories: subCategoriesParam ? subCategoriesParam.split(',') : [],
      categories: categoriesParam ? categoriesParam.split(',') : [],
      risk_level_ids: riskLevelParam ? riskLevelParam.split(',') : [],
      amc_ids: amsIdsParam ? amsIdsParam.split(',') : [],
      fund_size: fundSizeParam ? fundSizeParam : [],
    };

    if (nfoParam && nfoParam === '1') {
      filters.nfo = +nfoParam;
    }

    return filters;
  }, [
    subCategoriesParam,
    nfoParam,
    categoriesParam,
    riskLevelParam,
    amsIdsParam,
    fundSizeParam,
  ]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (data && data.fundsList && data.fundsResponse) {
      setFundsResponse(data.fundsResponse);
      setFundList(data.fundsList);
    } else {
      getFundsApi(dicFilter, returnObj.value);
    }
  }, [data, dicFilter, returnObj.value]);

  const getFundsApi = (filter, sortBy, offset) => {
    const escapedSearchStrig = encodeURIComponent(searchString);
    getFunds(
      resultLimit,
      offset,
      escapedSearchStrig,
      sortBy,
      getFilterString(filter),
      amcString
    ).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        const risks = data.risk_levels.filter((risk) => {
          return risk.is_active === true;
        });
        data.risk_levels = risks;
        setFundsResponse(data);
        setApiCall(false);
        if (offset) {
          setFundList([...fundsList, ...data.funds]);
        } else {
          setFundList(data.funds);
        }
      }
    });
  };

  const getFilterString = (filter) => {
    const arrFilter = [];
    Object.keys(filter).forEach((key) => {
      if (key === 'nfo') {
        arrFilter.push(`"${key}":${filter[key]}`);
      } else if (key === 'fund_size') {
        arrFilter.push(`"${key}":${filter[key] ? JSON.stringify(filter[key]) : "[]"}`);
      } else {
        arrFilter.push(`"${key}":[${filter[key]}]`);
      }
    });
    return arrFilter.length ? '{' + arrFilter.join(',') + '}' : null;
  };
  const onNfoChange = (event) => {
    const { checked } = event.target;
    const value = checked ? 1 : 0;
    if (value) {
      qsParams.set('nfo', value);
    } else {
      qsParams.delete('nfo');
    }
    history.replace({
      pathname: location.pathname,
      search: qsParams.toString(),
    });
  };

  const setQSFilter = (key, values) => {
    const finalValue = Array.isArray(values) ? values.join(',') : values;

    if (finalValue) {
      qsParams.set(key, finalValue);
    } else {
      qsParams.delete(key);
    }
    history.replace({
      pathname: location.pathname,
      search: qsParams.toString(),
    });
  };  

  const resetAllFilter = () => {
    getFundsApi({}, returnObj.value, 0);
    setAmcSearchString('');
    setAmcFilteredList([]);
    history.replace(location.pathname);
  };
  const onReturnChange = (event, item) => {
    event.preventDefault();
    setReturnObj(item);
    getFundsApi(dicFilter, item.value);
  };

  const searchOnClick = () => {
    if (apiCall) {
      getFundsApi(dicFilter, returnObj.value, 0);
    }
  };
  const enterHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      searchOnClick();
    }
  };
  const searchAmcOnKeydown = (event) => {
    const { keyCode } = event;
    if (keyCode === 13) {
      getFundsApi(dicFilter, returnObj.value, 0);
    }
  };
  const filterAmcList = (event) => {
    const { value } = event.target;
    setAmcSearchString(value);
    if (value && value.length > 2 && fundsResponse.amc_names) {
      const filterList = fundsResponse.amc_names.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setAmcFilteredList(filterList);
    } else {
      setAmcFilteredList([]);
    }
  };
  const handlePagination = () => {
    getFundsApi(dicFilter, returnObj.value, fundsResponse.offset);
  };

  const redirectToFilter = () => {
    history.push('/funds/mobilefundlist');
  };

  return (
    <>
      {windowDimensions.width > 480 ? (
        <div className='container invest-funds-container pb-5'>
          <div className='row justify-content-end'>
            <div className='col-sm-8 font-weight-bold h5 mb-3'>
              All Mutual Funds
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-3'>
              <div className='border rounded'>
                <div className='d-flex align-items-center justify-content-between p-3'>
                  <span className='font-weight-medium'>Filters</span>
                  <span
                    className='c-blue cursor-pointer'
                    onClick={resetAllFilter}
                  >
                    Clear All
                  </span>
                </div>
                {fundsResponse.amc_names &&
                fundsResponse.amc_names.length > 0 ? (
                  <div className='category-container border-top p-3'>
                    <Accordion defaultActiveKey='0'>
                      <Accordion.Toggle
                        as={'div'}
                        className='d-flex justify-content-between'
                        eventKey='0'
                      >
                        <span className='font-weight-bold h6'>AMC</span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='0'>
                        <>
                          <FormGroup className='position-relative'>
                            <img
                              src={serachIcon}
                              alt='searchIcon'
                              className='search-input icon'
                            />
                            <FormControl
                              placeholder='Search Mutual Funds Companies'
                              aria-label='Search Mutual Funds Companies'
                              className='pl-5 py-1 rounded-0'
                              aria-describedby='Search Mutual Funds Companies'
                              onChange={filterAmcList}
                              onKeyDown={searchAmcOnKeydown}
                              value={amcString}
                            />
                          </FormGroup>
                          <div className='fund-accordinan mt-3'>
                            <CheckboxGroups
                              title='AMC'
                              id='amc_id'
                              keyId='id'
                              keyValue='name'
                              checkboxList={
                                amcFilteredList.length
                                  ? amcFilteredList
                                  : fundsResponse.amc_names
                              }
                              selectedValues={
                                amsIdsParam ? amsIdsParam.split(',') : []
                              }
                              onCheckboxUpdate={(value) => {
                                setQSFilter('amc_ids', value);
                              }}
                            />
                          </div>
                        </>
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                ) : null}
                <div className='nfo-container border-top p-3'>
                  <Form.Check
                    className='my-2 font-weight-medium'
                    name='nfo'
                    type='checkbox'
                    label='NFO'
                    checked={dicFilter.nfo === 1}
                    value={dicFilter.nfo}
                    id='nfo-checkbox'
                    onChange={onNfoChange}
                  />
                </div>
                {fundsResponse.fscbi_broad_category_list &&
                fundsResponse.fscbi_broad_category_list.length > 0 ? (
                  <div className='category-container border-top p-3'>
                    <Accordion defaultActiveKey='0'>
                      <Accordion.Toggle
                        as={'div'}
                        className='d-flex justify-content-between'
                        eventKey='0'
                      >
                        <span className='font-weight-bold h6'>Category</span>
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey='0'
                        className='fund-accordinan'
                      >
                        <CheckboxGroups
                          title='Category'
                          id='Category'
                          keyId='id'
                          keyValue='name'
                          checkboxList={fundsResponse.fscbi_broad_category_list}
                          selectedValues={
                            dicFilter.categories ? dicFilter.categories : []
                          }
                          onCheckboxUpdate={(value) => {
                            setQSFilter('categories', value);
                          }}
                        />
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                ) : null}
                {fundsResponse.fscbi_category_list &&
                fundsResponse.fscbi_category_list.length > 0 ? (
                  <div className='sub-category-container border-top p-3'>
                    <Accordion defaultActiveKey='0'>
                      <Accordion.Toggle
                        as={'div'}
                        className='d-flex justify-content-between'
                        eventKey='0'
                      >
                        <span className='font-weight-bold h6'>
                          Sub Category
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey='0'
                        className='fund-accordinan'
                      >
                        <CheckboxGroups
                          title='SubCategory'
                          id='SubCategory'
                          keyId='id'
                          keyValue='name'
                          checkboxList={fundsResponse.fscbi_category_list}
                          selectedValues={
                            dicFilter.sub_categories
                              ? dicFilter.sub_categories
                              : []
                          }
                          onCheckboxUpdate={(value) => {
                            setQSFilter('sub_categories', value);
                          }}
                        />
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                ) : null}
                {fundsResponse.risk_levels &&
                fundsResponse.risk_levels.length > 0 ? (
                  <div className='risk-container border-top p-3'>
                    <Accordion defaultActiveKey='0'>
                      <Accordion.Toggle
                        as={'div'}
                        className='d-flex justify-content-between'
                        eventKey='0'
                      >
                        <span className='font-weight-bold h6'>Risk</span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey='0'>
                        <CheckboxGroups
                          title='risk'
                          id='risk'
                          keyId='id'
                          keyValue='name'
                          checkboxList={fundsResponse.risk_levels}
                          selectedValues={
                            dicFilter.risk_level_ids
                              ? dicFilter.risk_level_ids
                              : []
                          }
                          onCheckboxUpdate={(values) => {
                            setQSFilter('risk_level_ids', values);
                          }}
                        />
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                ) : null}
                <div className='fund-size-container border-top p-3'>
                  <Accordion defaultActiveKey='0'>
                    <Accordion.Toggle
                      as={'div'}
                      className='d-flex justify-content-between'
                      eventKey='0'
                    >
                      <span className='font-weight-bold h6'>Fund Size</span>
                    </Accordion.Toggle>
                      <Accordion.Collapse eventKey='0'>
                        <RadioGroups
                          id="FundSize"
                          radioList={FundSizeList}
                          selectedVal={dicFilter.fund_size || []}
                          onChange={(event) => {
                            setQSFilter('fund_size', event.target.value);
                          }}
                        />
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                </div>
              </div>
  
              <div className='col-sm-9 border rounded px-0 invest-fund-container'>
                <div className='d-flex align-items-center justify-content-between py-3'>
                  <div className='col-sm-8'>
                    <InputGroup className="new-input-group position-relative">
                      <img
                        src={serachIcon}
                        alt="searchIcon"
                        className="search-input icon"
                      />
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        className="pl-5"
                        aria-describedby="Search"
                        onChange={(event) => {
                          setSearchString(event.target.value);
                          setApiCall(true);
                        }}
                        value={searchString}
                        onKeyDown={enterHandler}
                      />
                      <InputGroup.Append>
                        <Button 
                          variant="primary" 
                          id="Search" 
                          onClick={searchOnClick}
                          className="search-btn"
                        >
                          {windowDimensions.width <= 480 ? 'Go' : 'Search'}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                  <div className="col-sm-4">
                    <div className="dropdown-list-wrapper">
                      <DropdownList
                        selectedOption={returnObj.label}
                        id="return-type"
                        label="Sort By:"
                        classes={{
                          input: "form-control py-2",
                          selectedLabel: "c-blue",
                          label: "sort-by-label",
                        }}
                        placeholder="Return"
                        controlFunc={onReturnChange}
                        options={returnList}
                      />
                    </div>
                  </div>
                </div>
                <div className='invest-fund-list-container'>
                  <InvestFundList
                    invesrFundItems={fundsList}
                    loadMore={
                      fundsResponse.total_funds > fundsResponse.offset
                        ? handlePagination
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='bg-light-grey pt-4'>
            <div className=' row justify-content-end'>
              <div className='col-sm-8 font-weight-bold h4 mb-3 text-center'>
                All Mutual Funds
              </div>
            </div>
            <div className='col-sm-7 w-90 mx-4'>
              <InputGroup className=' new-input-group'>
                <img
                  src={serachIcon}
                  alt='searchIcon'
                  className='search-input icon'
                />
                <FormControl
                  placeholder='Search'
                  aria-label='Search'
                  className='pl-5'
                  aria-describedby='Search'
                  onChange={(event) => {
                    setSearchString(event.target.value);
                    setApiCall(true);
                  }}
                  value={searchString}
                  onKeyDown={enterHandler}
                />
                <InputGroup.Append>
                  <Button variant='primary' id='Search' onClick={searchOnClick}>
                    Search
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
            <div className='colored-box my-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='pt-4'>
                  <button
                    className='btn bg-light-blue br-light ml-3 c-blue my-2'
                    onClick={redirectToFilter}
                  >
                    FILTER-SORT
                  </button>
                </div>
                <div className='pt-4 float-right mr-3'>
                  <DropdownList
                    selectedOption={returnObj.mobileLabel}
                    id='return-type'
                    classes={{
                      input: 'border-0  form-control py-2 ',
                      selectedLabel: 'c-blue p-2',
                    }}
                    placeholder='Return'
                    controlFunc={onReturnChange}
                    options={returnList}
                  />
                </div>
              </div>
              <div>
                <InvestFundList
                  invesrFundItems={fundsList || (data && data.fundsList)}
                  loadMore={
                    fundsResponse.total_funds > fundsResponse.offset
                      ? handlePagination
                      : null
                  }
                  sortBy={returnObj.value}
                />
              </div>
            </div>
            <MobileNavigation />
          </div>
        )}
      </>
    );
  };
  
  export default InvestFundAll;