import React, { useState, useEffect } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { getFunds } from '../../api/fund';
import { CheckboxGroups, RadioGroups } from '../../common/form';

import { decryptData } from '../../utility/DecryptHelper';
import {
  NfoList,
  FundSizeList,
} from '../../utility/utility';
import serachIcon from '../../assets/search.svg';
import { resultLimit } from '../../utility/constant';
import { useHistory } from 'react-router-dom';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
const MobileFundListFilter = () => {
  const [dicFilter, setDicFilter] = useState({});
  const [fundsResponse, setFundsResponse] = useState({});
  const [fundsList, setFundList] = useState([]);
  const [amcString, setAmcSearchString] = useState('');
  const [amcFilteredList, setAmcFilteredList] = useState([]);
  const [filterId, setFilterId] = useState(fundsResponse.amc_names);
  const [filterCategory, setFilterCategory] = useState('');
  const history = useHistory();
  const getFilterString = (filter) => {
    const arrFilter = [];
    Object.keys(filter).forEach((key) => {
      if (key === 'nfo') {
        arrFilter.push(`"${key}":${filter[key]}`);
      } else if (key === 'fund_size') {
        arrFilter.push(`"${key}":${filter[key]}`);
      } else {
        arrFilter.push(`"${key}":[${filter[key]}]`);
      }
    });
    return arrFilter.length ? '{' + arrFilter.join(',') + '}' : null;
  };

  const setFilter = (key, value) => {
    const filter = dicFilter;
    if (key === 'nfo') {
      const nfoValue = value ? 1 : 0;
      filter[key] = nfoValue;
    } else {
      if (value) {
        filter[key] = value;
      } else {
        delete filter[key];
      }
    }
    setDicFilter(filter);
    getFundsApi(filter);
  };

  useEffect(() => {
    getFundsApi({});
  }, []);

  const getFundsApi = (filter, sortBy, offset) => {
    const escapedSearchStrig = encodeURIComponent('');
    getFunds(
      resultLimit,
      offset,
      escapedSearchStrig,
      sortBy,
      getFilterString(filter),
      amcString
    ).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        const risks = data.risk_levels.filter((risk) => {
          return risk.is_active === true;
        });
        data.risk_levels = risks;
        setFundsResponse(data);
        if (offset) {
          setFundList([...fundsList, ...data.funds]);
        } else {
          setFundList(data.funds);
        }
      }
    });
  };

  const resetAllFilter = () => {
    getFundsApi({}, 0);
    setDicFilter({});
    setAmcSearchString('');
    setAmcFilteredList([]);
  };

  const searchAmcOnKeydown = (event) => {
    const { keyCode } = event;
    if (keyCode === 13) {
      getFundsApi(dicFilter, 0);
    }
  };
  const filterAmcList = (event) => {
    const { value } = event.target;
    setAmcSearchString(value);
    if (value && value.length > 2 && filterId) {
      const filterList = filterId.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setAmcFilteredList(filterList);
    } else {
      setAmcFilteredList([]);
    }
  };

  const getFilterCategory = (event) => {
    const { id } = event.target;
    if (id === 'amc_names') {
      setFilterId(fundsResponse.amc_names);
      setFilterCategory('amc_ids');
    } else if (id === 'fscbi_broad_category_list') {
      setFilterId(fundsResponse.fscbi_broad_category_list);
      setFilterCategory('categories');
    } else if (id === 'fscbi_category_list') {
      setFilterId(fundsResponse.fscbi_category_list);
      setFilterCategory('sub_categories');
    } else if (id === 'risk_levels') {
      setFilterId(fundsResponse.risk_levels);
      setFilterCategory('risk_level_ids');
    } else if (id === 'fund_size_list') {
      setFilterId(FundSizeList);
      setFilterCategory('fund_size');
    } else if (id === 'nfo') {
      setFilterId(NfoList);
      setFilterCategory('nfo');
    }
  };
  const redirectToFunds = () => {
    let data = {
      fundsResponse: fundsResponse,
      fundsList: fundsList,
    };

    const params = new URLSearchParams('');
    if (dicFilter.amc_ids) {
      params.set('amc_ids', dicFilter.amc_ids.join(','));
    }

    if (dicFilter.risk_level_ids) {
      params.set('risk_level_ids', dicFilter.risk_level_ids.join(','));
    }

    if (dicFilter.categories) {
      params.set('categories', dicFilter.categories.join(','));
    }

    if (dicFilter.sub_categories) {
      params.set('sub_categories', dicFilter.sub_categories.join(','));
    }

    if (dicFilter.nfo) {
      params.set('nfo', '1');
    }
    history.push({
      pathname: '/funds/all',
      state: data,
      search: params.toString(),
    });
  };

  return (
    <div>
      <div>
        <div className=' text-center  mb-3 mx-4'>
          <h4 className=' font-weight-bold d-inline-block'>Filter-Sort</h4>
          <span
            className='c-blue cursor-pointer float-right mr-4 ml-0'
            onClick={resetAllFilter}
          >
            Clear All
          </span>
        </div>
      </div>
      <div className='colored-box mx-2 py-3 '>
        <div className='row '>
          <div className='d-flex '>
            <div className=' align-items-center justify-content-between border-right px-3 ml-3'>
              <div
                className='font-weight-medium py-3  border-bottom'
                id='amc_names'
                onClick={getFilterCategory}
              >
                AMC
              </div>
              <div
                className='font-weight-medium py-3 border-bottom'
                id='nfo'
                onClick={getFilterCategory}
              >
                NFO
              </div>
              <div
                className='fon-weight-medium py-3 border-bottom'
                id='fscbi_broad_category_list'
                onClick={getFilterCategory}
              >
                Category
              </div>
              <div
                className='font-weight-medium py-3 border-bottom'
                id='fscbi_category_list'
                onClick={getFilterCategory}
              >
                Sub Category
              </div>
              <div
                className='font-weight-medium py-3 border-bottom'
                id='risk_levels'
                onClick={getFilterCategory}
              >
                Risk
              </div>
              <div
                className='font-weight-medium py-3 border-bottom'
                id='fund_size_list'
                onClick={getFilterCategory}
              >
                Fund Size
              </div>
            </div>
            <div>
              {filterId && filterId.length > 0 ? (
                <div className='d-flex  px-3'>
                  <div className=''>
                    <FormGroup className='position-relative my-3'>
                      <img
                        src={serachIcon}
                        alt='searchIcon'
                        className='search-input icon'
                      />
                      <FormControl
                        placeholder='Search Mutual Funds Companies'
                        aria-label='Search Mutual Funds Companies'
                        className='pl-5 py-1 rounded-0'
                        aria-describedby='Search Mutual Funds Companies'
                        onChange={filterAmcList}
                        onKeyDown={searchAmcOnKeydown}
                        value={amcString}
                      />
                    </FormGroup>
                    <div className='mt-3  border-bottom '>
                      {filterCategory && filterCategory === 'fund_size' ? (
                        <RadioGroups
                          id='FundSize'
                          radioList={filterId}
                          selectedVal={String(dicFilter.fund_size || '')}
                          onChange={(event) => {
                            setFilter(filterCategory, event.target.value);
                          }}
                        />
                      ) : (
                        <CheckboxGroups
                          title='AMC'
                          id='amc_id'
                          keyId='id'
                          keyValue='name'
                          checkboxList={
                            amcFilteredList.length ? amcFilteredList : filterId
                          }
                          selectedValues={
                            dicFilter.amc_ids ? dicFilter.amc_ids : []
                          }
                          onCheckboxUpdate={(value) => {
                            setFilter(filterCategory, value);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='btn-container text-center my-4 col-sm-12'>
            <button
              type='button'
              className='btn btn-outline-primary mr-2 btn-width'
              onClick={redirectToFunds}
            >
              cancel
            </button>
            <button
              type='button'
              className='btn btn-primary ml-2 btn-width'
              onClick={redirectToFunds}
            >
              Apply
            </button>
          </div>
        </div>
        <MobileNavigation />
      </div>
    </div>
  );
};

export default MobileFundListFilter;
