import React, { useEffect, useState, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { formatInput, BankListTypeNri, BankListTypeNre, BankListTypeNro } from '../../utility/utility';
import supportedIcon from '../../assets/supported.svg';
import notSupportedIcon from '../../assets/not-supported.svg';
import DropdownList from '../../common/form/dropdown-list/DropdownList';
import UploadBox from '../../common/form/upload-box/UploadBox';
import { getBankDetail, postInvestorsBankDetails, putInvestorsBankDetails, getInvestorBankDetails } from '../../api/bank';
import { decryptData } from '../../utility/DecryptHelper';
import { loadBankList } from '../../redux/reducers/onboarding-reducer';

const NewBankNri = React.forwardRef(({ alignment = 'center', onBankAdd, show, onBankSubmitted, addNew, bankDetail }, ref) => {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.authReducer.user_id);
  const bankList = useSelector((state) => state.onboardingReducer.bankList);
  const taxStatus = useSelector((state) => state.onboardingReducer.pan_kyc_status);
  const [bankNameObj] = useState({});
  const [bankTypeObj, setBankType] = useState({});
  const [accountNo, setAccountNo] = useState('');
  const [confirmAccountNo, setConfirmAccountNo] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [file, setFile] = useState(null);
  const [verificationDoc, setDocName] = useState('');
  const [errData, setErrData] = useState([]);
  const [bankId, setBankId] = useState(null);
  const [bankData, setBankData] = useState({});
  const [detailsChange, setDetailsChange] = useState(false);
  useEffect(() => {
    if (bankList && !bankList.length) {
      getBankDetail().then((res) => {
        if (res && res.data) {
          const bankListData = decryptData(res.data.data);
          dispatch(loadBankList(bankListData.data));
        }
      });
    }
    if (bankDetail) {
      setBankDetails();
    }
    if (!addNew) getUserBankListNri();
  }, []);

  const setBankDetails = () => {
    const bankType = BankListTypeNri?.find((item) => item.type === bankDetail.account_type_bse);
    console.log('bankType', bankType);
    setAccountNo(bankDetail.account_number);
    setConfirmAccountNo(bankDetail.account_number);
    setIfscCode(bankDetail.branch__ifsc_code);
    if (bankType) {
      setBankType({ label: bankType?.label, value: bankType?.value });
    }
    setDocName(bankDetail.verification_document);
    setBankId(bankDetail.id);
  };

  const getUserBankListNri = () => {
    getInvestorBankDetails(user_id).then((res) => {
      if (res && res.data) {
        let bankItem = res.data;
        setBankData(bankItem);
        let bank = bankItem[bankItem.length - 1];
        if (bank && bank.account_number) {
          const bankType = BankListTypeNri?.find((item) => item.value === bank.account_type);
          setAccountNo(bank.account_number);
          setConfirmAccountNo(bank.account_number);
          setIfscCode(bank.ifsc);
          setBankType({ label: bankType?.label, value: bankType?.value });
          setDocName(bank.verification_document);
          setBankId(bank.bank_id);
        }
      }
    });
  };

  const onBankTypeChange = (event, obj) => {
    event.preventDefault();
    errData['bankType'] = undefined;
    if (!obj) {
      errData['bankType'] = 'Bank type should not be empty.';
    }
    setErrData(errData);
    setBankType(obj);
    setDetailsChange(true);
  };
  const accountNumberChange = (event) => {
    event.preventDefault();
    const { value } = event.target,
      pattern = /^([a-zA-Z0-9]{0,20})?$/;
    console.log(value);
    errData['account'] = undefined;
    if (value && !value.trim()) {
      errData['account'] = 'Bank account should not be empty.';
    } else if (value && !value.match(pattern)) {
      return false;
    }
    setErrData(errData);
    setAccountNo(value);
    setDetailsChange(true);
  };
  const onConfirmAccountNo = (event) => {
    const { value } = event.target,
      pattern = /^([a-zA-Z0-9]{0,20})?$/;
    errData['confirmAccount'] = undefined;
    if (value && !value.trim()) {
      errData['confirmAccount'] = 'Confirm bank account should not be empty.';
    } else if (value && !value.match(pattern)) {
      errData['confirmAccount'] = 'Account number and confirm account number do not match.';
      return false;
    } else if (value !== accountNo) {
      errData['confirmAccount'] = 'Account number and confirm account number do not match.';
    }
    setErrData(errData);
    setConfirmAccountNo(value);
  };
  const IfscCodeChange = (event) => {
    const panCardRegex = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
    const { value } = event.target;
    errData['ifscCode'] = undefined;
    if (value && !value.trim()) {
      errData['ifscCode'] = 'Ifsc code cannot be empty.';
    } else if (!panCardRegex.test(value)) {
      errData['ifscCode'] = 'Invalid IFSC Code.';
    }
    setErrData(errData);
    setIfscCode(value.toUpperCase());
    setDetailsChange(true);
  };
  const formValidator = (isSaveAsDraft) => {
    console.log('FOMR V');
    let flag = true;
    let error = errData || [];
    if (bankTypeObj && !bankTypeObj.value) {
      error['bankType'] = !isSaveAsDraft && 'Bank type should not be empty.';
      flag = false;
    }
    if (!accountNo) {
      error['account'] = !isSaveAsDraft && 'Bank account should not be empty.';
      flag = false;
    }
    if (!confirmAccountNo) {
      error['confirmAccount'] = !isSaveAsDraft && 'Bank account should not be empty.';
      flag = false;
    } else if (accountNo !== confirmAccountNo) {
      error['confirmAccount'] = 'Account number and confirm account number do not match.';
      flag = false;
    }
    if (!ifscCode) {
      error['ifscCode'] = !isSaveAsDraft && 'Invalid IFSC Code.';
      flag = false;
    }
    if (!file && (!verificationDoc || verificationDoc?.length === 0)) {
      error['file'] = !isSaveAsDraft && 'Upload Cancelled Cheque or Bank Statement should not be empty.';
      flag = false;
    }
    setErrData(error);
    return flag;
  };
  const addNewBank = (isSaveAsDraft) => {
    if (formValidator(isSaveAsDraft) && user_id) {
      const formData = new FormData();
      formData.append('account_type', bankTypeObj.value);
      formData.append('account_number', accountNo);
      formData.append('ifsc', ifscCode);
      formData.append('verification_document', 'cancelled_cheque');
      formData.append('file', file);

      postInvestorsBankDetails(user_id, formData).then((res) => {
        if (onBankAdd) onBankAdd();
        if (onBankSubmitted) onBankSubmitted();
      });
    }
  };
  const updateNewBank = (isSaveAsDraft) => {
    if (formValidator(isSaveAsDraft) && user_id && bankId) {
      const formData = new FormData();
      formData.append('account_number', accountNo);
      formData.append('ifsc', ifscCode);
      formData.append('account_type', bankTypeObj.value);
      formData.append('verification_document', 'cancelled_cheque');
      if (bankData.verification_document !== file) {
        formData.append('file', file);
      }
      if (detailsChange) {
        putInvestorsBankDetails(user_id, bankId, formData).then((res) => {
          if (onBankAdd) onBankAdd();
          if (onBankSubmitted) onBankSubmitted();
        });
      } else {
        if (onBankSubmitted) onBankSubmitted();
      }
    }
  };

  const validate = (isSaveAsDraft) => {
    return formValidator(isSaveAsDraft);
  };

  useImperativeHandle(ref, () => ({ addNewBank, updateNewBank, validate }));
  const onFileSelected = (file) => {
    setFile(file);
    setDetailsChange(true);
    errData['file'] = undefined;
    setErrData(errData);
  };
  return (
    <div className="bank-account-container">
      <div className="row bank-account">
        <Form.Group className="col-sm-6 my-3">
          <Form.Label className=" opacity-50 mb-1">Account Type</Form.Label>
          <DropdownList
            selectedOption={bankTypeObj.label}
            id="account-type"
            classes={{ input: 'font-weight-medium updated-input form-control pl-0' }}
            placeholder=""
            controlFunc={onBankTypeChange}
            options={taxStatus ? (taxStatus === '21' ? BankListTypeNre : taxStatus === '24' ? BankListTypeNro : BankListTypeNri) : BankListTypeNri}
          />
          <Form.Control.Feedback type="invalid" className={errData['bankType'] ? 'd-flex' : ''}>
            {errData['bankType']}
          </Form.Control.Feedback>
        </Form.Group>
        {bankNameObj.payment_mode === 'NODAL' && (
          <div className="c-red my-3 col-sm-12">
            The selected bank can take upto 2 working days to clear the funds when using Netbanking. NEFT/RTGS are preferred payment modes for this
            bank
          </div>
        )}
        <Form.Group className="col-sm-6 my-3">
          <Form.Label className=" opacity-50 mb-1">Account Number</Form.Label>
          <Form.Control
            type="text"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            id="accountNumber"
            placeholder=""
            onChange={accountNumberChange}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) => {
              formatInput(e);
            }}
            value={accountNo}
            className={'updated-input font-weight-medium text-disc pl-0 ' + (errData['account'] ? 'is-invalid' : '')}
          />
          <Form.Control.Feedback type="invalid">{errData['account']}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col-sm-6  my-3">
          <Form.Label className=" opacity-50 mb-1">Confirm Account Number</Form.Label>
          <Form.Control
            type="text"
            id="confirmAccountNo"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            placeholder=""
            onChange={onConfirmAccountNo}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) => {
              formatInput(e);
            }}
            value={confirmAccountNo}
            className={'updated-input font-weight-medium pl-0 ' + (errData['confirmAccount'] ? 'is-invalid' : '')}
          />
          <Form.Control.Feedback type="invalid">{errData['confirmAccount']}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col-sm-6 my-3">
          <Form.Label className=" opacity-50 mb-1">IFSC Code</Form.Label>
          <Form.Control
            type="text"
            id="ifscCode"
            placeholder=""
            onChange={IfscCodeChange}
            value={ifscCode}
            className={'updated-input font-weight-medium pl-0 ' + (errData['ifscCode'] ? 'is-invalid' : '')}
          />
          <Form.Control.Feedback type="invalid">{errData['ifscCode']}</Form.Control.Feedback>
        </Form.Group>

        <div className="col-sm-6 my-3">
          <UploadBox
            id="upload-canceled"
            title="Upload Cancelled Cheque or Bank Statement"
            onChange={onFileSelected}
            value={verificationDoc}
            accept={['pdf', 'jpeg', 'png', 'jpg']}
            err={errData['file']}
          />
        </div>
      </div>
      {bankNameObj && bankNameObj.id && (
        <div className="pt-5">
          <div className="bank-status d-flex align-items-center py-2">
            <img src={!bankNameObj.payment_mode ? notSupportedIcon : supportedIcon} alt="forward icon" />
            <span className="ml-1 small">Netbanking Supported</span>
          </div>
          <div className="bank-status d-flex align-items-center py-2">
            <img src={!bankNameObj.payment_mode ? notSupportedIcon : supportedIcon} alt="forward icon" />
            <span className="ml-1 small">UPI Supported</span>
          </div>
          <div className="bank-status d-flex align-items-center py-2">
            <img src={supportedIcon} alt="forward icon" />
            <span className="ml-1 small">NEFT/RTGS Supported</span>
          </div>
        </div>
      )}
      {show ? (
        <div className={'bank-actions text-' + alignment}>
          <button type="button" className="btn btn-primary mt-3" onClick={bankId ? updateNewBank : addNewBank}>
            submit
          </button>
        </div>
      ) : null}
    </div>
  );
});
NewBankNri.propTypes = {
  buttonText: PropTypes.string,
  alignment: PropTypes.string,
  onBankAdd: PropTypes.func,
  bankDetail: PropTypes.object,
  show: PropTypes.bool,
};
NewBankNri.displayName = 'NewBankNri';
export default NewBankNri;
