import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getKYCStatus } from '../../api/kyc';
import { getUserDetails } from '../../api/profile';
import Toast from '../../common/toast/Toast';
import BankAccountMobile from '../../component/bank-accounts/BankAccountMobile';
import BankAccountUpdateMobile from '../../component/bank-accounts/BankAccountUpdateMobile';
import BankAutoPayLimit from '../../component/bank-accounts/BankAutoPayLimit';
import DigitalSignature from '../../component/digital-signature/DigitalSignature';
import DigitalSignaturePreview from '../../component/digital-signature/DigitalSignaturePreview';
import MobileFundListFilter from '../../component/invest-fund-list/MobileFundListFilter';
import MySipsMobile from '../../component/my-sips/MySipsMobile';
import ProfileComponentMobile from '../../component/profile/ProfileComponentMobile';
import { TransactionProvider } from '../../context/transaction-context';
import RedeemConfirmationModal from '../../modal/RedeemConfirmationModal';
import RedemptionStatus from '../../modal/RedemptionStatusModal';
import FundDetail from '../../page/fund/FundDetail';
import InvestFundAll from '../../page/fund/InvestFundAll';
import TopFunds from '../../page/fund/TopFunds';
import SelectMandate from '../../page/mandate/SelectMandate';
import StausMandate from '../../page/mandate/StatusMandate';
import InvestPage from '../../page/nav/InvestPage';
import MobileProfile from '../../page/nav/MobileProfile';
import Onboarding from '../../page/nav/Onboarding';
import PanStatus from '../../page/nav/PanStatus';
import PortfolioPage from '../../page/nav/PortfolioPage';
import Profile from '../../page/nav/Profile';
import Onboarding2 from '../../page/onboarding2';
import OrderComplete from '../../page/order/OrderComplete';
import OrderSummary from '../../page/order/OrderSummary';
import {
  setProfileObj,
  updateProfile,
} from '../../redux/reducers/auth-reducer';
import { updateKycInfo } from '../../redux/reducers/onboarding-reducer';
import { decryptData, encryptData } from '../../utility/DecryptHelper';
import { hasAdminAccess, hasAssistModePermission } from '../../utility/utility';
import { DashboardV2 } from '../pages/DashboardV2/DashboardV2';
import { FDApplicationReviewAndPayment } from '../pages/FDApplicationReview/FDApplicationReviewAndPayment';
import SwitchFundsOrderStatus from '../pages/MyPortfolio/Components/SwitchFundsOrderStatus';
import { MyPortfolioV2 } from '../pages/MyPortfolioV2/MyPortfolioV2';
import RiskAssessment from '../pages/RiskAssessment';
import SwitchFunds from '../pages/SwitchFunds/SwitchFunds';
import { UserGroups } from '../pages/UserGroups/UserGroups';
import { UserGroupsDetailPage } from '../pages/UserGroups/UserGroupsDetailPage';
import { Users } from '../pages/Users/Users';
import Loader from '../shared/component/Loader';
import { ExternalFunds } from '../pages/ExternalFunds/ExternalFunds';
import { ProtectedMFRoute } from './ProtectedMFRoute';
import { KycStatusEnum } from '../../enums';
import ChangePassword from '../../component/profile/ChangePassword';
import VkycStatusMobile from '../../component/profile/VkycStatusMobile';
import { getInvestorDetails } from '../../api/onboarding.api';
import taxStatus from '../../utility/taxStatus';

const Transactionsv2 = React.lazy(() =>
  import('../pages/Transactions').then((module) => ({
    default: module.Transactions_v2,
  }))
);
const MobileTransactionsv2 = React.lazy(() =>
  import('../pages/Transactions').then((module) => ({
    default: module.MobileTransactions_v2,
  }))
);

const Routes = () => {
  const {
    rise,
    is_kyc_verified,
    isMutualFundInScope,
    permissions,
    profileObj,
    user_id,
  } = useSelector((state: any) => state.authReducer);
  const [isKycVerified, setIsKycVerified] = useState<boolean>();
  const [kycStatusAPI, setKYCStatusAPI] = React.useState<KycStatusEnum | ''>(
    ''
  );
  const [isFetchingUserDetails, setIsFetchingUserDetails] = useState(false);
  let pan = localStorage.getItem('panData');
  const dispatch = useDispatch();

  const canAccessUserGroupsRoute = hasAdminAccess(permissions);
  const canAccessUsersRotue =
    hasAdminAccess(permissions) || hasAssistModePermission(permissions);
  const isTaxStatusInUserProfile = profileObj?.data?.tax_status?.tax_status;

  useEffect(() => {
    updatePanData();
  }, [pan]);

  const updatePanData = isMutualFundInScope
    ? async () => {
      setIsFetchingUserDetails(true)
      try {
        const userDetails = await getUserDetails();
        let investorDetails = null;

        investorDetails = await getInvestorDetails(user_id);

        if (investorDetails.data && (investorDetails.data?.pan || investorDetails.data?.guardian_detail?.pan)) {
          dispatch(
            updateKycInfo({
              pan: investorDetails.data?.pan || investorDetails.data?.guardian_detail?.pan,
              pan_kyc_status: taxStatus[investorDetails.data.investor_type as keyof typeof taxStatus],
            })
          );
          localStorage.setItem(
            'panData',
            encryptData({
              pan: investorDetails.data?.pan || investorDetails.data?.guardian_detail?.pan,
              pan_kyc_status: taxStatus[investorDetails.data?.investor_type as keyof typeof taxStatus],
            })
          );
          const profileObj = decryptData(userDetails.data.data);
          dispatch(setProfileObj(profileObj));
          if (profileObj.data?.pan) {
            const { user_profile_image, can_add_bank } = profileObj.data;
            dispatch(
              updateProfile({
                user_profile_picture: user_profile_image,
                user_name: investorDetails.data?.guardian_detail?.first_name || investorDetails.data?.first_name,
                can_add_bank,
                tax_status: investorDetails.data?.investor_type,
              })
            );
          } 
        } 
        else {
          localStorage.removeItem('panData');
        }
      } finally {
        setIsFetchingUserDetails(false);
      }
    }
    : function () {};

  if (isMutualFundInScope) {
    useEffect(() => {
      let pan = localStorage.getItem('panData');
      if (rise) {
        if (rise.redirect_screen) {
          setIsKycVerified(rise.redirect_screen);
        } else if (pan) {
          let panName = decryptData(pan);
          if (panName.pan) {
            setIsKycVerified(true);
          }
        } else {
          setIsKycVerified(is_kyc_verified);
        }
      }
    }, [is_kyc_verified]);

    useEffect(() => {
      if (pan) {
        getKYCStatus(decryptData(pan).pan)
          .then((res) => {
            setKYCStatusAPI(res?.data?.status);
          })
          .catch((error) => {
            if (error?.response?.data?.message?.length > 0) {
              Toast.error(error?.response?.data?.message);
            }
          });
      }
    }, [pan, is_kyc_verified]);
  }
  if (isFetchingUserDetails) {
    return <Loader />;
  }
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Switch>
          {isMutualFundInScope &&
            !isKycVerified &&
            (kycStatusAPI === KycStatusEnum.processing ||
              kycStatusAPI === KycStatusEnum.initiated ||
              kycStatusAPI === KycStatusEnum.rejected) && (
              <Route
                exact
                path={'/'}
                render={() => <Redirect to={'/home'} />}
              />
            )}
          {isMutualFundInScope && isKycVerified && (
            <Route exact path={'/'} render={() => <Redirect to={'/home'} />} />
          )}
          {/* 
            NOTE: Need to check tax_status, in case where stored status in user profile (fetched above) is incorrect, then update that via redirecting user to the /panstatus step
          */}
          {isMutualFundInScope &&
            isTaxStatusInUserProfile !== undefined &&
            !isTaxStatusInUserProfile && (
              <Route
                exact
                path={'/'}
                render={() => <Redirect to={'/panstatus'} />}
              />
            )}
          {isMutualFundInScope &&
            !isTaxStatusInUserProfile &&
            (kycStatusAPI === KycStatusEnum.registered ||
              kycStatusAPI === KycStatusEnum.validated ||
              kycStatusAPI === KycStatusEnum.pending) && (
              <Route exact path={'/'} component={PanStatus} />
            )}
          <ProtectedMFRoute path={'/kyc'} component={DashboardV2} />
          <Route path={'/home'} component={DashboardV2} />
          <Route exact path={'/my-portfolio'} component={MyPortfolioV2} />
          <ProtectedMFRoute
            exact
            path={'/switch-funds'}
            component={SwitchFunds}
          />
          <ProtectedMFRoute
            exact
            path={'/switch-funds/:id/status'}
            component={SwitchFundsOrderStatus}
          />
          <ProtectedMFRoute
            path={'/invest'}
            render={(props: any) => <InvestPage {...props} />}
          />
          <ProtectedMFRoute
            path={'/cart'}
            render={(props: any) => <OrderSummary {...props} />}
          />
          <ProtectedMFRoute
            path={'/risk-assessment'}
            render={(props: any) => <RiskAssessment {...props} />}
          />
          <ProtectedMFRoute
            exact
            path='/redemptionconfirmation'
            component={RedeemConfirmationModal}
          />
          <ProtectedMFRoute
            exact
            path='/redemptionstatus'
            component={RedemptionStatus}
          />
          <ProtectedMFRoute
            exact
            path='/digitalsignature/:data'
            component={DigitalSignature}
          />
          <ProtectedMFRoute
            exact
            path='/digitalsignpreview'
            component={DigitalSignaturePreview}
          />
          <ProtectedMFRoute exact path='/panstatus' component={PanStatus} />
          <Route
            path='/profile'
            render={({ match: { url } }: { match: { url: string } }) => (
              <>
                <Route path={`${url}/detail`} component={Profile} exact />
                <ProtectedMFRoute
                  path={`${url}/bank-list`}
                  component={Profile}
                />
                <ProtectedMFRoute
                  path={`${url}/add-bank`}
                  component={Profile}
                />
                <Route path={`${url}/change-password`} component={Profile} />
                <Route path={`${url}/vkyc-status`} component={Profile} />
                <ProtectedMFRoute
                  path={`${url}/bank-autopay/:bankId/:userId`}
                  component={BankAutoPayLimit}
                />
                <ProtectedMFRoute path={`${url}/my-sips`} component={Profile} />
              </>
            )}
          />
          <ProtectedMFRoute
            path='/bank-mandate/:bankId/:userId'
            component={SelectMandate}
            exact
          />
          <ProtectedMFRoute
            exact
            path='/bank-autopay-status'
            component={StausMandate}
          />
          <Route path={`/userprofile`} component={MobileProfile} exact />
          <Route
            path={`/mobileprofile/detail`}
            component={ProfileComponentMobile}
            exact
          />
          <ProtectedMFRoute
            path={`/mobileprofile/bank-list`}
            component={BankAccountMobile}
            exact
          />
          <ProtectedMFRoute
            path={`/mobileprofile/add-bank`}
            component={BankAccountUpdateMobile}
            exact
          />
          <Route
            path={`/mobileprofile/change-password`}
            component={ChangePassword}
            exact
          />
          <Route
            path={`/mobileprofile/vkyc-status`}
            component={VkycStatusMobile}
            exact
          />
          <ProtectedMFRoute
            path={`/mobileprofile/my-sips`}
            component={MySipsMobile}
            exact
          />
          <Route path='/invest' component={InvestPage} />
          <Route path='/portfolio' component={PortfolioPage} />
          <Route path='/transactions'>
            <>
              <TransactionProvider>
                <Transactionsv2 />
              </TransactionProvider>
            </>
          </Route>
          <Route path='/transactionsmobile'>
            <>
              <TransactionProvider>
                <MobileTransactionsv2 />
              </TransactionProvider>
            </>
          </Route>
          {/* <Route path='/transactionsmobile' component={MobileTransactions_v2} /> */}
          <ProtectedMFRoute path='/topfunds/:id' component={TopFunds} />
          <ProtectedMFRoute
            path='/fund/detail/:name/:id'
            component={FundDetail}
          />
          <ProtectedMFRoute
            path='/externalfunds/:step?'
            component={ExternalFunds}
          />
          <ProtectedMFRoute path='/funds/all' component={InvestFundAll} />
          <ProtectedMFRoute
            path='/funds/mobilefundlist'
            component={MobileFundListFilter}
          />
          <ProtectedMFRoute
            path='/order/summary'
            component={OrderSummary}
            key='OrderSummary'
          />
          ,
          <ProtectedMFRoute
            path='/order/complete/:orders/:mode'
            component={OrderComplete}
            key='OrderComplete'
          />
          <ProtectedMFRoute path='/boarding' component={Onboarding} />
          <ProtectedMFRoute path='/onboarding' component={Onboarding2} />
          <Route
            path={'/user-groups/:id'}
            component={UserGroupsDetailPage}
            exact
          />
          {canAccessUserGroupsRoute && (
            <Route path={'/user-groups'} component={UserGroups} exact />
          )}
          {canAccessUsersRotue && (
            <Route path={'/users'} component={Users} exact />
          )}
          <Route
            path={'/fd-application-review'}
            component={FDApplicationReviewAndPayment}
          />
          <Redirect to={'/home'} />
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
