/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserBanks } from '../../api/bank';
import { getInvestorStatus } from '../../api/kyc';
import { getHomeData } from '../../api/nav';
import { getUserDetails } from '../../api/profile';
import longArrowIcon from '../../assets/arrow_round_forward.svg';
import downIcon from '../../assets/down_arrow.svg';
import InfoIcon from '../../assets/info.svg';
import MobileNavigation from '../../common/footermobile/MobileNavigation';
import Toast from '../../common/toast/Toast';
import FundList from '../../component/funds/FundList';
import MobileFundList from '../../component/funds/MobileFundList';
import {
  updateProfile,
  updateinvestorStatus,
} from '../../redux/reducers/auth-reducer';
import { updateKycInfo } from '../../redux/reducers/onboarding-reducer';
import { decryptData, encryptData } from '../../utility/DecryptHelper';
import { formatPrice } from '../../utility/utility';

const Home = ({ history }) => {
  const [HomePageData, setHomeData] = useState({});
  const [returnVal, setValue] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [investorStatus, setInvestorStatus] = useState('');
  const [bankDetails, setBankDetails] = useState();
  const [bankRejected, setBankRejected] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.paymentReducer);
  const userId = useSelector((state) => state.authReducer.user_id);
  const pan = useSelector((state) => state.onboardingReducer.pan);
  if (data.payment_mode === 'DIRECT') {
    history.push({
      pathname: `/order/complete/${btoa(data.transaction_id)}/${
        data.payment_mode
      }`,
      state: data,
    });
  }
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    history.go('/home');
  };
  useEffect(() => {
    getHomeDataFunction();
    getUserDocumentStatus();
    updatePanData();
    getBankDetails();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getBankDetails = () => {
    getUserBanks(userId).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        setBankDetails(data.data.bank_details[0]);
      }
    });
  };

  const updatePanData = () => {
    getUserDetails().then((res) => {
      const profileObj = decryptData(res.data.data);
      if (profileObj.data && profileObj.data.pan) {
        dispatch(
          updateKycInfo({
            pan: profileObj.data.pan,
            pan_kyc_status: profileObj.data?.tax_status?.id,
          })
        );
        const { pan_name, user_profile_image, can_add_bank, tax_status } =
          profileObj.data;
        dispatch(
          updateProfile({
            user_profile_picture: user_profile_image,
            user_name: pan_name,
            can_add_bank,
            tax_status,
          })
        );
      }
    });
  };

  useEffect(() => {
    getHomeDataFunction();
  }, [pan, investorStatus]);

  const getHomeDataFunction = () => {
    getHomeData().then((res) => {
      if (res && res.data) {
        const homeData = decryptData(res.data.data);
        if (homeData) {
          setHomeData(homeData);
          if (homeData.portfolio_details) {
            setValue(homeData.portfolio_details.xirr);
          }
        }
      }
    });
  };

  const getUserDocumentStatus = () => {
    getInvestorStatus(userId).then((res) => {
      if (res && res.data) {
        setInvestorStatus(res.data.status);
        if (res.data?.message?.indexOf('bank_rejected') !== -1) {
          setBankRejected(true);
        }
        if (res?.data?.status === 'ready_to_invest') {
          dispatch(updateinvestorStatus(true));
        }
      }
    });
  };
  const onLinkSelection = (eventKey) => {
    const { portfolio_details } = HomePageData;
    const { absolute_return, xirr } = portfolio_details || {};
    if (eventKey === '1') {
      setValue(xirr);
    } else if (eventKey === '2') {
      setValue(absolute_return);
    }
  };

  const updateOnboardingPan = () => {
    history.push('/panstatus');
  };

  const updateOnboarding = () => {
    getUserDetails()
      .then((res) => {
        if (res && res.data) {
          const profileObj = decryptData(res.data.data);
          if (
            profileObj.data &&
            profileObj.data.pan &&
            profileObj.data.tax_status &&
            profileObj.data.tax_status.id
          ) {
            const panObj = {
              pan: profileObj.data.pan,
              pan_kyc_status: profileObj.data.tax_status.id,
            };
            dispatch(updateKycInfo(panObj));
            localStorage.setItem('panData', encryptData(panObj));
            setTimeout(() => {
              history.push('/onboarding');
            }, 0);
          } else {
            setTimeout(() => {
              history.push('/panstatus');
            }, 0);
          }
        }
      })
      .catch((e) => {
        console.log('Error caught in catch', e);
        Toast.error(
          'Unable to perform the requested action, please try after some time'
        );
      });
  };
  const goToInvest = () => {
    history.push('/invest');
  };
  const resubmitBank = () => {
    if (bankDetails) {
      history.push({ pathname: '/onboarding' });
    }
  };
  return (
    <>
      <div className='container'>
        <div className='my-4 row justify-content-end'>
          <div className='col-sm-12 text-center'>
            {investorStatus === 'incomplete_detail' &&
              (HomePageData.is_individual_nri ? (
                <div
                  className={
                    'col-sm-12 alert text-center alert-danger bg-light-red'
                  }
                  role='alert'
                >
                  <span className='d-flex justify-content-center'>
                    <img
                      src={InfoIcon}
                      className='icon mr-2 c-red'
                      alt='info'
                    />
                    Your PAN tax status is still Resident Individual. In order
                    to continue investing with your NRE/NRO account, kindly
                    contact{' '}
                    {process.env.REACT_APP_SUPPORT_EMAIL
                      ? `us at ${process.env.REACT_APP_SUPPORT_EMAIL}`
                      : 'Customer Support'}{' '}
                    to update your tax status to NRI.
                  </span>
                </div>
              ) : bankRejected ? (
                <>
                  <div
                    className={
                      'col-sm-12 alert text-center alert-danger bg-light-red'
                    }
                    role='alert'
                  >
                    <span className='d-flex justify-content-center'>
                      <img
                        src={InfoIcon}
                        className='icon mr-2 c-red'
                        alt='info'
                      />
                      Your bank account was not verified, please fill in the
                      details{' '}
                      <button
                        className='btn btn-link alert-link ml-1 p-0 mr-1'
                        onClick={resubmitBank}
                      >
                        here
                      </button>
                    </span>
                  </div>
                </>
              ) : HomePageData.is_kyc_verified ? (
                <>
                  <div
                    className={
                      'col-sm-12 alert text-center alert-danger bg-light-red'
                    }
                    role='alert'
                  >
                    <span className='d-flex justify-content-center'>
                      <img
                        src={InfoIcon}
                        className='icon mr-2 c-red'
                        alt='info'
                      />
                      Please complete your verification
                      <button
                        className='btn btn-link alert-link ml-1 p-0'
                        onClick={updateOnboarding}
                      >
                        here
                      </button>
                    </span>
                  </div>
                </>
              ) : !pan ? (
                <>
                  <div
                    className={
                      'col-sm-12 alert text-center alert-danger bg-light-red'
                    }
                    role='alert'
                  >
                    <span className='d-flex justify-content-center'>
                      <img
                        src={InfoIcon}
                        className='icon mr-2 c-red'
                        alt='info'
                      />
                      Get investment ready! Click{' '}
                      <button
                        className='btn btn-link alert-link ml-1 p-0'
                        onClick={updateOnboardingPan}
                      >
                        here
                      </button>
                      &nbsp;to set up your account.
                    </span>
                  </div>
                </>
              ) : (
                <div
                  className={
                    'col-sm-12 alert text-center alert-danger bg-light-red'
                  }
                  role='alert'
                >
                  <span className='d-flex justify-content-center'>
                    <img
                      src={InfoIcon}
                      className='icon mr-2 c-red d-flex'
                      alt='info'
                    />
                    Your KYC is incomplete, please complete your KYC and click{' '}
                    <button
                      className='btn btn-link alert-link ml-1 p-0'
                      onClick={updateOnboardingPan}
                    >
                      here
                    </button>
                    &nbsp;to continue. Contact{' '}
                    {process.env.REACT_APP_SUPPORT_EMAIL
                      ? `${process.env.REACT_APP_SUPPORT_EMAIL}`
                      : 'Customer Support'}{' '}
                    for any assistance.
                  </span>
                </div>
              ))}
            {investorStatus === 'under_review' && (
              <>
                <div
                  className={'col-sm-12 alert text-center bg-light-orange'}
                  role='alert'
                >
                  <span className='d-flex justify-content-center'>
                    <img
                      src={InfoIcon}
                      className='icon mr-2 c-red'
                      alt='info'
                    />
                    Your application is submitted and is in verification
                    process. It will be verified in next 24 hrs
                  </span>
                </div>
              </>
            )}
            {investorStatus === 'ready_to_invest' &&
            !HomePageData.has_transacted &&
            windowDimensions.width > 480 ? (
              <>
                <div
                  className={'col-sm-12 alert text-center bg-light-green'}
                  role='alert'
                >
                  <span className='d-flex justify-content-center'>
                    <img
                      src={InfoIcon}
                      className='icon mr-2 c-red'
                      alt='info'
                    />
                    Your account verification is completed. Click
                    <button
                      className='btn btn-link alert-link ml-1 p-0 mr-1'
                      onClick={goToInvest}
                    >
                      here
                    </button>
                    to start your investment journey
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
            {investorStatus === 'ready_to_invest' &&
              !HomePageData.has_transacted &&
              windowDimensions.width <= 480 && (
                <>
                  <div
                    style={{ position: 'relative' }}
                    className={'col-sm-12 alert text-center bg-light-green'}
                    role='alert'
                  >
                    <span className=''>
                      <img
                        src={InfoIcon}
                        style={{
                          position: 'absolute',
                          top: '2px',
                          left: '4px',
                        }}
                        className='icon c-red'
                        alt='info'
                      />
                      Your account verification is completed. Click
                      <a
                        style={{
                          color: '#08aeea',
                          fontWeight: 800,
                          cursor: 'pointer',
                        }}
                        onClick={goToInvest}
                      >
                        here
                      </a>
                      to start your investment journey
                    </span>
                  </div>
                </>
              )}
          </div>
          <div className='col-sm-8 c-blue mt-2'>
            {windowDimensions.width > 480 ? <span>Quick Links</span> : null}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4'>
            <div
              className={`${
                windowDimensions.width > 480
                  ? 'colored-box h-100  p-4 rounded'
                  : 'colored-box mx-4 p-3'
              }`}
            >
              {HomePageData.portfolio_details && (
                <>
                  <Form.Group className='mb-3'>
                    <Form.Label className='font-weight-medium c-blue'>
                      Total Investment
                    </Form.Label>
                    <InputGroup className='mb-2 font-weight-medium'>
                      <InputGroup.Prepend>
                        <InputGroup.Text className='font-weight-medium'>
                          &#8377;
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type='text'
                        id='Total-Investment'
                        placeholder=''
                        disabled
                        value={formatPrice(
                          HomePageData.portfolio_details.total_investment
                        )}
                        className={'updated-input font-weight-medium'}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className='my-3'>
                    <Form.Label className='font-weight-medium c-blue'>
                      Current Value
                    </Form.Label>
                    <InputGroup className='mb-2 font-weight-medium'>
                      <InputGroup.Prepend>
                        <InputGroup.Text className='font-weight-medium'>
                          &#8377;
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type='text'
                        id='Current-Value'
                        placeholder=''
                        disabled
                        value={formatPrice(
                          HomePageData.portfolio_details.current_value
                        )}
                        className={'updated-input font-weight-medium '}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className='my-3'>
                    <Form.Label className='font-weight-medium c-blue d-flex align-items-center'>
                      <span className='ml-0'>Returns (%)</span>
                      <Dropdown className='ml-1' onSelect={onLinkSelection}>
                        <Dropdown.Toggle
                          as='span'
                          id='dropdown-profile'
                          className='cursor-pointer'
                        >
                          <img
                            src={downIcon}
                            className='down-arrow'
                            alt='profile pic'
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey='1'>
                            <div className='profile-list'>
                              <span className='ml-3'>XIRR</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey='2'>
                            <div className='profile-list'>
                              <span className='ml-3'>Absolute</span>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Label>
                    <InputGroup className='mb-2 font-weight-medium'>
                      <InputGroup.Prepend>
                        <InputGroup.Text className='font-weight-medium'>
                          &#8377;
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type='text'
                        id='returnPercentage'
                        placeholder=''
                        disabled
                        value={`${returnVal}%`}
                        className={'updated-input font-weight-medium'}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className='link-container my-4 text-right'>
                    <Link to='/portfolio' className='d-inline-block'>
                      <div className='d-flex align-items-center justify-content-end'>
                        <span className='mr-3'>View All</span>
                        <img src={longArrowIcon} alt='long arrow icon' />
                      </div>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
          {windowDimensions.width > 480 ? (
            <div className='col-sm-8 fund-list-container'>
              <div className='colored-box h-100 p-4 rounded'>
                <FundList fundItems={HomePageData.category_list} />
              </div>
            </div>
          ) : (
            <div className='col-sm-8 fund-list-container'>
              <div className=' h-100 p-4 rounded'>
                <MobileFundList fundItems={HomePageData.category_list} />
              </div>
            </div>
          )}
          {windowDimensions.width > 480 ? null : <MobileNavigation />}
        </div>
      </div>
    </>
  );
};
export default Home;
