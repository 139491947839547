import React, { useEffect, useState, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BankListType, formatInput, BankListTypeIndividual, BankListTypeCurrent, BankListTypeNri } from '../../utility/utility';
import supportedIcon from '../../assets/supported.svg';
import notSupportedIcon from '../../assets/not-supported.svg';
import DropdownList from '../../common/form/dropdown-list/DropdownList';
import UploadBox from '../../common/form/upload-box/UploadBox';
import { getBankDetail, postInvestorsBankDetails, putInvestorsBankDetails } from '../../api/bank';
import { decryptData } from '../../utility/DecryptHelper';
import { loadBankList } from '../../redux/reducers/onboarding-reducer';

const EditBankAccount = React.forwardRef(({ alignment = 'center', onBankAdd, bankDetail, show, onBankSubmitted, panStatus, onboarding }, ref) => {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.authReducer.user_id);
  const bankList = useSelector((state) => state.onboardingReducer.bankList);
  const [errData, setErrData] = useState([]);
  const [bankNameObj, setBankName] = useState({});
  const [bankTypeObj, setBankType] = useState({});
  const [accountNo, setAccountNo] = useState('');
  const [confirmAccountNo, setConfirmAccountNo] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [file, setFile] = useState(null);
  const [verificationDoc, setDocName] = useState('');
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [bankListOption, setBankListOption] = useState([]);
  const individual = '01';
  const minor = '02';
  const huf = '03';
  const nrinrenro = ['11', '21', '24'];
  useEffect(() => {
    if (panStatus) {
      if (panStatus === individual) {
        setBankListOption(BankListTypeIndividual);
      } else if (panStatus === minor) {
        setBankListOption(BankListTypeIndividual);
      } else if (panStatus === huf) {
        setBankListOption(BankListType);
      } else if (nrinrenro.includes(panStatus)) {
        setBankListOption(BankListTypeNri);
      } else {
        setBankListOption(BankListTypeCurrent);
      }
    }

    if (bankList && !bankList.length) {
      getBankDetail().then((res) => {
        if (res && res.data) {
          const bankListData = decryptData(res.data.data);
          dispatch(loadBankList(bankListData.data));
        }
      });
    }
  }, [panStatus]);
  useEffect(() => {
    if (bankDetail && bankDetail.account_number) {
      const bankType = BankListType?.find(
        (item) => item.value === bankDetail.account_type_bse
      )?.label;
      // TODO: Why do we need this branch__bank_id__bank_name
      const bankObj =
        bankList?.find(
          (item) => item.bank_name === bankDetail?.branch__bank_id__bank_name
        ) || {};
      setAccountNo(bankDetail.account_number);
      setConfirmAccountNo(bankDetail.account_number);
      setIfscCode(bankDetail.branch__ifsc_code);
      if (bankDetail?.branch__bank_id__bank_name) {
        setBankName({
          label: bankDetail?.branch__bank_id__bank_name,
          id: bankObj ? bankObj.id : '',
          payment_mode: bankObj.payment_mode,
        });
      }
      setBankType({ label: bankType, value: bankDetail.account_type_bse });
      setDocName(bankDetail.verification_document);
    }
  }, [bankDetail, bankList]);

  const onBankTypeChange = (event, obj) => {
    event.preventDefault();
    errData['bankType'] = undefined;
    if (!obj) {
      errData['bankType'] = 'Bank type should not be empty.';
    }
    setErrData(errData);
    setIsDataUpdated(true);
    setBankType(obj);
  };
  const accountNumberChange = (event) => {
    const { value } = event.target,
      pattern = /^([a-zA-Z0-9]{0,20})?$/;
    errData['account'] = undefined;
    if (value && !value.trim()) {
      errData['account'] = 'Bank account should not be empty.';
    } else if (value && !value.match(pattern)) {
      return false;
    }
    setErrData(errData);
    setIsDataUpdated(true);
    setAccountNo(value);
  };
  const onConfirmAccountNo = (event) => {
    const { value } = event.target,
      pattern = /^([a-zA-Z0-9]{0,20})?$/;
    errData['confirmAccount'] = undefined;
    if (value && !value.trim()) {
      errData['confirmAccount'] = 'Confirm bank account should not be empty.';
    } else if (value && !value.match(pattern)) {
      errData['confirmAccount'] = 'Account number and confirm account number do not match.';
      return false;
    } else if (value !== accountNo) {
      errData['confirmAccount'] = 'Account number and confirm account number do not match.';
    }
    setErrData(errData);
    setIsDataUpdated(true);
    setConfirmAccountNo(value);
  };
  const IfscCodeChange = (event) => {
    const panCardRegex = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
    const { value } = event.target;
    errData['ifscCode'] = undefined;
    if (value && !value.trim()) {
      errData['ifscCode'] = 'Ifsc code cannot be empty.';
    } else if (!panCardRegex.test(value)) {
      errData['ifscCode'] = 'Invalid IFSC Code.';
    }
    setErrData(errData);
    setIsDataUpdated(true);
    setIfscCode(value.toUpperCase());
  };
  const formValidator = (isSaveAsDraft) => {
    let flag = true;
    let error = [];

    if (bankTypeObj && !bankTypeObj.value) {
      error['bankType'] = !isSaveAsDraft && 'Bank type should not be empty.';
      flag = false;
    }
    if (!accountNo) {
      error['account'] = !isSaveAsDraft && 'Bank account should not be empty.';
      flag = false;
    }
    if (!confirmAccountNo) {
      error['confirmAccount'] = !isSaveAsDraft && 'Confirm bank account should not be empty.';
      flag = false;
    } else if (accountNo !== confirmAccountNo) {
      error['confirmAccount'] = 'Account number and confirm account number do not match.';
      flag = false;
    }
    if (!ifscCode) {
      error['ifscCode'] = !isSaveAsDraft && 'Invalid IFSC Code.';
      flag = false;
    }
    //validation of bank upload cancelation check
    if (!((onboarding && panStatus === '01') || (!bankDetail && !['02', '11', '21', '24'].includes(panStatus)))) {
      if (!file && (!verificationDoc || verificationDoc?.length === 0)) {
        error['bankFile'] = !isSaveAsDraft && 'Upload Cancelled Cheque or Bank Statement should not be empty.';
        flag = false;
      }
    }
    setErrData(error);
    return flag;
  };
  const addNewBank = (isSaveAsDraft) => {
    if (formValidator(isSaveAsDraft) && user_id) {
      const formData = new FormData();
      formData.append('account_number', accountNo);
      formData.append('ifsc', ifscCode);
      formData.append('account_type', bankTypeObj?.value);
      if (file) {
        // TODO: Document should be selected dynamically
        formData.append('verification_document', 'cancelled_cheque');
        formData.append('file', file);
      }
      postInvestorsBankDetails(user_id, formData).then((res) => {
        if (onBankAdd) onBankAdd();
        if (onBankSubmitted) onBankSubmitted();
      });
    }
  };

  const updateNewBank = (isSaveAsDraft) => {
    if (formValidator(isSaveAsDraft) && isDataUpdated && bankDetail.id && user_id) {
      const formData = new FormData();
      formData.append('account_number', accountNo);
      formData.append('ifsc', ifscCode);
      formData.append('account_type', bankTypeObj?.value);
      if (file) {
        formData.append('verification_document', 'cancelled_cheque');
        formData.append('file', file);
      }
      putInvestorsBankDetails(user_id, bankDetail.id, formData).then((res) => {
        if (onBankAdd) onBankAdd();
        if (onBankSubmitted) onBankSubmitted();
      });
    } else {
      if (onBankAdd) onBankAdd();
      if (onBankSubmitted) onBankSubmitted();
    }
  };
  useImperativeHandle(ref, () => ({ addNewBank, updateNewBank, validate }));
  const onFileSelected = (file) => {
    setFile(file);
    errData['bankFile'] = undefined;
    setErrData(errData);
    setIsDataUpdated(true);
  };
  const addBankDetails = () => {
    if (bankDetail?.id) {
      updateNewBank();
    } else {
      addNewBank();
    }
  };

  const validate = (isSaveAsDraft) => {
    return formValidator(isSaveAsDraft);
  };

  return (
    <div className='bank-account-container'>
      <div className='row bank-account'>
        <Form.Group className='col-sm-6 my-3'>
          <Form.Label className=' opacity-50 mb-1'>Account Type</Form.Label>
          <DropdownList
            selectedOption={bankTypeObj.label}
            id='account-type'
            classes={{
              input: 'font-weight-medium updated-input form-control pl-0',
            }}
            placeholder=''
            controlFunc={onBankTypeChange}
            options={bankListOption}
          />
          <Form.Control.Feedback
            type='invalid'
            className={errData['bankType'] ? 'd-flex' : ''}
          >
            {errData['bankType']}
          </Form.Control.Feedback>
        </Form.Group>
        {bankNameObj.payment_mode === 'NODAL' && (
          <div className='c-red my-3 col-sm-12'>
            The selected bank can take upto 2 working days to clear the funds
            when using Netbanking. NEFT/RTGS are preferred payment modes for
            this bank
          </div>
        )}
        <Form.Group className='col-sm-6 my-3'>
          <Form.Label className=' opacity-50 mb-1'>Account Number</Form.Label>
          <Form.Control
            type='text'
            id='accountNumber'
            placeholder=''
            onChange={accountNumberChange}
            onKeyDown={(e) => {
              formatInput(e);
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            value={accountNo}
            className={
              'updated-input font-weight-medium text-disc pl-0 ' +
              (errData['account'] ? 'is-invalid' : '')
            }
          />
          <Form.Control.Feedback type='invalid'>
            {errData['account']}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='col-sm-6  my-3'>
          <Form.Label className=' opacity-50 mb-1'>
            Confirm Account Number
          </Form.Label>
          <Form.Control
            type='text'
            id='confirmAccountNo'
            placeholder=''
            onChange={onConfirmAccountNo}
            onKeyDown={(e) => {
              formatInput(e);
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            value={confirmAccountNo}
            className={
              'updated-input font-weight-medium pl-0 ' +
              (errData['confirmAccount'] ? 'is-invalid' : '')
            }
          />
          <Form.Control.Feedback type='invalid'>
            {errData['confirmAccount']}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='col-sm-6 my-3'>
          <Form.Label className=' opacity-50 mb-1'>IFSC Code</Form.Label>
          <Form.Control
            type='text'
            id='ifscCode'
            placeholder=''
            onChange={IfscCodeChange}
            value={ifscCode}
            className={
              'updated-input font-weight-medium pl-0 ' +
              (errData['ifscCode'] ? 'is-invalid' : '')
            }
          />
          <Form.Control.Feedback type='invalid'>
            {errData['ifscCode']}
          </Form.Control.Feedback>
        </Form.Group>
        {/* TODO: Do we need to ask the for verification document for all investors? */}
        <div className='col-sm-6 my-3'>
          <UploadBox
            id='upload-canceled'
            title='Upload Cancelled Cheque'
            onChange={onFileSelected}
            value={verificationDoc}
            accept={['pdf', 'jpeg', 'png', 'jpg']}
            err={errData && errData['bankFile']}
          />
        </div>
      </div>
      {bankNameObj && bankNameObj.id && (
        <div className='pt-5'>
          <div className='bank-status d-flex align-items-center py-2'>
            <img
              src={!bankNameObj.payment_mode ? notSupportedIcon : supportedIcon}
              alt='forward icon'
            />
            <span className='ml-1 small'>Netbanking Supported</span>
          </div>
          <div className='bank-status d-flex align-items-center py-2'>
            <img
              src={!bankNameObj.payment_mode ? notSupportedIcon : supportedIcon}
              alt='forward icon'
            />
            <span className='ml-1 small'>UPI Supported</span>
          </div>
          <div className='bank-status d-flex align-items-center py-2'>
            <img src={supportedIcon} alt='forward icon' />
            <span className='ml-1 small'>NEFT/RTGS Supported</span>
          </div>
        </div>
      )}
      {show ? (
        <div className={'bank-actions text-' + alignment}>
          <button
            type='button'
            className='btn btn-primary mt-3'
            onClick={addBankDetails}
          >
            submit
          </button>
        </div>
      ) : null}
    </div>
  );
});
EditBankAccount.propTypes = {
  buttonText: PropTypes.string,
  alignment: PropTypes.string,
  onBankAdd: PropTypes.func,
  bankDetail: PropTypes.object,
  show: PropTypes.bool,
};
EditBankAccount.displayName = 'EditBankAccount';
export default EditBankAccount;
