import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Scope } from '../../types';

interface Rise {
  tax_status: Record<string, any>;
  user_profile_picture: string;
}

interface ProfileObj {
  data: {
    [key: string]: any;
  };
}
export interface AuthState {
  username: string | null;
  email: string | null;
  is_email_activated: boolean;
  is_kyc_verified: boolean;
  is_mobile_verified: boolean;
  mobile_number: string | null;
  nomineeAuthenticationStatus: boolean;
  user_id: string | null;
  rise: Rise;
  ready_to_invest?: boolean;
  scopes: Scope[];
  isMutualFundInScope: boolean;
  isFixedDepositsInScope: boolean;
  fdAppURL: string | null;
  website_url: null;
  profileObj: ProfileObj | null;
  permissions: string[] | [];
  userDetailFromElevoBE: any | null;
}

const initialState: AuthState = {
  username: '',
  email: null,
  is_email_activated: false,
  is_kyc_verified: false,
  is_mobile_verified: false,
  mobile_number: null,
  nomineeAuthenticationStatus: false,
  user_id: null,
  rise: {
    tax_status: {},
    user_profile_picture: '',
  },
  scopes: [],
  isMutualFundInScope: false,
  isFixedDepositsInScope: false,
  fdAppURL: process.env.REACT_APP_FD_APP_URL! ?? null,
  website_url: null,
  profileObj: null,
  permissions: [],
  userDetailFromElevoBE: null,
};

// Create a slice with the reducer and actions
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setScopes: (state, action: PayloadAction<Scope[]>) => {
      state.scopes = action.payload;
      state.isMutualFundInScope =
        action.payload.includes('asset_class:mutual_fund') ?? false;
      state.isFixedDepositsInScope =
        action.payload.includes('asset_class:deposit') ?? false;
    },
    setUser: (state, action) => {
      if (action.payload?.first_name && action.payload?.last_name) {
        state.username =
          action.payload.first_name + ' ' + action.payload.last_name;
      } else if (action.payload?.email) {
        state.username = action.payload.email;
      } else {
        state.username = '#';
      }
    },
    setFdAppURL: (state, action) => {
      action.payload?.apps?.forEach((ele: any) => {
        if (ele.app === 'fd') {
          state.fdAppURL = ele.url;
        }
        if (ele.app === 'website') {
          state.website_url = ele.url;
        }
      });
    },
    updateAuthData: (state, action) => {
      // Can be updated by AuthProvider using token
      Object.assign(state, action.payload);
    },
    updateProfile: (state, action) => {
      Object.assign(state.rise, action.payload);
    },
    removeAuthData: (state) => {
      return initialState;
    },
    updateKycStatus: (state, action) => {
      state.is_kyc_verified = action.payload;
    },
    updateinvestorStatus: (state, action) => {
      state.ready_to_invest = action.payload;
    },
    updateNomineeStatus: (state, action) => {
      state.nomineeAuthenticationStatus = action.payload;
    },
    setProfileObj: (state, action) => {
      state.profileObj = action.payload;
    },
    setUserPermissions: (state, action) => {
      if (action.payload) {
        state.permissions = action.payload;
      }
    },
    setUserDetailFromElevoBE: (state, action) => {
      state.userDetailFromElevoBE = action.payload;
    },
  },
});

export const {
  updateAuthData,
  updateProfile,
  removeAuthData,
  updateKycStatus,
  updateinvestorStatus,
  updateNomineeStatus,
  setScopes,
  setFdAppURL,
  setUser,
  setProfileObj,
  setUserPermissions,
  setUserDetailFromElevoBE
} = authSlice.actions;

// Export the reducer
export default authSlice.reducer;
