import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import {
  BsCardText,
  BsFillFilePostFill,
  BsSave,
  BsShiftFill,
} from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { createBankMandate } from '../../api/bank';
import Toast from '../../common/toast/Toast';
import './styles/selectmandate.css';

import LoaderModel from '../../modal/LoaderModel';
import { updateAutoPayLimitAction } from '../../redux/reducers/mandate-reducer';
import { TOKEN_KEY } from '../../utility/utility';

const SelectMandate = () => {
  const mandateInfo = {
    'first-page': [
      {
        title: 'E-Nach-Mandate',
        info: 'Electronically validate your E-Nach mandate. Authorize your E-Nach mandate by entering your net-banking or debit card details. it takes 3-7 days for the mandate to be accepted',
        about: 'mandate',
        img: '',
      },
      {
        title: 'Nach Mandate',
        info: ' We provide the easiest way to do the NACH Mandate by generating the pre-filled e-Mandate form for you.All you need to do is take a print,sign the form, and upload it on the app.It will take 10 to 45 days for the approval but you can choose any maximum mandate limit.',
        about: 'mandate',
        img: '',
      },
    ],
    'second-page': [
      {
        title: 'Download mandate form',
        info: 'Download the bank mandate form and fill out your detail to set up Auto-payment. This form allows secure auto-debting of a specified amount of money towards your SIP,to create an easier investment experience for you',
        about: 'documents',
        img: '',
      },
      {
        title: 'Upload Scanned mandate',
        info: 'Once you have filled out the bank mandate form, please scan the form and upload it.In case your phone has a low quality camera, please download the CAMScanner or Officelens app to scan the mandate,to scan the bank mandate',
        about: 'documents',
        img: '',
      },
    ],
  };
  const location = useLocation();
  const { bankId, userId } = useParams();
  const dispatch = useDispatch();
  const maxautopayLimit = useSelector(
    (state) => state.mandateReducer.autopay_limit
  );
  const history = useHistory();
  const [selectBox, setSelectBox] = useState(0);
  const [mandateId, setMandateID] = useState('');
  const [boxInfo, setBoxInfo] = useState([mandateInfo['first-page'][1]]);
  const [postObjData, setPostObjData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const hiddenFileInput = useRef(null);
  const formData = new FormData();

  useEffect(() => {
    if (location.state) {
      if (
        location.state.status === 'mandate-completed' &&
        location.state.flow === 'NACH'
      ) {
        setMandateID(location.state.mandateID);
        setBoxInfo(mandateInfo['second-page']);
      } else if (
        location.state.status === 'mandate-completed' &&
        location.state.flow === 'e-NACH'
      ) {
        setMandateID(location.state.mandateID);
        setBoxInfo([mandateInfo['first-page'][0]]);
        dispatch(
          updateAutoPayLimitAction(location.state.maxautopaylimit.toString())
        );
      } else {
        setMandateID('');
        if (location.state.individual) {
          if (Number(maxautopayLimit) > 10000000) {
            setBoxInfo([mandateInfo['first-page'][1]]);
          } else {
            setBoxInfo(mandateInfo['first-page']);
          }
        } else {
          setBoxInfo([mandateInfo['first-page'][1]]);
        }
      }
    } else {
      history.push('/profile/bank-list');
    }
  }, []);
  const selectCardHandler = (index) => {
    // To show box is selected or not - conditional class value
    setSelectBox(index);

    if (boxInfo[0].title === 'Download mandate form') {
      setSelectBox(index);
      if (index === 1) {
        handleClick();
      }
      if (index === 0) {
        downloadMandateHandler();
      }
    }
  };

  const showLoaderHandler = () => {
    setShowLoader(false);
  };

  const createBankMandateFunction = (type) => {
    let postObj = {};
    if (location.state.redirectionUrl) {
      // Check redirection URL then redirect user
      window.location = location.state.redirectionUrl;
    } else {
      //  Not available
      if (location.state.mandateID) {
        // If mandate is registered
        setMandateID(location.state.mandateID);
        setShowLoader(true); // wait for the redirection URL
      } else {
        // Mandate NOT registered then, create it!
        postObj.investor_id = userId;
        postObj.mandate_type = type;
        postObj.bank_id = bankId;
        postObj.auto_debit_limit = parseInt(maxautopayLimit);
        if (type === 'enach') {
          postObj.callback_url = `${
            window.location.protocol +
            '//' +
            window.location.host +
            '/bank-autopay-status'
          }`;
        }

        setPostObjData(postObj);

        createBankMandate(postObj).then((res) => {
          if (res && res.data && type === 'enach') {
            setMandateID(res.data.mandate_id);
            setShowLoader(true);
          } else if (res && res.data && type === 'nach') {
            setMandateID(res.data.mandate_id);
            setBoxInfo(mandateInfo['second-page']); // Download form to sign and upload it back
          } else {
            alert('Mandate type is not known!');
          }
        });
      }
    }
  };

  const contentChangeHandler = () => {
    if (
      boxInfo[selectBox].title === 'E-Nach-Mandate' ||
      boxInfo[selectBox].title === 'Nach Mandate'
    ) {
      if (boxInfo[selectBox].title === 'E-Nach-Mandate') {
        createBankMandateFunction('enach');
      } else {
        createBankMandateFunction('nach');
      }
    } else {
      history.push('/');
    }
  };

  const downloadMandateHandler = () => {
    axios
      .get(
        process.env.REACT_APP_TARRAKKI_API_URL +
          `/bank-mandates/${mandateId}/nach`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        }
      )
      .catch((data) => {
        axios({
          url: data.response.data.download_url,
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          let urlSplit = data.response.data.download_url.split('/');
          let fileName = urlSplit[urlSplit.length - 1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    formData.append('file', fileUploaded);
    if (formData.has('file')) {
      axios
        .put(
          process.env.REACT_APP_TARRAKKI_API_URL +
            `/bank-mandates/${mandateId}/nach`,
          formData,
          {
            headers: {
              Authorization: `bearer ${localStorage.getItem(TOKEN_KEY)}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((res) => {
          window.location = `${
            window.location.protocol +
            '//' +
            window.location.host +
            `/bank-autopay-status?mandate_id=${mandateId}&status=success`
          }`;
        })
        .catch((error) => {
          window.location = `${
            window.location.protocol +
            '//' +
            window.location.host +
            `/bank-autopay-status?mandate_id=${mandateId}&status=failure`
          }`;
        });
    } else {
      Toast.error('Please select file to upload');
    }
  };

  return (
    <div className='wrapper'>
      <h3>Bank Mandate</h3>
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      {boxInfo.map((item, index) => {
        return (
          <div
            className={
              selectBox === index
                ? `mandate-card-selected`
                : 'mandate-card-not-selected'
            }
            onClick={() => {
              selectCardHandler(index);
            }}
          >
            <div className='mandate-icon'>
              {!(boxInfo[0].title === 'Download mandate form') ? (
                boxInfo[0].title === 'E-Nach-Mandate' ? (
                  <BsFillFilePostFill className='icon-file' />
                ) : (
                  <BsCardText className='icon-file' />
                )
              ) : (
                ''
              )}
              {boxInfo[0].title === 'Download mandate form' ? (
                index === 0 ? (
                  <BsSave className='icon-file' />
                ) : (
                  <BsShiftFill className='icon-file' />
                )
              ) : (
                ''
              )}
            </div>
            <div className='mandate-card-body'>
              {/* <img src="" /> */}

              <div className='text-wrapper'>
                <h6 className='title-card-bank-mandate'>{item.title}</h6>
                <p className='text-card-bank-mandate'>{item.info}</p>
              </div>
            </div>
          </div>
        );
      })}
      {boxInfo[0].title !== 'Download mandate form' && (
        <div
          className='continue-btn-mandate'
          onClick={(e) => {
            contentChangeHandler();
          }}
          target='_blank'
          download
        >
          Continue
        </div>
      )}
      {showLoader && (
        <LoaderModel
          show={showLoader}
          showLoaderHandler={showLoaderHandler}
          postObj={postObjData}
          mandateId={mandateId}
        />
      )}
    </div>
  );
};

export default SelectMandate;
