import React, { useEffect, useState } from 'react';
import IndividualOnboarding from './components/InvdividualOnboarding';
import taxStatus from '../../utility/taxStatus';
import { updateKycInfo } from '../../redux/reducers/onboarding-reducer';
import { useDispatch, useSelector } from 'react-redux';
import NriOnboarding from './components/NriOnboarding';
import MinorOnboarding from './components/MinorOnboarding';
import NonIndividualOnboarding from './components/NonIndividualOnboarding';
import { getInvestorDetails } from '../../api/onboarding.api';

// Investor ID will always be available as User has completed the Signup
// TODO: What happens when user is accessing webpage using auto login?
const Onboarding2 = () => {
  const dispatch = useDispatch();
  const {
    user_id: userId,
  } = useSelector((state) => state.authReducer);
  const { pan, pan_kyc_status } = useSelector(
    (state) => state.onboardingReducer
  );
  const [kycData, setKycData] = useState({});

  useEffect(() => {
    updatePanData(userId);
  }, [userId]);

  const updatePanData = (userId) => {
    getInvestorDetails(userId).then((res) => {
      if(res.data && (res.data?.pan || res.data?.guardian_detail?.pan)){
        // Update details in the onboarding reducers
        dispatch(
          updateKycInfo({
            pan: res.data?.pan || res.data?.guardian_detail?.pan,
            pan_kyc_status: taxStatus[res.data?.investor_type],
          })
        );
      }
    })
  };

  useEffect(() => {
    if (pan) {
      setKycData({
        pan: pan,
        tax_status_tcs: pan_kyc_status,
      });
    }
  }, [pan, pan_kyc_status]);

  return (
    <>
      <div className='container onboarding-container colored-box p-md-5'>
        {kycData.tax_status_tcs === taxStatus.nri ||
        kycData.tax_status_tcs === taxStatus.nre ||
        kycData.tax_status_tcs === taxStatus.nro ? (
          <>
            <NriOnboarding kycData={kycData} setKycData={setKycData} />
          </>
        ) : kycData.tax_status_tcs === taxStatus.minor ? (
          <>
            <MinorOnboarding kycData={kycData} setKycData={setKycData} />
          </>
        ) : kycData.tax_status_tcs === taxStatus.individual ? (
          <>
            <IndividualOnboarding kycData={kycData} setKycData={setKycData} />
          </>
        ) : (
          <NonIndividualOnboarding kycData={kycData} setKycData={setKycData} />
        )}
      </div>
    </>
  );
};

export default Onboarding2;
