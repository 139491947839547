import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { IoKeySharp } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { fdApi } from '../../../api/fdApi';
import { UsersOut } from '../../../api/fdApi/services/investors.service';
import { UserGroupsInterface } from '../../../api/fdApi/services/user_groups.service';
import { useDebounce } from '../../../hooks/useDebounce';
import { useAppSelector } from '../../../redux/hooks';
import {
  hasAdminAccess,
  hasAssistModePermission,
} from '../../../utility/utility';
import Chip from '../../shared/component/Chip';
import BrowserSearch from '../Transactions/icons/BrowserSearch.svg';
import { AssignPermissionsToUserModal } from './AssignPermissionsToUserModal';
import { AssignUserGroupsModal } from './AssignUserGroupsModal';
import { PANLinkVerifier } from './PANLinkVerifier';
import './Users.scss';
import { Typeahead } from 'react-bootstrap-typeahead';

export const Users = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const group_name = params.get('group-name');
  // State variables
  const [searchbarValue, setSearchBarValue] = useState<string | null>('');
  const [groupIdValue, setGroupIdValue] = useState<string | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<UserGroupsInterface[]>([]);
  const [users, setInvestors] = useState<UsersOut[]>([] as UsersOut[]);
  const [isLoadingInvestors, setIsLoadingInvestors] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedInvestors, setSelectedUser] = useState<UsersOut>(
    {} as UsersOut
  );
  const [isShowPermissionsModal, setIsShowPermissionsModal] = useState(false);
  const [isUrlProcessed, setIsUrlProcessed] = useState<boolean>(false);

  const debouncedSearchText = useDebounce(searchbarValue);

  // From App wide state
  const { permissions } = useAppSelector((state) => state.authReducer);
  const canViewUsers = hasAssistModePermission(permissions);
  const isAdmin = hasAdminAccess(permissions);
  const hasAccessToPermissionsAction = hasAdminAccess(permissions);
  const hasAccessToEditAction = hasAdminAccess(permissions);

  // APIs
  const { data: UserGroups } = useQuery(['Usergroups'], async () => {
    const res = await fdApi.UserGroups.getUserGroups('');
    return res;
  });

  const getUsers = (search_text: string, group_ids: string) => {
    setIsLoadingInvestors(true);
    fdApi.investors
      .getInvestors(search_text, group_ids)
      .then((res) => {
        setInvestors(res);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoadingInvestors(false);
      });
  };

  useEffect(() => {
    if (group_name && UserGroups?.length > 0 && !isUrlProcessed) {
      const getGroupIdByName = (groupName: string) => {
        const group = UserGroups?.find((g) => g.name === groupName);
        return group ? group.id : null;
      };

      const group_id = getGroupIdByName(group_name);

      if (group_id) {
        setGroupIdValue(group_id.toString());
        setSelectedGroup(
          UserGroups?.filter((group) => group.id === group_id) || []
        );

        getUsers('', group_id.toString());
        setIsUrlProcessed(true);
        return;
      }
    }

    if (!isUrlProcessed) {
      getUsers('', '');
      setIsUrlProcessed(true);
    }
  }, [group_name, UserGroups]);

  useEffect(() => {
    if (isUrlProcessed) {
      getUsers(debouncedSearchText, groupIdValue || '');
    }
  }, [debouncedSearchText, groupIdValue]);

  const handleGroupChange = (selected: any) => {
    if (selected.length === 0) {
      setGroupIdValue('');
      setSelectedGroup([]);
      params.delete('group-name');
    } else {
      const selectedIds = selected.map((group: any) => group.id).join(',');
      const selectedGroupName = selected[0].name;

      setGroupIdValue(selectedIds);
      setSelectedGroup(selected);

      params.set('group-name', selectedGroupName);
      setIsUrlProcessed(true);
    }
  };

  // Methods
  const openEditUserModal = (user: any) => {
    setIsShowModal(true);
    setSelectedUser(user);
  };

  const handlePermissionsActions = (user: UsersOut) => {
    setIsShowPermissionsModal(true);
    setSelectedUser(user);
  };

  if (!canViewUsers) {
    return (
      <>
        <div>
          <h1>Forbidden</h1>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='Investors_container'>
        <div className='Investors_header'>
          <span className='Investors_header_title'>Users</span>
          <div className='Investors_search_group'>
            {isAdmin && (
              <div className='Investors_select_container'>
                <Typeahead
                  id='UserGroupsToAdd'
                  size='sm'
                  labelKey='name'
                  options={UserGroups || []}
                  placeholder='Search By Group'
                  onChange={handleGroupChange}
                  selected={selectedGroup} // Fix for pre-selection
                  multiple
                />
              </div>
            )}
            <div className='Investors_search_container'>
              <img src={BrowserSearch} alt='searchicon' />
              <input
                placeholder='Search'
                value={searchbarValue as string}
                onChange={(event) => setSearchBarValue(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='Investors_horizontal_divider'></div>
        <div className='Investors_table_container'>
          {!isLoadingInvestors ? (
            <div className='Investors_table'>
              <div className='Investors_table_header'>
                <div>First Name</div>
                <div>Last Name</div>
                {/* <div>PAN</div> */}
                <div>Email</div>
                <div>Mobile</div>
                <div>Groups</div>
                <div>Actions</div>
              </div>
              <div className='Investors_table_rows'>
                {users?.length !== 0 ? (
                  users?.map((user: any) => {
                    if (!user) {
                      return null;
                    }
                    return (
                      <>
                        <div
                          className='Investors_table_row'
                          key={Math.random()}
                        >
                          <div>
                            {Boolean(user.first_name) ? user.first_name : '-'}
                          </div>
                          <div>
                            {Boolean(user.last_name) ? user.last_name : '-'}
                          </div>
                          {/* <div>{Boolean(user?.pan) ? user.pan : '-'}</div> */}
                          <div>{user.email}</div>
                          <div>{user.mobile}</div>
                          <div>
                            {user.user_group.map(
                              (group: UserGroupsInterface) => {
                                return (
                                  <>
                                    <Chip
                                      label={<span>{group.name}</span>}
                                      filledColor='#08aeea'
                                    />
                                  </>
                                );
                              }
                            )}
                          </div>
                          <div className='Investors_table_row_field_actions'>
                            {/* TODO: Needed for Quick links to book an FD, not enabled now */}
                            <PANLinkVerifier investor={user} />
                            {hasAccessToEditAction && (
                              <>
                                <div className='Investors_vertical_divider'></div>
                                <button
                                  onClick={() => openEditUserModal(user)}
                                  className='Investors_table_edit_button'
                                >
                                  <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 100, hide: 200 }}
                                    overlay={
                                      <Tooltip id='edit-icon'>Edit</Tooltip>
                                    }
                                  >
                                    <MdModeEditOutline
                                      size={25}
                                      color='#a7aaab'
                                    />
                                  </OverlayTrigger>
                                </button>
                              </>
                            )}
                            {hasAccessToPermissionsAction && (
                              <>
                                <div className='Investors_vertical_divider'></div>
                                <button
                                  onClick={() => handlePermissionsActions(user)}
                                  className='Investors_table_edit_button'
                                >
                                  <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 100, hide: 200 }}
                                    overlay={
                                      <Tooltip id='permissions-icon'>
                                        Permissions
                                      </Tooltip>
                                    }
                                  >
                                    <IoKeySharp size={25} color='#a7aaab' />
                                  </OverlayTrigger>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <span className='Investors_table_no_data'>
                      No Users Found.
                    </span>
                  </>
                )}
              </div>
            </div>
          ) : (
            <span>Loading Investors...</span>
          )}
        </div>
      </div>
      {isShowModal && (
        <AssignUserGroupsModal
          user={selectedInvestors}
          isShow={isShowModal}
          userGroups={UserGroups || []}
          setIsShow={setIsShowModal}
          onHide={() => getUsers(debouncedSearchText, groupIdValue || '')}
        />
      )}
      {isShowPermissionsModal && (
        <AssignPermissionsToUserModal
          user={selectedInvestors}
          isShowModal={isShowPermissionsModal}
          setIsShowModal={setIsShowPermissionsModal}
          listOfGroups={UserGroups || []}
        />
      )}
    </>
  );
};
