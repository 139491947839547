 const taxStatus ={

    llp : '47',
    partnership : '06',
    proprietor : '13',
    private_limited : '52',
    limited : '51',
    huf : '03',
    trust : '08',
    minor : '02',
    individual : '01',
    nri : '11',
    nre : '21',
    nro : '24',
    bank_cooperative_bank : '75'
 }

 export default taxStatus;
 
