import { client } from '../client';
import { UserGroupsInterface } from './user_groups.service';

export interface UsersOut {
  pan: string;
  id: string;
  email: string;
  mobile: string;
  username: string;
  user_group: UserGroupsInterface[];
}

export interface UpdateInvestorsPayloadType {
  group_ids: String[];
}

export interface GrantPermissionsInterface {
  id: string;
  user_group_ids: string[];
}

export interface GrantedPermissionsInterface {
  [key: string]: GrantPermissionsInterface[];
}

interface VkycStatus {
  issuer_id: string;
  vkyc_status: string;
}
export interface InvestorVkycStatus {
  investors: [VkycStatus];
}

const getInvestors = async (
  search_text: string | null,
  group_ids: string | null,
): Promise<UsersOut[]> => {
  const params: any = {};

  if (search_text) params.search_text = search_text;
  if (group_ids) params.group_ids = group_ids;

  try {
    const res = await client.get('/users', { params });
    return res.data;
  } catch (err) {
    console.error('Error fetching investors:', err);
    throw err;
  }
};

const getInvestorVkycStatus = async (
  id: string,
  email_id: string,
  mobile: string
): Promise<InvestorVkycStatus> => {
  const res = await client.get(`/products/fd/investors/${id}/vkyc-status`, {
    params: {
      email_id,
      mobile,
    },
  });
  return res.data;
};

const updateInvestor = async (
  user_id: string,
  input: UpdateInvestorsPayloadType
) => {
  const res = await client.patch(`/users/${user_id}`, input);
  return res.data;
};

export const getGrantedPermissions = async (user_id: string): Promise<any> => {
  const res = await client.get(`/users/${user_id}/permissions`);
  return res.data;
};

export const getGroupsByUserId = async (user_id: string) => {
  const res = await client.get(`/users/${user_id}/groups`);
  return res.data;
};

export const postPermissionsToBeGranted = async (
  user_id: string,
  input: GrantPermissionsInterface
) => {
  const res = await client.post(`/users/${user_id}/permissions`, input);
  return res.data;
};

const api = {
  getInvestors,
  getGrantedPermissions,
  getGroupsByUserId,
  updateInvestor,
  postPermissionsToBeGranted,
  getInvestorVkycStatus,
};

export default api;
