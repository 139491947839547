import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputBox, RadioGroups, SelectBox } from '../../../common/form';
import Toast from '../../../common/toast/Toast';
import { incomeSlab, incomeSource , occupationNewList, genderList, handleInputs  } from '../../../utility/utility';
import { Form } from 'react-bootstrap';
import { placeofbirthRegex } from '../../../utility/constant';
import { getInvestorBankDetails } from '../../../api/bank';
import { getInvestorDocuments, postInvestorDocuments } from '../../../api/mfApi/documents';
import { getInvestorDetails, saveInvestorDetails } from '../../../api/onboarding.api';
import DocumentUpload from '../../../common/on-boarding/DocumentUpload';
import NewBankNri from '../../../component/bank-accounts/NewBankNri';
import NominationField from '../../../component/nomination-field';
import { addNominee, updateNominee } from '../../../api/nominee';

const NriOnboarding = ({kycData, setKycData}) => {
  const history = useHistory();
  const { pan, pan_kyc_status } = useSelector((state) => state.onboardingReducer);
  const {
      user_id: userId,
      email,
      mobile_number,
    } = useSelector((state) => state.authReducer);
  const [,setKycDataNri] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cityName, setCityName] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [stateName, setStateName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [DOB, setDOB] = useState('');
  const [gender, setGender] = useState('');
  const [nriAddress, setNriUserAddress] = useState('');
  const [errors, setErrors] = useState([]);
  const [annualIncome, setAnnualIncome] = useState('');
  const [placeOfBirth, setPlace] = useState('');
  const [wealthSource, setWealthSource] = useState('');
  const [birthCountry, setBirthCountry] = useState('India');
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState('');
  const [checked, setChecked] = useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [nomineeDetails, setNomineeDetails] = useState();
  const [nomineeErrors, setNomineeErrors] = useState([]);
  const [commonNomineeError, setCommonNomineeError] = useState();
  const [fatcaSubmited, setFatcaSubmited] = useState(false);
  const [bankUpdated, setBankUpdated] = useState(false);
  const [nomineeSubmited, setNomineeSubmited] = useState(false);
  const [occupation, setOccupation] = useState('');
  const [bankDetails, setBankDetails] = useState();
  const [investorType, setInvestorType] = useState(undefined);
  const [documentsList, setDocumentsList] = useState([
    {
      document_type: 'signature',
      id: '06',
      is_multiple_upload: false,
      name: 'Upload your signature',
      is_uploaded: false,
    },
  ]);

  const childRef = useRef();
  const childRefDoc = useRef();

  useEffect(() => {
    getUserKycDataNri();
  }, [pan_kyc_status]);

  useEffect(() => {
      if (userId) {
        populateInvestorBankDetails();
        populateInvestorDocuments();
      }
    }, []);

  const populateInvestorBankDetails = () => {
    // Fetch investor's bank details by investor ID (used when the form is marked as 'Save as Draft')
    getInvestorBankDetails(userId).then((res) => {
      if (res.data && res.data.length !== 1) return;

      const bankDetail = {
        id: res.data[0].bank_id,
        account_type_bse: res.data[0].account_type,
        account_number: res.data[0].account_number,
        branch__ifsc_code: res.data[0].ifsc,
        verification_document: res.data[0].verification_document,
      };

      setBankDetails(bankDetail);
    });
  };

  const populateInvestorDocuments = () => {
    getInvestorDocuments(userId).then((res) => {
      if (res.data.length === 0) return;
      const newState = [];
      for (const doc of res.data) {
        for (const docState of documentsList) {
          if (docState.document_type === doc.document_type) {
            newState.push({
              ...docState,
              is_uploaded:
                docState.document_type === doc.document_type ? true : false,
            });
          }
        }
      }
      if (newState.length >= 0) {
        setDocumentsList(newState);
      }
    });
  };

  const getUserKycDataNri = () => {
    getInvestorDetails(userId).then((res) => {
      if (res && res.data) {
        let investorType = res.data?.investor_type;
        setInvestorType(investorType)
        if (investorType === 'nri' || investorType === 'nre' || investorType === 'nro') {
          setKycDataNri(res.data);
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          setDOB(res.data.dob);
          setGender(res.data.gender);
          setNriUserAddress(res.data.contact_address.address);
          setCityName(res.data.contact_address.city);
          setPinCode(res.data.contact_address.pincode);
          setStateName(res.data.contact_address.state);
          setCountryName(res.data.contact_address.country);
          setOccupation(res.data.fatca_detail.occupation);
          setAnnualIncome(res.data.fatca_detail.income_slab);
          setPlace(res.data.fatca_detail.birth_place);
          setWealthSource(res.data.fatca_detail.income_source);
          setBirthCountry(res.data.fatca_detail.birth_country);
          setfatcaData(res.data);
        }
      }
    });
  };

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const onNriAddressChange = (event) => {
    setNriUserAddress(event.target.value);
    
  };
  const onCityPlaceChange = (event) => {
    setCityName(event.target.value);
  
  };
  const onPinCodeChange = (event) => {
    setPinCode(event.target.value);
  
  };
  const onStateNameChange = (event) => {
    setStateName(event.target.value);
  };

  const onCountryChange = (event) => {
    setCountryName(event.target.value);
  };

  const onDOBChange = (event) => {
    setDOB(event.target.value);
  };

  const handleGenderChange = (value) => {
    setGender(value);
    errors['gender'] = '';
    setErrors(errors);
  };

  const handleBirthCountryChange = (value) => {
    setBirthCountry(value)
    let error = errors;
    if (value && value.length > 0) {
      error['birthCountry'] = undefined;
      setErrors(error)
    }
  };

  const onBirthPlaceChange = (event) => {
    let inputValue = event.target.value || '',
      placeErr = '';
    if (!inputValue) {
      setPlace('');
    } else if (!placeofbirthRegex.test(inputValue)) {
      placeErr = 'Please enter a Place of birth without spaces or characters like("-", "&", "@", etc)';
    } else {
      placeErr = undefined;
      setPlace(inputValue);
    }
    setPlaceOfBirthErr(placeErr);
  };

  const handleWealthSourceChange = (value) => {
    setWealthSource(value)
    let error = errors;
    if (value && value.length) {
      error['wealthSource'] = undefined;
      setErrors(error)
    }
  };

  const onRadioChange = (event) => {
    const { value } = event.target;
    setAnnualIncome(value);
  };

  const handleSingleDocumentUpload = (type, document) => {
    const payload = new FormData();
    payload.append('document_type', 'signature');
    payload.append('file', document);
    postInvestorDocuments(userId, payload)
      .then((res) => {
        setDocumentsList((prev) => {
          const newState = [];
          for (const i in prev) {
            newState.push({ ...i, is_uploaded: true });
          }
          return newState;
        });
        Toast.success('Document uploaded successfully!');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const setfatcaData = (data) => {
    // TODO: How to retrieve PEP declaration
    if (data.mobile && data.email_declaration && data.mobile_declaration) {
      setChecked(true);
      setMobileDeclaration(true);
      setEmailDeclaration(true);
    }
    if (!data.mobile && data.email_declaration) {
      setChecked(true);
      setEmailDeclaration(true);
    }
  };

  const onBankAddNri = () => {
    getUserKycDataNri();
  };

  const onBankSubmitted = () => {
    setBankUpdated(true);
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (mobile_number) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
    // onChange({ value, checked });
  };

  const handleOccupationChange = (value) => {
    setOccupation(value);
    errors['occupation'] = '';
    setErrors(errors);
  };

  const validateNomineeDetails = (isSubmit) => {
    let isValid = true;

    nomineeDetails.forEach((item, index) => {
      nomineeErrors[index] = {};

      if (item.name) {
        if (!item.relation) {
          isValid = false;
          nomineeErrors[index].relation = 'Relation is required';
        }
        if (!item.share) {
          isValid = false;
          nomineeErrors[index].share = 'Share is required';
        }
      }
    });

    setNomineeErrors([...nomineeErrors]);

    const nomineeTotal = getNomineeTotal();

    if (nomineeTotal > 100) {
      setCommonNomineeError(
        'Total of share for all nominees should not exceed 100%'
      );
      isValid = false;
    } else if (isSubmit && nomineeTotal < 100) {
      setCommonNomineeError(
        'Total of share for all nominees should be exactly 100%'
      );
      isValid = false;
    } else {
      setCommonNomineeError('');
    }

    return isValid;
  };


  const handleNomineeDetailChange = (data) => {
    setNomineeDetails([...data]);
    nomineeErrors.forEach((err, index) => {
      if(err && err.relation) {
        if(data[index].relation) {
          err.relation = undefined
        }
      }
      if(err && err.share) {
        if(data[index].share) {
          err.share = undefined
        }
      }
    });
    setCommonNomineeError(undefined)
    setNomineeErrors([...nomineeErrors]);
  };

  const getNomineeTotal = () => {
    let shareCount = 0;
    nomineeDetails.forEach(item => {
      shareCount = shareCount + parseInt(item.share);
    });
    return shareCount;
  }

  const submitNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach(item => {
      shareCount = shareCount + item.share;
    });
    if(shareCount < 100) {
      setCommonNomineeError("Total of share for all nominee should be 100%");
    } else {
      saveNomineeDetails();
    }
  };

  const saveNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    if (shareCount > 100) {
      setCommonNomineeError(
        'Total of share for all nominee should be not be greater than 100%'
      );
    } else {
      const nomineesToAdded = [];

      // Update Nominee Details
      for (let i in nomineeDetails) {
        if (nomineeDetails[i].id) {
          updateNomineeDetails(nomineeDetails[i]);
        } else {
          nomineesToAdded.push(nomineeDetails[i]);
        }
      }

      // Add Nominee Details
      if (nomineesToAdded?.length > 0) {
        addNomineeDetails(nomineesToAdded);
      }
    }
  };

  const updateNomineeDetails = (data) => {
    if(data?.name) {
      let request = {
        name: data.name,
        relation: data.relation,
        share: parseInt(data.share),
        minor: false
      };
      updateNominee(userId, data.id, request).then(response => {
        setNomineeSubmited(true)
      })
    } else {
      setNomineeSubmited(true)
    }
  };

  const addNomineeDetails = (nominees) => {
    /**
     * @param nominees - [{name: string, relation: string, share: number, minor: boolean}]
     * @returns void
     */
    if (nominees?.length > 0) {
      const payload = {
        nominees: nominees.map((nominee) => {
          return {
            name: nominee.name,
            relation: nominee.relation,
            share: parseInt(nominee.share),
            minor: false,
          };
        }),
      };
      addNominee(userId, payload).then(() => {
        setNomineeSubmited(true);
      });
    } else {
      // Not expected to reach here...
      Toast.error('Failed to submit nominee details! Please try again!');
      setNomineeSubmited(false);
    }
  };

  const getConsentText = (mobile, email, firstName, lastName) => {
    const investorName =
      !firstName || !lastName ? 'me' : `${firstName} ${lastName}`;
    if (!mobile) {
      return `I confirm that email ID ${email} belong to ${investorName}.`;
    }
    return `I confirm that mobile number ${mobile} and email ID ${email} belong to ${investorName}.`;
  };

  const saveFormData = () => {
    if(validateNomineeDetails()) {
      const req = {
        investor_type: investorType,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        pan: pan,
        // email: email,
        // mobile: mobile_number,
        email_declaration: emailDeclaration ? 'self' : undefined,
        mobile_declaration: mobileDeclaration ? 'self' : undefined,
        dob: DOB,
        gender: gender,
        fatca_detail: {
          occupation: occupation,
          income_source: wealthSource,
          income_slab: annualIncome,
          birth_place: placeOfBirth,
          birth_country: birthCountry,
        },
        contact_address: {
          address: nriAddress,
          city: cityName,
          state: stateName,
          country: countryName,
          pincode: pinCode,
        },
      };
      saveInvestorDetails(userId, req)
        .then((response) => {
          history.push('/home');
        })
        .catch((error) => {
          console.error(error);
        });

      onSubmit();
      saveNomineeDetails();
    }
  };

  const validateData = () => {

    let errors = [];
    if (!wealthSource || wealthSource.length === 0) {
      errors['wealthSource'] = 'Wealth Source is required.';
    }
    if (!birthCountry || birthCountry.length === 0) {
      errors['birthCountry'] = 'Country of Birth is required.';
    }
    if (!placeOfBirth || placeOfBirth.length === 0) {
      setPlaceOfBirthErr('Place of Birth is required.');
    }
    setErrors(errors);
    let status = handleErrorsBankNri();
    if((errors && Object.keys(errors).length > 0) || !status) {
      return false;
    }
    return true;
  };

  const handleErrorsBankNri = () => {
    let status = childRef.current.validate()
    let status1 = childRefDoc.current.validateDoc()
    if(status && status1) {
      return true
    }
    return false;
  };

  const submitFormData = () => {
    let isNomineeValid = validateNomineeDetails(true);
    if (validateData() && isNomineeValid) {
      const req = {
        investor_type: investorType,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        pan: pan,
        // email: email,
        // mobile: mobile_number,
        email_declaration: emailDeclaration ? 'self' : '',
        mobile_declaration: mobileDeclaration ? 'self' : '',
        dob: DOB,
        gender: gender,
        fatca_detail: {
          occupation: occupation,
          income_source: wealthSource,
          income_slab: annualIncome,
          birth_place: placeOfBirth,
          birth_country: birthCountry,
        },
        contact_address: {
          address: nriAddress,
          city: cityName,
          state: stateName,
          country: countryName,
          pincode: pinCode,
        },
      };
      if (emailDeclaration) {
          saveInvestorDetails(userId, req)
          .then((response) => {
            setFatcaSubmited(true);
          })
          .catch((error) => {
            console.error(error);
          });
        onSubmit();
        submitNomineeDetails();
      } else {
        setDeclarationError(`Please confirm your Email ${mobile_number ? 'and Mobile ' : ''}Declaration.`);
      }
    }
  };

  useEffect(() => {
    if (fatcaSubmited && bankUpdated) {
      history.push('/home');
    }
  }, [fatcaSubmited, bankUpdated]);

  const onSubmit = () => {
    if (bankDetails?.id) {
      childRef.current.updateNewBank();
    } else {
      childRef.current.addNewBank();
    }
  };

  return (
    <>
      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-first-name'
            title={'First Name'}
            value={firstName}
            onChange={onFirstNameChange}
            onBlur={(e) => setFirstName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-last-name'
            title={'Last Name'}
            value={lastName}
            onChange={onLastNameChange}
            onBlur={(e) => setLastName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
      </div>
      <div className="col-sm-12 mt-3">
        <InputBox
          id="onboarding-address"
          title={'Indian Contact Address'}
          value={nriAddress}
          onChange={onNriAddressChange}
          onBlur={(e) => setNriUserAddress(handleInputs(e.target.value))}
          type="text"
        />
      </div>
      <div className="d-flex">
      <div className="col-sm-4 mt-3">
          <InputBox
            id="pincode"
            className="col-sm-12"
            labelClass="opacity-75 h6 py-2"
            onChange={onPinCodeChange}
            title="Pincode"
            value={pinCode}
            inputMode={'numeric'}
            type="number"
          />
        </div>
        <div className="col-sm-4 mt-3">
          <InputBox
            id="City"
            className="col-sm-12"
            labelClass="opacity-75 h6 py-2"
            onChange={onCityPlaceChange}
            onBlur={(e) => setCityName(handleInputs(e.target.value))}
            title="City"
            value={cityName}
            type="text"
          />
        </div>
        <div className="col-sm-4 mt-3">
          <InputBox
            title="State"
            value={stateName}
            className="col-sm-12"
            labelClass="opacity-75 h6 py-2"
            id="countryBirth"
            onChange={onStateNameChange}
            onBlur={(e) => setStateName(handleInputs(e.target.value))}
            type="text"
          />
        </div>
      </div>
      <div className='d-flex'>
        <div className='col-sm-4 mt-3'>
          <InputBox
            id='Country'
            title={'Country'}
            value={countryName}
            onChange={onCountryChange}
            onBlur={(e) => setCountryName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-4 mt-3'>
          <InputBox
            id='DOB'
            title={'Date Of Birth'}
            value={DOB}
            onChange={onDOBChange}
            type='date'
          />
        </div>
        <div className='col-sm-4'>
          <SelectBox
            id='Gender'
            title={'Gender'}
            value={gender}
            classes={{ root: 'rounded-none' }}
            onUpdate={handleGenderChange}
            options={genderList}
            placeholder='Select gender'
            err={errors && errors['gender']}
          />
        </div>
      </div>
      <div className="col-sm-12 mt-3">
        <h6 className="font-weight-bold mt-4">Documents</h6>
        <DocumentUpload
          kycData={kycData}
          documentList={documentsList}
          panStatus={pan_kyc_status}
          singleDocUpload={handleSingleDocumentUpload}
          title={""}
          ref={childRefDoc}
        />
      </div>
      <section className="col-sm-12">
        <h6 className="font-weight-bold mt-5">Bank Details</h6>
        <div className="border p-4 rounded">
          <NewBankNri
            alignment="center"
            ref={childRef}
            buttonText="Submit"
            bankDetail={bankDetails}
            onBankAdd={onBankAddNri}
            onBankSubmitted={onBankSubmitted}
          />
        </div>
      </section>
      <section className="col-sm-12">
        <h6 className="font-weight-bold mt-5">Details for FATCA</h6>
        <div className='border p-4 rounded w-100 my-3'>
          <SelectBox
            title='Occupation'
            value={occupation}
            id='Occupation'
            classes={{ root: 'col-sm-3 px-0 ' }}
            onUpdate={handleOccupationChange}
            options={occupationNewList}
            placeholder='Select occupation'
            err={errors && errors['occupation']}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <SelectBox
            title="Wealth Source"
            value={wealthSource}
            id="WealthSource"
            classes={{ root: 'col-sm-3 px-0 ' }}
            onUpdate={handleWealthSourceChange}
            options={incomeSource}
            placeholder="Select wealth source"
            err={errors && errors['wealthSource']}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <RadioGroups
            title="Annual Income"
            id="annualIncome"
            radioList={incomeSlab}
            selectedVal={annualIncome}
            onChange={onRadioChange}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <InputBox
            id="PlaceofBirth"
            className="col-sm-5"
            labelClass="opacity-75 h6"
            onChange={onBirthPlaceChange}
            title="Place of Birth"
            value={placeOfBirth}
            type="text"
            maxLength={40}
            err={placeOfBirthErr}
          />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <NominationField nomineeDetailChange={handleNomineeDetailChange} errors={nomineeErrors} commonError={commonNomineeError} nomineeSubmited={nomineeSubmited} />
        </div>
        <div className="border p-4 rounded w-100 my-3">
          <SelectBox
            title="Country of Birth"
            value={birthCountry}
            id="countryBirth"
            classes={{ root: 'col-sm-3 px-0' }}
            onUpdate={handleBirthCountryChange}
            options={[{ label: 'India', value: 'India' }]}
            placeholder="Select country of birth"
            err={errors && errors['birthCountry']}
          />
        </div>
        <div className='border p-4 rounded w-100 my-3'>
          <Form.Check>
            <Form.Check.Input
              id='declaration'
              type='checkbox'
              checked={checked}
              onChange={checkboxHandleChange}
              style={{ cursor: 'pointer' }}
            />
            <Form.Check.Label
              htmlFor='declaration'
              onChange={checkboxHandleChange}
              style={{ cursor: 'pointer' }}
            >
              {getConsentText(mobile_number, email, firstName, lastName)}
            </Form.Check.Label>
          </Form.Check>
          {declarationError && (
            <div className={'invalid-feedback d-block mt-2'}>
              {declarationError}
            </div>
          )}
        </div>
      </section>
      <div className=" btn-container text-center my-4  col-sm-12">
        <button type="button" className="btn btn-outline-primary m-2" onClick={saveFormData}>
          Save As Draft
        </button>
        <button type="button" className="btn btn-primary m-2 btn-width" onClick={submitFormData}>
          Submit
        </button>
      </div>
    </>
  );
};

export default NriOnboarding;
