import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

//API Call
import { getKYCStatus } from '../api/kyc';

//Helper
import { KycStatusEnum } from '../enums';
import { useAppSelector } from '../redux/hooks';
import { useKyc } from '../hooks/useKyc';

interface KycVerifiedModalProps {
  show: boolean;
  isNri: boolean;
  header: string;
  message: string;
  onSubmit: any;
  pan: string;
  kycStatusCheck: (status: string) => void;
}

const KycVerifiedModal = ({
  show,
  onSubmit,
  header,
  isNri,
  pan,
  kycStatusCheck,
}: KycVerifiedModalProps) => {
  const { supportEmail } = useAppSelector((state) => state.whitelabelReducers);
  const [kycStatus, setKYCStatus] = React.useState<KycStatusEnum>();
  const { initiateKyc } = useKyc();

  const onCallInitiateKYC = () => initiateKyc(pan);

  useEffect(() => {
    if (pan) {
      getKYCStatus(pan).then((res: any) => {
        const status = res?.data?.status || '';
        setKYCStatus(status);
        kycStatusCheck(status);
      });
    }
  }, [pan]);


  if (!kycStatus) {
    return;
  }

  return (
    <>
      {kycStatus === KycStatusEnum.pending && (
        <Modal
          show={show}
          size={isNri ? 'lg' : 'sm'}
          aria-labelledby='kyc-verified-modal'
          onHide={onSubmit}
          centered
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header className='justify-content-center'>
            <span className='font-weight-medium h5'>
              {header || 'KYC is not verified'}
            </span>
          </Modal.Header>
          <Modal.Body className='small text-center'>
            <span>
              {
                'Your KYC is not registered, would you like to get your PAN KYC registered?'
              }
            </span>
          </Modal.Body>
          <Modal.Footer className='btn-container justify-content-center'>
            <Button
              onClick={onCallInitiateKYC}
              className='btn btn-primary w-25'
            >
              Yes
            </Button>
            <Button onClick={onSubmit} className='btn btn-primary w-25'>
              No
            </Button>
            <div className='small text-center'>
              <span>
                {`If you are an NRI, Please contact ${
                  supportEmail ? supportEmail : 'Customer Support'
                } for more assistance.`}
              </span>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {kycStatus !== KycStatusEnum.pending &&
        kycStatus !== KycStatusEnum.registered &&
        kycStatus !== KycStatusEnum.validated && (
          <Modal
            show={show}
            size={isNri ? 'lg' : 'sm'}
            aria-labelledby='kyc-verified-modal'
            onHide={onSubmit}
            centered
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header className='justify-content-center'>
              <span className='font-weight-medium h5'>
                {header || 'KYC is not verified'}
              </span>
            </Modal.Header>
            <Modal.Body className='small text-center'>
              <span>{`Your KYC status is ${kycStatus}`}</span>
            </Modal.Body>
            <Modal.Footer className='btn-container justify-content-center'>
              <Button onClick={onSubmit} className='btn btn-primary w-50'>
                Continue
              </Button>
              <div className='small text-center'></div>
            </Modal.Footer>
          </Modal>
        )}
    </>
  );
};

export default KycVerifiedModal;