import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';

const OptionsType4 = ({
  options,
  question,
  updateAnswerJson,
  errors,
  value,
  step,
}) => {
  const [value1, setValue1] = useState();

  const handleValueSelect = (val) => {
    setValue1(val);
    const answer = {
      question_id: question.question_id?.toString(),
      options: [
        {
          option_id: val.option_id?.toString(),
        },
      ],
    };
    updateAnswerJson(answer);
  };

  useEffect(() => {
    setValue1(undefined);
  }, [...options]);

  useEffect(() => {
    if (value) {
      options.forEach((item, index) => {
        if (item.option_id?.toString() === value?.options[0]?.option_id) {
          setValue1(index);
        }
      });
    }
  }, [value]);

  return (
    <>
      <div className={'options mt-2'}>
        <div className={'option-group flex'}>
          {options?.map((item, index) => (
            <label htmlFor={`radio-option-${index}`} className={'w-100 mt-2'}>
              <div
                className={`radio-option h-100 ${
                  value1 === index ? 'selected' : ''
                }`}
                onClick={() => handleValueSelect(item)}
              >
                <Radio
                  checked={value1 === index}
                  value={index}
                  name={'radio-option-3'}
                />
                <div className={'option-text ml-3'}>{item.option_value}</div>
              </div>
            </label>
          ))}
        </div>
        {errors && errors[step] && (
          <div className={'invalid-feedback d-block mt-4'}>{errors[step]}</div>
        )}
      </div>
    </>
  );
};

export default OptionsType4;
