import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DropdownList from '../dropdown-list/DropdownList';
const SelectBox = ({ onUpdate, id, title, value, options = [], placeholder = '', classes = {}, err }) => {
  const onDropDownChange = (event, obj) => {
    const { value } = obj;
    if (onUpdate) onUpdate(value);
  };
  const getLabel = (value) => {
    const filterData = options.filter((item) => item.value === value);
    return filterData && filterData.length ? filterData[0].label : '';
  };
  return (
    <Form.Group className="my-3 w-full">
  <Form.Label className="opacity-50 mb-1">{title}</Form.Label>
  <DropdownList
    classes={{
      input: "font-medium border border-gray-300 rounded py-1 w-full",
      ...classes,
    }}
    selectedOption={getLabel(value)}
    id={id}
    placeholder={placeholder}
    controlFunc={onDropDownChange}
    options={options}
  />
  {err && <div className="text-red-500 text-sm mt-1">{err}</div>}
</Form.Group>
  );
};
SelectBox.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onUpdate: PropTypes.func,
  options: PropTypes.array,
};
export default SelectBox;
