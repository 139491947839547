import { Fragment, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { uploadProfileDoc } from '../../api/profile';
import ProfileIcon from '../../assets/Profile.svg';
import AddIcon from '../../assets/add-profile.svg';
import ForwardArrowIcon from '../../assets/forward-arrow.svg';
import { UploadBox } from '../../common/form';
import Toast from '../../common/toast/Toast';
import BankAccounts from '../../component/bank-accounts/BankAccounts';
import EditBankAccount from '../../component/bank-accounts/EditBankAccount';
import NewBankNri from '../../component/bank-accounts/NewBankNri';
import Banner from '../../component/banner/Banner';
import MySips from '../../component/my-sips/MySips';
import ProfileComponent from '../../component/profile/ProfileComponent';
import { updateProfile } from '../../redux/reducers/auth-reducer';
import { decryptData } from '../../utility/DecryptHelper';
import { getInvestorsStatus } from '../../utility/investorStatus';
import ChangePassword from '../../component/profile/ChangePassword';
import VkycStatus from '../../component/profile/VkycStatus';

const Profile = () => {
  const { user_profile_picture, user_name, tax_status } = useSelector(
    (state) => state.authReducer.rise
  );
  const { ready_to_invest, isMutualFundInScope } = useSelector(
    (state) => state.authReducer
  );
  const userId = useSelector((state) => state.authReducer.user_id);

  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const data = location.state;
  const nrinrenro = ['11', '21', '24'];

  const [eventKey, setKey] = useState(location.pathname);
  const [show, setShow] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    getInvestorsStatus(userId, dispatch);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.pathname === '/profile/add-bank' && data && data.bankDetails) {
      setShow(true);
    } else if (eventKey === '/profile/add-bank') {
      setShow(true);
    }
  }, [1]);
  const onLeftNavSelect = (eKey) => {
    if (eKey !== eventKey) {
      history.push(eKey);
      setKey(eKey);
      setShow(true);
    }
  };

  const updateProfileDocument = (name, file) => {
    const data = new FormData();
    data.append(name, file);
    uploadProfileDoc(data).then((res) => {
      if (res && res.data) {
        if (res.data.status.message && res.data.status.code === 1) {
          Toast.success(res.data.status.message);
        }
        const profileDOcs = decryptData(res.data.data);
        if (name === 'profile_picture') {
          dispatch(
            updateProfile({
              user_profile_picture: profileDOcs.data.user_profile_image,
            })
          );
        }
      }
    });
  };

  const redirectToHome = () => {
    history.push('/home');
  };

  return (
    <>
      {windowDimensions.width > 480 ? (
        <div className='profile-container container'>
          <Banner />

          <div className='row'>
            <div className='col-sm-4 '>
              <div className='colored-box font-weight-medium h-100 pb-4'>
                <div className=' py-5 text-center'>
                  <div className='profile-pic-container rounded-circle d-inline-block'>
                    <img
                      className='profile-pic rounded-circle'
                      src={user_profile_picture || ProfileIcon}
                      alt=''
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = ProfileIcon;
                      }}
                    />
                    <UploadBox
                      icon={true}
                      iconImg={AddIcon}
                      accept={['jpeg', 'jpg', 'png']}
                      onChange={(file) => {
                        updateProfileDocument('profile_picture', file);
                      }}
                    />
                  </div>
                  <div className='profiler-name text-capitalize  my-3'>
                    {user_name}
                  </div>
                </div>
                <Nav
                  activeKey='/profile/detail'
                  className='flex-column'
                  onSelect={onLeftNavSelect}
                >
                  <Nav.Link
                    as='div'
                    eventKey='/profile/detail'
                    className={
                      'py-2 my-1 px-5 ' +
                      (eventKey === '/profile/detail' ? 'selected' : '')
                    }
                  >
                    <span className='ml-1'>Profile</span>
                    <img src={ForwardArrowIcon} alt='forward icon' />
                  </Nav.Link>
                  {isMutualFundInScope && (
                    <Nav.Link
                      as='div'
                      eventKey='/profile/bank-list'
                      className={
                        'py-2  my-1 px-5 ' +
                        (eventKey === '/profile/bank-list' ||
                        eventKey === '/profile/add-bank'
                          ? 'selected'
                          : '')
                      }
                    >
                      <span className='ml-1'>Bank Details</span>
                      <img src={ForwardArrowIcon} alt='forward icon' />
                    </Nav.Link>
                  )}

                  {isMutualFundInScope && ready_to_invest && (
                    <Nav.Link
                      as='div'
                      eventKey='/profile/my-sips'
                      className={
                        'py-2  my-1 px-5 ' +
                        (eventKey === '/profile/my-sips' ? 'selected' : '')
                      }
                    >
                      <span className='ml-1'>My SIPs</span>
                      <img src={ForwardArrowIcon} alt='forward icon' />
                    </Nav.Link>
                  )}
                  <Nav.Link
                    as='div'
                    eventKey='/profile/change-password'
                    className={
                      'py-2 my-1 px-5 ' +
                      (eventKey === '/profile/change-password'
                        ? 'selected'
                        : '')
                    }
                  >
                    <span className='ml-1'>Change Password</span>
                    <img src={ForwardArrowIcon} alt='forward icon' />
                  </Nav.Link>
                  <Nav.Link
                    as='div'
                    eventKey='/profile/vkyc-status'
                    className={
                      'py-2 my-1 px-5 ' +
                      (eventKey === '/profile/vkyc-status' ? 'selected' : '')
                    }
                  >
                    <span className='ml-1'>VKYC Status</span>
                    <img src={ForwardArrowIcon} alt='forward icon' />
                  </Nav.Link>
                </Nav>
                <div className={'w-100 mt-5 text-center'}>
                  <button className={'new-ui-btn'} onClick={redirectToHome}>
                    Go to Home
                  </button>
                </div>
              </div>
            </div>
            {
              <div className='col-sm-8  colored-box'>
                {eventKey === '/profile/detail' ? (
                  <div>
                    <ProfileComponent onDocUpdate={updateProfileDocument} />
                  </div>
                ) : null}
                {isMutualFundInScope && eventKey === '/profile/add-bank' ? (
                  <div className='bank-acount-container p-4'>
                    {nrinrenro.includes(tax_status?.id) ? (
                      <NewBankNri
                        addNew
                        show={show}
                        panStatus={tax_status?.id}
                        bankDetail={data?.bankDetail ? data?.bankDetail : null}
                        onBankAdd={() => {
                          history.push('/profile/bank-list');
                        }}
                      />
                    ) : (
                      <EditBankAccount
                        show={show}
                        panStatus={tax_status?.id}
                        bankDetail={data?.bankDetail ? data?.bankDetail : null}
                        onBankAdd={() => {
                          history.push('/profile/bank-list');
                        }}
                      />
                    )}
                  </div>
                ) : null}
                {isMutualFundInScope && eventKey === '/profile/bank-list' ? (
                  <div className='bank-acount-container p-4'>
                    <Fragment>
                      <BankAccounts />
                      {ready_to_invest && (
                        <div className='bank-actions ml-4'>
                          <button
                            type='button'
                            className='btn btn-primary mt-3'
                            onClick={() => {
                              history.push({
                                pathname: '/profile/add-bank',
                                state: {},
                              });
                            }}
                          >
                            Add Bank
                          </button>
                        </div>
                      )}
                    </Fragment>
                  </div>
                ) : null}

                {isMutualFundInScope && eventKey === '/profile/my-sips' ? (
                  <div className='bank-acount-container p-4'>
                    <>
                      <MySips />
                    </>
                  </div>
                ) : null}
                {eventKey === '/profile/change-password' && (
                  <div>
                    <ChangePassword />
                  </div>
                )}
                {eventKey === '/profile/vkyc-status' && (
                  <div>
                    <VkycStatus />
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      ) : (
        <div className='profile-container container'>
          {isMutualFundInScope && <Banner />}

          <div className='row'>
            <div className='col-sm-4 '>
              <div className='colored-box font-weight-medium h-100'>
                <div className=' py-5 text-center'>
                  <div className='profile-pic-container rounded-circle d-inline-block'>
                    <img
                      className='profile-pic rounded-circle'
                      src={user_profile_picture || ProfileIcon}
                      alt=''
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = ProfileIcon;
                      }}
                    />
                    <UploadBox
                      icon={true}
                      iconImg={AddIcon}
                      accept={['jpeg', 'jpg', 'png']}
                      onChange={(file) => {
                        updateProfileDocument('profile_picture', file);
                      }}
                    />
                  </div>
                  <div className='profiler-name text-capitalize  my-3'>
                    {user_name}
                  </div>
                </div>
                <Nav
                  activeKey='/profile/detail'
                  className='flex-column'
                  onSelect={onLeftNavSelect}
                >
                  <Nav.Link
                    as='div'
                    eventKey='/profile/detail'
                    className={
                      'py-2 my-1 px-5 ' +
                      (eventKey === '/profile/detail' ? 'selected' : '')
                    }
                  >
                    <span className='ml-1'>Profile</span>
                    <img src={ForwardArrowIcon} alt='forward icon' />
                  </Nav.Link>
                  {isMutualFundInScope && (
                    <Nav.Link
                      as='div'
                      eventKey='/profile/bank-list'
                      className={
                        'py-2  my-1 px-5 ' +
                        (eventKey === '/profile/bank-list' ||
                        eventKey === '/profile/add-bank'
                          ? 'selected'
                          : '')
                      }
                    >
                      <span className='ml-1'>Bank Details</span>
                      <img src={ForwardArrowIcon} alt='forward icon' />
                    </Nav.Link>
                  )}

                  {isMutualFundInScope && ready_to_invest && (
                    <Nav.Link
                      as='div'
                      eventKey='/profile/my-sips'
                      className={
                        'py-2  my-1 px-5 ' +
                        (eventKey === '/profile/my-sips' ? 'selected' : '')
                      }
                    >
                      <span className='ml-1'>My SIPs</span>
                      <img src={ForwardArrowIcon} alt='forward icon' />
                    </Nav.Link>
                  )}
                  <Nav.Link
                    as='div'
                    eventKey='/profile/change-password'
                    className={
                      'py-2 my-1 px-5 ' +
                      (eventKey === '/profile/change-password'
                        ? 'selected'
                        : '')
                    }
                  >
                    <span className='ml-1'>Change Password</span>
                    <img src={ForwardArrowIcon} alt='forward icon' />
                  </Nav.Link>
                  <Nav.Link
                    as='div'
                    eventKey='/profile/vkyc-status'
                    className={
                      'py-2 my-1 px-5 ' +
                      (eventKey === '/profile/vkyc-status' ? 'selected' : '')
                    }
                  >
                    <span className='ml-1'>VKYC Status</span>
                    <img src={ForwardArrowIcon} alt='forward icon' />
                  </Nav.Link>
                </Nav>
              </div>
            </div>
            <div className='col-sm-8  colored-box'>
              {eventKey === '/profile/detail' ? (
                <div>
                  <ProfileComponent onDocUpdate={updateProfileDocument} />
                </div>
              ) : null}
              {isMutualFundInScope && eventKey === '/profile/add-bank' ? (
                <div className='bank-acount-container p-4'>
                  <EditBankAccount
                    show={show}
                    panStatus={tax_status?.id}
                    bankDetail={data?.bankDetail ? data?.bankDetail : data}
                    onBankAdd={() => {
                      history.push('/profile/bank-list');
                    }}
                  />
                </div>
              ) : null}
              {isMutualFundInScope && eventKey === '/profile/bank-list' ? (
                <div className='bank-acount-container p-4'>
                  <Fragment>
                    <BankAccounts />
                    {ready_to_invest && (
                      <div className='bank-actions ml-4'>
                        <button
                          type='button'
                          className='btn btn-primary mt-3'
                          onClick={() => {
                            history.push({
                              pathname: '/profile/add-bank',
                              state: {},
                            });
                          }}
                        >
                          Add Bank
                        </button>
                      </div>
                    )}
                  </Fragment>
                </div>
              ) : null}

              {isMutualFundInScope && eventKey === '/profile/my-sips' ? (
                <div className='bank-acount-container p-4'>
                  <>
                    <MySips />
                  </>
                </div>
              ) : null}
              {eventKey === '/profile/change-password' ? (
                <div className='bank-acount-container p-4'>
                  <>
                    <ChangePassword />
                  </>
                </div>
              ) : null}
              {eventKey === '/profile/vkyc-status' ? (
                <div className='bank-acount-container p-4'>
                  <>
                    <VkycStatus />
                  </>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Profile;
