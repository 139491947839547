import iZtoast from 'izitoast';

const Toast = {
  error: (message,  onClosed = () => {},title = 'Error') => {
    return iZtoast.error({
      title: title,
      message: message,
      position: 'topRight',
      timeout: 3000,
      onClosed: onClosed,
    });
  },
  warn: (message, title = 'Error', onClosed = () => {}) => {
    return iZtoast.warning({
      title: title,
      message: message,
      position: 'topRight',
      timeout: 3000,
      onClosed: onClosed,
    });
  },
  warnLong: (message, title = 'Error', onClosed = () => {}) => {
    return iZtoast.warning({
      title: title,
      message: message,
      position: 'topRight',
      timeout: 7000,
      onClosed: onClosed,
    });
  },
  success: (message, onClosed = () => {}, title = 'Success') => {
    return iZtoast.success({
      title: title,
      message: message,
      position: 'topRight',
      timeout: 3000,
      onClosed: onClosed,
    });
  },
  destroy: () => {
    iZtoast.destroy();
  },
};

export default Toast;
