import { useEffect, useState } from 'react';
import { fdApi } from '../../api/fdApi';
import classes from './VkycStatus.module.css';
import { useAppSelector } from '../../redux/hooks';
import DataMsg from '../../common/data-msg/DataMsg';

interface VkycStatusInterface {
  issuer_id: string;
  vkyc_status: string;
}
const VkycStatusMoblie = () => {
  const statusLabelMapping: {
    [key in VkycStatusInterface['vkyc_status']]: string;
  } = {
    approved: 'Approved',
    vkyc_under_review: 'Under Review',
    not_started: 'Not Started',
    rejected: 'Rejected',
    pending_vkyc: 'Pending Vkyc',
  };
  const [status, setStatus] = useState<{ label: string; class: string }[]>([]);
  const [issuers, setIssuers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAppSelector((state: any) => state.authReducer);
  useEffect(() => {
    getInvestorStatus();
  }, []);
  const getInvestorStatus = async () => {
    setLoading(true);
    fdApi.investors
      .getInvestorVkycStatus(
        user.userDetailFromElevoBE.id,
        user.userDetailFromElevoBE.email,
        user.userDetailFromElevoBE.mobile
      )
      .then((data) => {
        setIssuers(data.investors.map((investor) => investor.issuer_id));
        setStatus(
          data.investors.map((investor) => {
            return {
              label: statusLabelMapping[investor.vkyc_status],
              class: investor.vkyc_status,
            };
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const table_headers = ['Issuer', 'Status'];

  return (
    <div className='profile-detail-section p-4 d-flex flex-column justify-content-center align-items-center'>
      {issuers && issuers.length > 0 && (
        <div className={`${classes.history_table_wrapper}`}>
          <table className={classes.history__table}>
            <thead>
              {table_headers.map((header) => {
                return (
                  <th className={classes.history__table_header}>{header}</th>
                );
              })}
            </thead>
            <tbody>
              {issuers?.map((record, i) => {
                return (
                  <>
                    <tr className={classes.history__table_row}>
                      <td className={classes.history_table_issuer}>
                        {record.charAt(0).toUpperCase() + record.slice(1)}
                      </td>
                      <td
                        className={`${classes.history__table_status}  ${
                          classes[status[i].class]
                        }`}
                      >
                        {status[i].label}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {!loading && !issuers.length && (
        <DataMsg message='No VKYC status available' />
      )}
    </div>
  );
};

export default VkycStatusMoblie;
