import elevo_apis from './elevo_apis';

function createInvestor(data) {
  return elevo_apis.post('/investors', data);
}
function updateInvestorDetails(id, data) {
  return elevo_apis.put(`/investors/${id}`, data);
}
function saveInvestorDetails(id, data) {
  return elevo_apis.patch(`/investors/${id}`, data);
}
async function getInvestorDetails(id) {
  try {
    const response = await elevo_apis.get(`/investors/${id}`, { skipErrors: true });
    return response; 
  } catch (error) {
    if (error.response?.status === 400 && error.response?.data?.message === "Investor's tax-status not found") {
      return {}; 
    }
    else{
      throw error;
    }
  }
}

export { createInvestor, saveInvestorDetails, updateInvestorDetails, getInvestorDetails };