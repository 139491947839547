import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getFunds } from '../../../../api/fund';
import { decryptData } from '../../../../utility/DecryptHelper';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import OTPInput from '../../../../component/otp-input-component';
import OTPTimer from '../../../../component/otp-component/Timer';
import { sendOTP, verifyOTP, resendOTP } from '../../../../api/otp';
import { switchFund } from '../../../../api/switchFund';
import CurrencyInput from 'react-currency-input-field';

const ToggleButton = ({ isOpen, onClick, disabled }) => (
  <button
    className={`toggle-button ${isOpen ? 'open' : ''}`}
    onClick={onClick}
    disabled={disabled}
    onMouseDown={(e) => {
      // Prevent input from losing focus.
      e.preventDefault();
    }}
  >
    {isOpen ? <span>&#8963;</span> : <span>&#8964;</span>}

    {/*{isOpen ? '&#8964;' : '&#8964;'}*/}
  </button>
);

const SwitchFundForm = ({ selectedFund, portfolioList }) => {
  const history = useHistory();
  const userId = useSelector((state) => state.authReducer.user_id);
  const userData = JSON.parse(localStorage.getItem('lD'));
  const [selectedFolio, setSelectedFolio] = useState();
  const [switchToFunds, setSwitchToFunds] = useState();
  const [switchFromFund, setSwitchFromFund] = useState();
  const [selectedSwitchToFund, setSelectedSwitchToFund] = useState();
  const [type, setType] = useState('amount');
  const [checkAll, setCheckAll] = useState(false);
  const [switchAmountUnit, setSwitchAmountUnit] = useState('');
  const [isOpen, setIsOpen] = useState();
  const [isConfirmOpen, setIsConfirmOpen] = useState();
  const [showTimer, setShowTimer] = useState(false);
  const [proceedBtnState, setProceedBtnState] = useState();
  const [otpId, setOtpId] = useState();
  const [error, setError] = useState({});

  useEffect(() => {
    if (selectedFund) {
      setSelectedFolio(selectedFund.folio_list[0]);
      setSwitchFromFund(selectedFund);
    }
  }, [selectedFund]);

  useEffect(() => {
    if (switchFromFund) {
      getSwitchToFunds();
    }
  }, [switchFromFund]);

  const getFilterString = (filter) => {
    const arrFilter = [];
    filter &&
      Object.keys(filter) &&
      Object.keys(filter)?.length > 0 &&
      Object.keys(filter).forEach((key) => {
        if (key === 'nfo') {
          arrFilter.push(`"${key}":${filter[key]}`);
        } else if (key === 'fund_size') {
          arrFilter.push(`"${key}":${Math.max(...filter[key])}`);
        } else {
          arrFilter.push(`"${key}":[${filter[key]}]`);
        }
      });
    return arrFilter.length ? '{' + arrFilter.join(',') + '}' : null;
  };

  const getSwitchToFunds = (search) => {
    const filter = { amc_ids: [switchFromFund?.amc_id] };
    filter &&
      getFunds(100, 0, search, null, getFilterString(filter)).then(
        (response) => {
          if (response && response.data) {
            const data = decryptData(response.data.data);
            setSwitchToFunds(data.funds);
          }
        }
      );
  };

  const handleSwitchToSelect = (value) => {
    setSelectedSwitchToFund(value);
    error.switchTo = undefined;
    setError(error);
  };

  const handleSwitchFromSelect = (value) => {
    if (value && value[0]) {
      setSwitchFromFund(value[0]);
      setSelectedFolio(value[0].folio_list[0]);
      error.switchFrom = undefined;
      setError(error);
    }
  };

  const handleRadioChange = (event) => {
    setType(event.target.value);
    if (checkAll) {
      if (event.target.value === 'unit') {
        setSwitchAmountUnit(selectedFolio?.units);
      } else {
        setSwitchAmountUnit(selectedFolio?.current_value);
      }
    } else {
      setSwitchAmountUnit('');
    }
  };

  const handleCheckAll = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      if (type === 'unit') {
        setSwitchAmountUnit(selectedFolio?.units);
      } else {
        setSwitchAmountUnit(selectedFolio?.current_value);
      }
    } else {
      setSwitchAmountUnit('');
    }
  };

  const confirmSwitchFund = () => {
    const req = {
      order_type: 'switch',
      investor_id: userId.toString(),
      from_fund_id: switchFromFund.fund_id.toString(),
      to_fund_id: selectedSwitchToFund[0].id.toString(),
      switch_all: checkAll,
      folio: selectedFolio.folio_no,
      authenticator: 'tarrakki',
      auth_ref: otpId,
    };
    if (!checkAll) {
      if (type === 'unit') {
        req.units = switchAmountUnit;
      } else {
        req.amount = parseFloat(switchAmountUnit.replaceAll(',', '')).toFixed(
          2
        );
      }
    }
    switchFund(req)
      .then((response) => {
        if (response.data && response.data.order_id) {
          setOtpId(undefined);
          history.push(`/switch-funds/${response.data.order_id}/status`);
        }
      })
      .catch(() => {
        setIsOpen(false);
      });
  };

  const validateData = () => {
    let isValid = true;
    if (!selectedSwitchToFund || selectedSwitchToFund.length === 0) {
      error.switchTo = 'Please select fund to switch';
      isValid = false;
    }
    if (!checkAll) {
      if (type === 'unit') {
        if (!switchAmountUnit || setSwitchAmountUnit.length === 0) {
          error.amountUnit = 'Please enter Units';
          isValid = false;
        } else if (
          parseFloat(switchAmountUnit) > parseFloat(selectedFolio?.units)
        ) {
          error.amountUnit = 'Value should be less or equal to available Units';
          isValid = false;
        }
      } else {
        if (!switchAmountUnit || setSwitchAmountUnit.length === 0) {
          error.amountUnit = 'Please enter Amount';
          isValid = false;
        } else if (
          parseFloat(switchAmountUnit.toString().replaceAll(',', '')) >
          parseFloat(selectedFolio?.current_value)
        ) {
          error.amountUnit = 'Value should be less or equal to Current Value';
          isValid = false;
        }
      }
    }
    setError({ ...error });
    return isValid;
  };

  const openOTPVerification = () => {
    if (!otpId) {
      setIsConfirmOpen(false);
      handleSendOTP('switch_order');
    } else {
      setIsOpen(true);
      setIsConfirmOpen(false);
    }
  };

  const handleSendOTP = (type) => {
    const switchformOtpData = {
      otp_type: type,
      investor_id: userId.toString(),
      folio: selectedFolio?.folio_no,
      fund_id: selectedSwitchToFund[0]?.id.toString(),
    };
    setProceedBtnState(false);
    sendOTP(switchformOtpData).then((response) => {
      if (response && response.data) {
        const data = response?.data;
        if (data?.otp_id) {
          setOtpId(data?.otp_id);
        }
        setShowTimer(true);
        setIsOpen(true);
      }
    });
  };

  const handleReSendOTP = (otpId, voice) => {
    setProceedBtnState(false);
    resendOTP(otpId, voice).then((response) => {
      if (response && response.data) {
        const data = response.data;
        setOtpId(data?.otp_id);
        setShowTimer(true);
      }
    });
  };

  const otpChange = (event) => {
    let otp = event || '';
    if (otp.length === 6) {
      verifyOtp(otp, otpId);
    }
  };

  const verifyOtp = (otp, otpId) => {
    let verifySwitchformData = {
      otp_type: 'switch_order',
      otp: otp,
    };
    verifyOTP(verifySwitchformData, otpId).then((response) => {
      if (response.status === 201) {
        setProceedBtnState(true);
      } else {
        setProceedBtnState(false);
      }
    });
  };

  const resendOtp = () => {
    handleReSendOTP(otpId, false);
  };

  const resendOtpViaCall = () => {
    handleReSendOTP(otpId, true);
  };

  const handleUnitChange = (event) => {
    setSwitchAmountUnit(event.target.value);
    error.amountUnit = undefined;
    setError({ ...error });
  };

  const handleAmountChange = (value) => {
    setSwitchAmountUnit(value);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const openConfimBox = () => {
    if (validateData()) setIsConfirmOpen(true);
  };

  return (
    <>
      <div className={'p-md-4'}>
        <Form.Group className=''>
          <Form.Label className='mb-1'>Switch From</Form.Label>
          {selectedFund ? (
            <Typeahead
              id={'switch-from'}
              labelKey={(option) => `${option.fund_name}`}
              options={portfolioList || []}
              defaultSelected={selectedFund && [selectedFund]}
              disabled={true}
              selected={selectedFund && [selectedFund]}
              title={selectedFund && [selectedFund]}
              placeholder={'Select Fund to Switch from'}
            >
              {({ isMenuShown, toggleMenu }) => (
                <ToggleButton
                  isOpen={isMenuShown}
                  onClick={(e) => toggleMenu()}
                  disabled
                />
              )}
            </Typeahead>
          ) : (
            <Typeahead
              id={'switch-from'}
              labelKey={(option) => `${option.fund_name}`}
              options={portfolioList || []}
              onChange={handleSwitchFromSelect}
              // defaultInputValue={switchFromFund && [switchFromFund]}
              placeholder={'Select Fund to Switch from'}
              inputProps={{ style: { paddingRight: '40px' } }}
            >
              {({ isMenuShown, toggleMenu }) => (
                <div
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '20%',
                    transform: 'translateY(-40%)',
                    zIndex: 1,
                    height: '100%', 
                    display: 'flex', 
                    alignItems: 'center', 
                  }}
                >
                  <ToggleButton
                    isOpen={isMenuShown}
                    onClick={(e) => toggleMenu()}
                    disabled
                  />
                </div>
              )}
            </Typeahead>
          )}

          <Form.Control.Feedback type='invalid'>error</Form.Control.Feedback>
        </Form.Group>

        <div className='d-flex flex-column'>
          <Form.Group className='w-100'>
            <Form.Label className='mb-1'>Folio No.</Form.Label>
            <select className='form-control w-100 pr-4' >
              {switchFromFund &&
                switchFromFund?.folio_list?.map((item) => (
                  <option key={item.folio_no}>{item.folio_no}</option>
                ))}
            </select>
            <Form.Control.Feedback type='invalid'>error</Form.Control.Feedback>
          </Form.Group>

          <div className='d-flex flex-column flex-md-row'>
            <Form.Group className='w-100 w-md-50 pr-md-2 mb-2 mb-md-0'>
              <Form.Label className='mb-1'>Current Value</Form.Label>
              <Form.Control
                type='text'
                id='folio-current-value'
                value={selectedFolio?.current_value.toLocaleString('en-IN')}
                disabled
              />
              <Form.Control.Feedback type='invalid'>
                error
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='w-100 w-md-50 pl-md-2'>
              <Form.Label className='mb-1'>Units</Form.Label>
              <Form.Control
                type='text'
                id='folio-units'
                value={selectedFolio?.units}
                disabled
              />
              <Form.Control.Feedback type='invalid'>
                error
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>

        <Form.Group className=''>
          <Form.Label className='mb-1'>Switch To</Form.Label>
          <Typeahead
            id={'switch-from'}
            labelKey='name'
            multiple={false}
            options={switchToFunds?.length > 0 ? switchToFunds : []}
            placeholder={'Select Fund to Switch to'}
            isInvalid={error && error.switchTo}
            className={error && error.switchTo ? 'is-invalid' : ''}
            disabled={!switchFromFund}
            onChange={handleSwitchToSelect}
          >
            {({ isMenuShown, toggleMenu }) => (
              <div
              style={{
                position: 'absolute',
                right: '5px',
                top: '20%',
                transform: 'translateY(-40%)',
                zIndex: 1,
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
              }}
            >
              <ToggleButton
                isOpen={isMenuShown}
                onClick={(e) => toggleMenu()}
              />
              </div>
            )}
          </Typeahead>
          {error && error.switchTo && (
            <div className={'invalid-feedback d-block mt-2'}>
              {error.switchTo}
            </div>
          )}
        </Form.Group>
        <div className={'switch-to-note m-0 ml-0'}>
          *Switch TAT (Turn Around Time) will be applicable as per fund
          settlement type. To know more, contact support
        </div>
        <Form.Group
          className='d-flex switch-fund-radio mt-3'
          id={'switch-fund-radio'}
        >
          <Form.Label className='mr-3'>
            How do you want to switch your funds?
          </Form.Label>
          <Form.Check
            name={'switch-type'}
            className={'mr-3'}
            type={'radio'}
            id={`amount`}
            label={`Amount`}
            value={'amount'}
            onChange={handleRadioChange}
            checked={type === 'amount'}
          />
          <Form.Check
            name={'switch-type'}
            type={'radio'}
            id={`unit`}
            label={`Units`}
            value={'unit'}
            onChange={handleRadioChange}
            checked={type === 'unit'}
          />
          <Form.Control.Feedback type='invalid'>error</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className={'m-0 ml-0'}>
          <Form.Check
            name={'switch-all'}
            className={'mr-3'}
            type={'checkbox'}
            id={`all-units`}
            label={`switch all ${type}`}
            onChange={handleCheckAll}
            checked={checkAll}
            disabled={!switchFromFund}
          />
        </Form.Group>
        <Form.Group className=''>
          {type === 'unit' ? (
            <Form.Control
              type={'number'}
              placeHolder={`Enter Units Here`}
              value={switchAmountUnit}
              disabled={checkAll || !switchFromFund}
              onChange={handleUnitChange}
              className={error?.amountUnit ? 'is-invalid' : ''}
            />
          ) : (
            <CurrencyInput
              id='amount-input'
              name='amount'
              className={'form-control'}
              placeholder='Enter Amount Here'
              value={switchAmountUnit || ''}
              decimalsLimit={2}
              allowNegativeValue={false}
              disabled={checkAll || !switchFromFund}
              onValueChange={handleAmountChange}
            />
          )}
          {error?.amountUnit && (
            <Form.Control.Feedback type='invalid'>
              {error?.amountUnit}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <div className={'text-center mt-4 pt-4'}>
          <button
            type='button'
            className='btn btn-primary btn-lg switch-fund-btn'
            onClick={openConfimBox}
          >
            Switch Fund
          </button>
        </div>
        <div className={'switch-to-note mt-4 ml-0 text-center'}>
          Don't see the fund which you want to switch from ?{' '}
          <a href={'mailto:abg@gmail.com'}>
            <u>Contact Support</u>
          </a>
        </div>
      </div>
      <Modal
        show={isOpen}
        size='md'
        aria-labelledby='add-fund-modal'
        onHide={handleClose}
        centered
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton className='pb-0'>
          <h6 className='font-weight-medium m-0'>Verify OTP</h6>
        </Modal.Header>
        <Modal.Body className='pt-0 switch-fund-otp-modal'>
          <div className='p-3'>
            <p id='otp-modal' className='h6 font-weight-bold text-center'>
              Verification Code
            </p>
            <p className='text-center font-weight-light opacity-75 otp-text-light'>
              Please enter the verification code that we have sent on your
              mobile number or email id.
            </p>
            <div className='text-center mt-8 opacity-75'>
              <OTPInput
                autoFocus={true}
                isNumberInput
                length={6}
                className='justify-content-center mt-8 w-25 d-inline-flex'
                inputClassName='form-control otp-input'
                onChangeOTP={otpChange}
              />
              {proceedBtnState ? (
                <p className={'mt-4 font-weight-light text-success otp-text'}>
                  OTP Verified
                </p>
              ) : showTimer ? (
                <p className={'mt-4 font-weight-light text-danger otp-text'}>
                  OTP expires in{' '}
                  <OTPTimer
                    className='enabled text-danger'
                    initialSeconds='60'
                    onComplete={setShowTimer}
                  />{' '}
                  seconds
                </p>
              ) : (
                <p className={'mt-4 font-weight-light text-danger otp-text'}>
                  {' '}
                  OTP expired{' '}
                </p>
              )}
              <p className='otp-text'>
                Didn't receive OTP ? &nbsp;
                <button
                  type='button'
                  className={
                    showTimer ? 'btn link-disabled' : 'btn link-enabled'
                  }
                  style={showTimer ? { pointerEvents: 'none' } : null}
                  onClick={resendOtp}
                >
                  Resend OTP
                </button>
                {userData?.mobile_number && (
                  <>
                    &nbsp; | &nbsp;
                    <button
                      type='button'
                      className={
                        showTimer ? 'btn link-disabled' : 'btn link-enabled'
                      }
                      style={showTimer ? { pointerEvents: 'none' } : null}
                      onClick={resendOtpViaCall}
                    >
                      {' '}
                      Send OTP Via Call
                    </button>
                  </>
                )}
              </p>
              <div className='btn-container text-center px-5 '>
                <Button
                  className='btn btn-primary'
                  onClick={confirmSwitchFund}
                  disabled={!proceedBtnState}
                >
                  Proceed
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isConfirmOpen}
        onHide={() => setIsConfirmOpen(false)}
        size='sm'
        aria-labelledby='add-fund-modal'
        centered
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton />
        <Modal.Body className='pt-3'>
          <div className={'text-center p-3 w-100'}>
            <div className={'confirm-switch-logo m-auto'}>
              <svg
                id='_003-contact-form'
                data-name='003-contact-form'
                xmlns='http://www.w3.org/2000/svg'
                width='40.902'
                height='40.902'
                viewBox='0 0 40.902 40.902'
              >
                <path
                  id='Path_3898'
                  data-name='Path 3898'
                  d='M37.6.281a.959.959,0,0,0-1.356,0L32.351,4.175V.959A.959.959,0,0,0,31.392,0H.959A.959.959,0,0,0,0,.959V39.944a.959.959,0,0,0,.959.959H23.092a.958.958,0,0,0,.678-.281l8.3-8.3a.958.958,0,0,0,.28-.678h0V12.928l8.271-8.271a.959.959,0,0,0,0-1.356ZM21.19,18.406a.472.472,0,0,1,.052.243.959.959,0,0,0,1.19.985.288.288,0,0,1,.193.02L20.1,20.8ZM23,17.767a2.387,2.387,0,0,0-.541-.834q-.039-.039-.079-.076l12.3-12.3,1.665,1.665L24.135,18.432q-.037-.041-.075-.079A2.207,2.207,0,0,0,23,17.767Zm1.05,19.863V32.6h5.027Zm6.383-6.944H23.092a.959.959,0,0,0-.959.959v7.341H1.917V1.917H30.434V6.092L20.01,16.516a.957.957,0,0,0-.194.281h0l-2.522,5.542a.959.959,0,0,0,1.269,1.27l5.542-2.521h0a.954.954,0,0,0,.281-.194l6.047-6.047ZM37.7,4.863,36.04,3.2l.883-.883,1.665,1.665ZM4.37,6.692a.959.959,0,0,1,.959-.959H25.824a.959.959,0,1,1,0,1.917H5.329A.959.959,0,0,1,4.37,6.692Zm0,4.291a.959.959,0,0,1,.959-.959H22.38a.959.959,0,1,1,0,1.917H5.329A.959.959,0,0,1,4.37,10.983Zm0,4.291a.959.959,0,0,1,.959-.959H18.088a.959.959,0,1,1,0,1.917H5.329A.959.959,0,0,1,4.37,15.274Zm0,4.291a.959.959,0,0,1,.959-.959H13.8a.959.959,0,1,1,0,1.917H5.329a.959.959,0,0,1-.959-.959Zm0,4.291a.959.959,0,0,1,.959-.959h9.042a.959.959,0,1,1,0,1.917H5.329a.959.959,0,0,1-.959-.959Zm.959,3.333H27.022a.959.959,0,0,1,0,1.917H5.329a.959.959,0,1,1,0-1.917Z'
                  fill='#f1944c'
                />
              </svg>
            </div>
            <div
              className={'text-left mt-3'}
              style={{ fontSize: '14px', fontWeight: '500', color: '#262E4A' }}
            >
              <ul>
                <li>Capital gains tax may be applied</li>
                <li>
                  Amount/Units may slightly differ depending on the NAV on the
                  day of the Switch
                </li>
              </ul>
            </div>
            <div className={'text-center mt-4 pt-4'}>
              <button
                type='button'
                className='btn btn-primary btn-lg switch-fund-btn'
                onClick={openOTPVerification}
                style={{ fontSize: '14px' }}
              >
                Switch Now
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SwitchFundForm;
