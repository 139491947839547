import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getKycData, getSignzyUrl } from '../../api/kyc';
import { saveInvestorDetails, getInvestorDetails } from '../../api/onboarding.api';
import InfoIcon from '../../assets/info-blue.svg';
import { RadioGroups } from '../../common/form';
import KycVerifiedModal from '../../modal/KycVerifiedModal';
import SuccessModal from '../../modal/SuccessModal';
import {
  removeAuthData,
  updateKycStatus,
} from '../../redux/reducers/auth-reducer';
import { updateSelectedNav } from '../../redux/reducers/header-reducer';
import { updateKycInfo } from '../../redux/reducers/onboarding-reducer';
import { decryptData, encryptData } from '../../utility/DecryptHelper';
import {
  getPanType,
  isEmptyObject,
  redirectUserForAuthentication,
} from '../../utility/utility';
import { KycStatusEnum } from '../../enums';
import {
  INVESTOR_TYPE_OPTIONS,
  TAX_STATUS,
  TAX_STATUS_CODE_TO_INVESTOR_TYPE,
} from '../../utility/constant';
import Toast from '../../common/toast/Toast';
import taxStatus from '../../utility/taxStatus';
import * as Sentry from '@sentry/react';

const PanStatus = ({ history }) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [pageHtmlData, setHtmlData] = useState({});
  const [panData, setPanData] = useState({});
  const [disabledFields, setDisabledFields] = useState(false);
  const [, setKycData] = useState({});
  const [showKycPopup, setKycPopup] = useState({ show: false, message: null });
  const [showSuccessPopup, setSuccessPopup] = useState({
    show: false,
    message: null,
  });
  const [applicableFor, setApplicableFor] = useState();
  const [panInputType, setPanInputType] = useState('text');
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const dispatch = useDispatch();
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onRadioButtonChange = (event) => {
    const { value } = event.target;
    setApplicableFor(value);
    setHtmlData((prevState) => ({
      ...prevState,
      tax_status: {
        ...prevState.tax_status,
        tax_status: TAX_STATUS_CODE_TO_INVESTOR_TYPE[value],
        id: value,
      },
    }));
    dispatch(updateKycInfo({ pan: panData.value, pan_kyc_status: value }));
    localStorage.setItem(
      'panData',
      encryptData({ pan: panData.value, pan_kyc_status: value })
    );
  };

  useEffect(() => {
    if (userId) {
      populateInvestorDetails(userId);
    }
  }, [userId]);

  const populateInvestorDetails = (userId) => {
    getInvestorDetails(userId).then((res) => {
      if (
        res &&
        res.data &&
        res.data.investor_type &&
        (res.data.pan || res.data.guardian_detail.pan)
      ) {
        setPanData({ value: res.data.pan || res.data.guardian_detail.pan });
        const panType = getPanType(
          res.data.pan || res.data.guardian_detail.pan
        );
        const panIdentifier = res.data.pan[3].toUpperCase();
        if (taxStatus[res.data.investor_type] === TAX_STATUS.NRE || taxStatus[res.data.investor_type] === TAX_STATUS.NRO) {
          res.data.investor_type = "nri";
        }
        setHtmlData({
          tax_status: {
            pan_type: panType.label,
            tax_status: res.data.investor_type,
            id: taxStatus[res.data.investor_type],
          },
          ...(Object.keys(INVESTOR_TYPE_OPTIONS).includes(panIdentifier)
            ? { radio_button_options: INVESTOR_TYPE_OPTIONS[panIdentifier] }
            : {}),
        });
        setDisabledFields(true);
      }
    });
  };

  // Step 1
  const panNumberChange = (event) => {
    const panCardRegex = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    let value = event.target.value?.trim();
    if (value !== panData?.value) {
      if (value.length < 5 || value.length > 8 || !value) {
        setPanInputType('text');
      } else {
        setPanInputType('numeric');
      }
      const panData = { value: value?.toUpperCase(), err: '' };
      if (!value && !value.trim()) {
        panData['err'] = 'Pan card cannot be empty';
      } else if (!panCardRegex.test(value)) {
        panData['err'] = 'Invalid Pan Card';
      }
      setPanData(panData);

      if (value && value.length && !panData.err) {
        /**
         * TODO: Check the KYC Status and act accordingly
         * 1. KYC is not completed
         * 2. KYC is completed
         * 3. KYC status is not available
         */
        setKycPopup({ show: true, message: '', pan: panData.value });
        handleKycStatusCheck()
      }
    }
  };

  const handleKycStatusCheck = (status) => {
    if (status === KycStatusEnum.pending) {
      setKycPopup({ show: true, message: '', pan: panData.value });
    } else if (status === KycStatusEnum.registered || status === KycStatusEnum.validated) {
      const panType = getPanType(panData.value);
      const panIdentifier = panData.value[3].toUpperCase();

      setHtmlData({
        tax_status: {
          pan_type: panType.label,
          tax_status: TAX_STATUS_CODE_TO_INVESTOR_TYPE[applicableFor],
          id: applicableFor,
        },
        radio_button_options: Object.keys(INVESTOR_TYPE_OPTIONS).includes(panIdentifier)
          ? INVESTOR_TYPE_OPTIONS[panIdentifier]
          : {},
      });
    }
  };

  const getUserKycData = () => {
    const data = new FormData();
    data.append('pan', panData.value);
    data.append('tax_status', '11');
    getKycData(data).then((res) => {
      if (res && res.data) {
        const data = decryptData(res.data.data);
        if (data && data.data && data.data.length) {
          setKycData(data.data[0]);
        }
      }
    });
  };

  const onSubmit = async () => {
    const onInvestorTypeVerified = () => {
      if (!isEmptyObject(pageHtmlData)) {
        if (
          panData.value &&
          panData.value.length &&
          pageHtmlData &&
          pageHtmlData.tax_status &&
          pageHtmlData.tax_status.id
        ) {
          dispatch(updateKycStatus(true));
          history.push('/onboarding/');
        } else {
          setPanData({ value: panData.value, err: 'Invalid Pan Card' });
        }
      } else {
        // Not expected to reach here
        Sentry.captureException('pageHtmlData object is empty');
        Toast.error('Failed to retrive Investor data!');
        history.push('/home/');
      }
    };

    // Update Pan and Tax Status for investor
    try {
      const res = await getInvestorDetails(userId);

      if (
        res.data &&
        res.data?.investor_type &&
        (res.data?.pan || res.data?.guardian_detail?.pan)
      ) {
        dispatch(
          updateKycInfo({
            pan: res.data?.pan || res.data?.guardian_detail?.pan,
            pan_kyc_status: taxStatus[res.data?.investor_type],
          })
        );
        localStorage.setItem(
          'panData',
          encryptData({
            pan: res.data?.pan || res.data?.guardian_detail?.pan,
            pan_kyc_status: taxStatus[res.data?.investor_type],
          })
        );
        onInvestorTypeVerified();
      } else {
        // Update PAN and Tax Status for investor only if the above condition is false
        const panType = getPanType(panData.value);
        if (panType.code === TAX_STATUS.INDIVIDUAL) {
          await saveInvestorDetails(userId, {
            ...(applicableFor === TAX_STATUS.MINOR
              ? { guardian_detail: { pan: panData.value } }
              : { pan: panData.value }),
            investor_type: TAX_STATUS_CODE_TO_INVESTOR_TYPE[applicableFor],
          });

          dispatch(
            updateKycInfo({
              pan: panData.value,
              pan_kyc_status: TAX_STATUS_CODE_TO_INVESTOR_TYPE[applicableFor],
            })
          );
          localStorage.setItem(
            'panData',
            encryptData({
              pan: panData.value,
              pan_kyc_status: TAX_STATUS_CODE_TO_INVESTOR_TYPE[applicableFor],
            })
          );
        } else {
          let investor_type =
            TAX_STATUS_CODE_TO_INVESTOR_TYPE[pageHtmlData.tax_status.id];
          const panIdentifier = panData.value[3].toUpperCase();
          if (Object.keys(INVESTOR_TYPE_OPTIONS).includes(panIdentifier)) {
            investor_type = TAX_STATUS_CODE_TO_INVESTOR_TYPE[applicableFor];
          }
          await saveInvestorDetails(userId, {
            pan: panData.value,
            investor_type: investor_type,
          });

          dispatch(
            updateKycInfo({
              pan: panData.value,
              pan_kyc_status: investor_type,
            })
          );
          localStorage.setItem(
            'panData',
            encryptData({
              pan: panData.value,
              pan_kyc_status: investor_type,
            })
          );
        }
        Toast.success('PAN Status is updated!', onInvestorTypeVerified);
      }
    } catch (e) {
      Toast.error('Failed to update Investor type!');
    }
  };

  const getInvestorTypeRadioOptions = (objArr) => {
    const tempArr = [];

    objArr.forEach((item) => {
      tempArr.push({ label: item.tax_status, value: item.id });
    });
    return tempArr;
  };
  const redirectToLogin = () => {
    setKycPopup(false);
    localStorage.removeItem('panData');
    localStorage.removeItem('uD');
    dispatch(removeAuthData());
    redirectUserForAuthentication();
  };
  const onPopupClose = () => {
    if (
      pageHtmlData &&
      pageHtmlData.tax_status &&
      pageHtmlData.tax_status.id === '09'
    ) {
      getSignzyUrl(userId)
        .then((res) => {
          const data = decryptData(res.data.data);
          if (data && data.data) {
            window.open(data.data.mobile_auto_login_url, '_blank');
          }
          redirectToLogin();
        })
        .catch(() => {
          redirectToLogin();
        });
    } else {
      if (applicableFor === '11') {
        getUserKycData();
        setTimeout(() => {
          dispatch(updateKycStatus(true));
        }, 500);
      }
      dispatch(updateSelectedNav('home'));
      history.push('/home');
    }
  };
  const onSuccessPopupClose = () => {
    setSuccessPopup({ show: false, message: null });
  };
  return (
    <>
      {windowDimensions.width > 480 ? (
        <div
          className='p-5 col-sm-5 colored-box center'
          style={{ position: 'absolute' }}
        >
          <Form.Group className='my-3'>
            <Form.Label className='opacity-75 mb-1'>
              Enter PAN Number
            </Form.Label>
            <Form.Control
              type='text'
              id='contact-number'
              placeholder=''
              inputMode={panInputType}
              onChange={panNumberChange}
              onFocus={panNumberChange}
              value={panData.value}
              data-cy='panNumber'
              className={
                'updated-input font-weight-medium pl-0 ' +
                (panData.err ? 'is-invalid' : '')
              }
              readOnly = {disabledFields}
            />
            <Form.Control.Feedback type='invalid'>
              {panData.err}
            </Form.Control.Feedback>
          </Form.Group>
          {pageHtmlData?.tax_status ? (
            <Form.Group className='mb-3'>
              <Form.Label className='opacity-75'>
                Select Your Tax Status
              </Form.Label>
              <Form.Control
                className={'updated-input font-weight-medium '}
                value={pageHtmlData.tax_status.pan_type}
                readOnly
              />
            </Form.Group>
          ) : null}
          {pageHtmlData?.radio_button_options &&
          pageHtmlData?.radio_button_options.length ? (
            <div>
              {pageHtmlData?.tax_status?.pan_type === 'Individual' && (
                <div className='mt-3 small d-flex align-items-center'>
                  <span>Please select your investment profile</span>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Popover id='popover-recomandation'>
                        <Popover.Content>
                          <div className='my-2 c-grey small'>
                            Select Invest for yourself, If you are an individual
                            creating your own account
                          </div>
                          <div className='my-2 c-grey small'>
                            Select Invest on behalf of a minor, If you are a
                            guardian who is investing on behalf of a minor
                          </div>
                          <div className='my-2 c-grey small'>
                            Select Invest as a proprietorship firm, If your
                            proprietorship is linked to your individual PAN and
                            you plan to invest using your proprietorship firms
                            bank account.
                          </div>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <img src={InfoIcon} alt='info icon' className='icon ml-2' />
                  </OverlayTrigger>
                </div>
              )}
              <RadioGroups
                id='panStatusRadio'
                selectedVal={applicableFor || pageHtmlData.tax_status.id}
                radioList={getInvestorTypeRadioOptions(pageHtmlData?.radio_button_options)}
                onChange={onRadioButtonChange}
                disable={disabledFields}
              />
            </div>
          ) : null}
          <div className='btn-container text-center my-4'>
            <button
              type='button'
              data-cy='submitButton'
              className='w-100 btn btn-primary big-btn'
              onClick={onSubmit}
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        <div className='bg-light-grey '>
          <h3 className=' text-center pt-4'>PAN Details</h3>
          <div className='mx-4 colored-box'>
            <Form.Group className='my-3 p-4'>
              <Form.Label className='opacity-75 mb-1'>
                Enter PAN Number
              </Form.Label>
              <Form.Control
                type='text'
                inputMode={panInputType}
                id='contact-number'
                placeholder=''
                onChange={panNumberChange}
                value={panData.value}
                data-cy='panNumber'
                className={
                  'updated-input font-weight-medium pl-0 ' +
                  (panData.err ? 'is-invalid' : '')
                }
              />
              <Form.Control.Feedback type='invalid'>
                {panData.err}
              </Form.Control.Feedback>
            </Form.Group>
            {pageHtmlData.tax_status ? (
              <Form.Group className='mb-3 px-4'>
                <Form.Label className='opacity-75'>
                  Select Your Tax Status
                </Form.Label>
                <Form.Control
                  className={'updated-input font-weight-medium '}
                  value={pageHtmlData.tax_status.tax_status}
                  readOnly
                />
              </Form.Group>
            ) : null}
            {pageHtmlData.radio_button_options &&
            pageHtmlData.radio_button_options.length ? (
              <div className='px-4'>
                {pageHtmlData.tax_status.id === '01' && (
                  <div className='mt-3 medium d-flex align-items-center '>
                    <span className='ml-0'>
                      Please select your investment profile
                    </span>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Popover id='popover-recomandation'>
                          <Popover.Content>
                            <div className='my-2 c-grey small'>
                              Select Invest for yourself, If you are an
                              individual creating your own account
                            </div>
                            <div className='my-2 c-grey small'>
                              Select Invest on behalf of a minor, If you are a
                              guardian who is investing on behalf of a minor
                            </div>
                            <div className='my-2 c-grey small'>
                              Select Invest as a proprietorship firm, If your
                              proprietorship is linked to your individual PAN
                              and you plan to invest using your proprietorship
                              firms bank account.
                            </div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <img
                        src={InfoIcon}
                        alt='info icon'
                        className='icon ml-2'
                      />
                    </OverlayTrigger>
                  </div>
                )}
                <RadioGroups
                  id='panStatusRadio'
                  selectedVal={applicableFor || pageHtmlData.tax_status.id}
                  radioList={getInvestorTypeRadioOptions(pageHtmlData.radio_button_options)}
                  onChange={onRadioButtonChange}
                />
              </div>
            ) : null}
            <div
              className={`text-center my-4 p-4 ${
                pageHtmlData.tax_status ? 'bottom-30' : 'bottom-60'
              }`}
            >
              <button
                type='button'
                data-cy='submitButton'
                className='w-100 btn btn-primary big-btn '
                onClick={onSubmit}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {showKycPopup.show ? (
        <KycVerifiedModal
          isNri={showKycPopup.isNri}
          show={showKycPopup.show}
          message={showKycPopup.message}
          header={showKycPopup.header}
          onSubmit={onPopupClose}
          pan={showKycPopup.pan}
          kycStatusCheck={handleKycStatusCheck}
        />
      ) : null}
      {showSuccessPopup.show ? (
        <SuccessModal
          successText={showSuccessPopup.message}
          show={showSuccessPopup.show}
          onSubmit={onSuccessPopupClose}
        />
      ) : null}
    </>
  );
};
PanStatus.propTypes = {
  history: PropTypes.object,
};
export default PanStatus;
