import React, { useEffect, useRef, useState } from 'react';
import { addNominee, updateNominee } from '../../../api/nominee';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputBox, RadioGroups, SelectBox } from '../../../common/form';
import { DocumentUpload } from '../../../common/on-boarding';
import Toast from '../../../common/toast/Toast';
import EditBankAccount from '../../../component/bank-accounts/EditBankAccount';
import NominationField from '../../../component/nomination-field';
import { Form } from 'react-bootstrap';
import { placeofbirthRegex } from '../../../utility/constant';
import {
  getInvestorDetails,
  saveInvestorDetails,
} from '../../../api/onboarding.api';

import { getInvestorBankDetails } from '../../../api/bank';
import {
  incomeSource,
  incomeSlab,
  genderList,
  occupationNewList,
  handleInputs
} from '../../../utility/utility';
import {
  getInvestorDocuments,
  postInvestorDocuments,
} from '../../../api/mfApi/documents';

/**
 * When User is onboarded then investor id will be generated, so for any further process Use PATCH/PUT for investors
 *
 * TODO: For save as draft/submit check if the investor is created or not, then only do the patch/put action
 */
const IndividualOnboarding = ({ kycData, setKycData }) => {
  const history = useHistory();
  const {
    user_id: userId,
    email,
    mobile_number,
  } = useSelector((state) => state.authReducer);
  const { pan, pan_kyc_status } = useSelector(
    (state) => state.onboardingReducer
  );

  // Component Level States
  const [annualIncome, setAnnualIncome] = useState();
  const [placeOfBirth, setPlace] = useState();
  const [wealthSource, setWealthSource] = useState();
  const [birthCountry, setBirthCountry] = useState('India');
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState();
  const [checked, setChecked] = useState(false);
  const [notPoliticallyExposedCheck, setNotPoliticallyExposedCheck] =
    useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [politicallyExposedError, setPoliticallyExposedError] = useState();
  const [nomineeDetails, setNomineeDetails] = useState();
  const [nomineeErrors, setNomineeErrors] = useState([]);
  const [commonNomineeError, setCommonNomineeError] = useState();
  const [fatcaSubmited, setFatcaSubmited] = useState(false);
  const [nomineeSubmited, setNomineeSubmited] = useState(false);
  const [bankUpdated, setBankUpdated] = useState(false);
  const [errors, setErrors] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cityName, setCityName] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [stateName, setStateName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [DOB, setDOB] = useState('');
  const [gender, setGender] = useState('');
  const [occupation, setOccupation] = useState('');
  const [investorType, setInvestorType] = useState(undefined);
  const [bankDetails, setBankDetails] = useState();
  // TODO: Remove this id and sturcture is not complitable with new API
  const [documentsList, setDocumentsList] = useState([
    {
      document_type: 'signature',
      id: '06',
      is_multiple_upload: false,
      name: 'Upload your signature',
      is_uploaded: false,
    },
  ]);

  const childRef = useRef();
  const childRefDoc = useRef();

  useEffect(() => {
    if (userId) {
      populateInvestorDetails(userId);
      populateInvestorBankDetails(userId);
      populateInvestorDocuments(userId);
    }
  }, [userId]);

  const populateInvestorBankDetails = (id) => {
    // Fetch investor's bank details by investor ID (used when the form is marked as 'Save as Draft')
    getInvestorBankDetails(id).then((res) => {
      if (res.data && res.data.length !== 1) return;

      const bankDetail = {
        id: res.data[0].bank_id,
        account_type_bse: res.data[0].account_type,
        account_number: res.data[0].account_number,
        branch__ifsc_code: res.data[0].ifsc,
        verification_document: res.data[0].verification_document,
      };

      setBankDetails(bankDetail);
    });
  };

  const populateInvestorDetails = (id) => {
    // Fetch investor details by ID (used when the form is marked as 'Save as Draft')
    getInvestorDetails(id).then((res) => {
      const fatca_detail = res.data?.fatca_detail;
      const address_details = res.data?.address;

      setInvestorType(res?.data?.investor_type)

      setFirstName(res?.data?.first_name);
      setLastName(res?.data?.last_name);

      setAddressLine1(address_details?.address_line_1);
      setAddressLine2(address_details?.address_line_2);
      setAddressLine3(address_details?.address_line_3);
      setPinCode(address_details?.pincode);
      setCityName(address_details?.city);
      setStateName(address_details?.state);
      setCountryName(address_details?.country);

      setDOB(res?.data?.dob);

      setGender(res?.data?.gender);

      setOccupation(fatca_detail?.occupation);
      setAnnualIncome(fatca_detail?.income_slab);
      setPlace(fatca_detail?.birth_place);
      setWealthSource(fatca_detail?.income_source);
      setBirthCountry(fatca_detail?.birth_country);
      setfatcaData(res.data);
    });
  };

  const populateInvestorDocuments = (id) => {
    getInvestorDocuments(id).then((res) => {
      if (res.data.length === 0) return;
      const newState = [];
      for (const doc of res.data) {
        for (const docState of documentsList) {
          if (docState.document_type === doc.document_type) {
            newState.push({
              ...docState,
              is_uploaded:
                docState.document_type === doc.document_type ? true : false,
            });
          }
        }
      }
      if (newState.length >= 0) {
        setDocumentsList(newState);
      }
    });
  };

  const handleSingleDocUpload = (type, document) => {
    const payload = new FormData();
    payload.append('document_type', 'signature');
    payload.append('file', document);
    postInvestorDocuments(userId, payload)
      .then((res) => {
        setDocumentsList((prev) => {
          const newState = [];
          for (const i in prev) {
            newState.push({ ...i, is_uploaded: true });
          }
          return newState;
        });
        Toast.success('Document uploaded successfully!');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const setfatcaData = (data) => {
    // TODO: How to retrieve PEP declaration
    setNotPoliticallyExposedCheck(!data?.is_entity_politically_exposed_person);
    if (data.mobile && data.email_declaration && data.mobile_declaration) {
      setChecked(true);
      setMobileDeclaration(true);
      setEmailDeclaration(true);
    }
    if (!data.mobile && data.email_declaration) {
      setChecked(true);
      setEmailDeclaration(true);
    }
  };

  const getConsentText = (mobile, email, firstName, lastName) => {
    const investorName =
      !firstName || !lastName ? 'me' : `${firstName} ${lastName}`;
    if (!mobile) {
      return `I confirm that email ID ${email} belong to ${investorName}.`;
    }
    return `I confirm that mobile number ${mobile} and email ID ${email} belong to ${investorName}.`;
  };

  const multiDocUpload = () => {
    // getUserKycData();
  };

  const onBankSubmitted = () => {
    setBankUpdated(true);
  };

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const onAddressChangeLine1 = (event) => {
    setAddressLine1(event.target.value);
  };
  const onAddressChangeLine2 = (event) => {
    setAddressLine2(event.target.value);
  };
  const onAddressChangeLine3 = (event) => {
    setAddressLine3(event.target.value);
  };
  const onCountryChange = (event) => {
    setCountryName(event.target.value);
  };
  const onCityPlaceChange = (event) => {
    setCityName(event.target.value);
  };
  const onPinCodeChange = (event) => {
    setPinCode(event.target.value);
  };
  const onStateNameChange = (event) => {
    setStateName(event.target.value);
  };
  const onDOBChange = (event) => {
    setDOB(event.target.value);
  };

  const handleGenderChange = (value) => {
    setGender(value);
    errors['gender'] = '';
    setErrors(errors);
  };

  const onBirthPlaceChange = (event) => {
    let inputValue = event.target.value || '',
      placeErr = '';
    if (!inputValue) {
      setPlace('');
    } else if (!placeofbirthRegex.test(inputValue)) {
      placeErr =
        'Please enter a Place of birth without spaces or characters like("-", "&", "@", etc)';
    } else {
      placeErr = '';
      setPlace(inputValue);
    }
    setPlaceOfBirthErr(placeErr);
  };

  const saveFormData = () => {
    if (validateNomineeDetails()) {
      const formData = new FormData();
      formData.append('income_slab', annualIncome);
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      if (wealthSource !== undefined || !wealthSource) {
        formData.append('wealth_src', wealthSource);
      }
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append(
        'is_entity_politically_exposed_person',
        !notPoliticallyExposedCheck
      );

      const req = {
        investor_type: investorType,
        first_name: firstName,
        last_name: lastName,
        pan: pan,
        // email: email,
        // mobile: mobile_number,
        email_declaration: emailDeclaration ? 'self' : undefined,
        mobile_declaration: mobileDeclaration ? 'self' : undefined,
        dob: DOB,
        gender: gender,
        fatca_detail: {
          occupation: occupation,
          income_source: wealthSource,
          income_slab: annualIncome,
          birth_place: placeOfBirth,
          birth_country: birthCountry,
        },
        address: {
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          address_line_3: addressLine3,
          city: cityName,
          state: stateName,
          country: countryName,
          pincode: pinCode,
        },
      };
      saveInvestorDetails(userId, req)
        .then((response) => {
          history.push('/home');
        })
        .catch((error) => {
          console.error(error);
        });

      onSubmit();
      saveNomineeDetails();
    }
  };
  const onSubmit = () => {
    if (bankDetails?.id) {
      childRef.current.updateNewBank();
    } else {
      childRef.current.addNewBank();
    }
  };

  const submitFormData = () => {
    let isNomineeValid = validateNomineeDetails(true);
    if (validateData() && isNomineeValid) {
      const req = {
        investor_type: investorType,
        first_name: firstName,
        last_name: lastName,
        pan: pan,
        // email: email,
        // mobile: mobile_number,
        email_declaration: emailDeclaration ? 'self' : '',
        mobile_declaration: mobileDeclaration ? 'self' : '',
        dob: DOB,
        gender: gender,
        fatca_detail: {
          occupation: occupation,
          income_source: wealthSource,
          income_slab: annualIncome,
          birth_place: placeOfBirth,
          birth_country: birthCountry,
        },
        address: {
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          address_line_3: addressLine3,
          city: cityName,
          state: stateName,
          country: countryName,
          pincode: pinCode,
        },
      };
      const formData = new FormData();
      formData.append('income_slab', annualIncome);
      formData.append('place_of_birth', placeOfBirth);
      formData.append('country_of_birth', birthCountry);
      formData.append('wealth_src', wealthSource);
      formData.append('is_submit', true);
      formData.append('mobile_declaration', mobileDeclaration);
      formData.append('email_declaration', emailDeclaration);
      formData.append(
        'is_entity_politically_exposed_person',
        !notPoliticallyExposedCheck
      );
      if (!notPoliticallyExposedCheck) {
        setPoliticallyExposedError(
          'Please confirm you are not politically exposed'
        );
      }
      if (!emailDeclaration) {
        setDeclarationError(
          `Please confirm your Email ${
            mobile_number ? 'and Mobile ' : ''
          }Declaration.`
        );
      }
      if (emailDeclaration && notPoliticallyExposedCheck) {
        saveInvestorDetails(userId, req)
          .then((response) => {
            setFatcaSubmited(true);
          })
          .catch((error) => {
            console.error(error);
          });
        onSubmit();
        submitNomineeDetails();
      }
    }
  };

  useEffect(() => {
    if (fatcaSubmited && bankUpdated) {
      history.push('/home');
    }
  }, [fatcaSubmited, bankUpdated]);

  const validateData = () => {
    let errors = [];

    if (!wealthSource || wealthSource.length === 0) {
      errors['wealthSource'] = 'Wealth Source is required.';
    }
    if (!birthCountry || birthCountry.length === 0) {
      errors['birthCountry'] = 'Country of Birth is required.';
    }
    if (!placeOfBirth || placeOfBirth.length === 0) {
      setPlaceOfBirthErr('Place of Birth is required.');
    }
    setErrors(errors);
    let status = handleErrorsBank();
    if ((errors && Object.keys(errors).length > 0) || !status) {
      return false;
    }
    return true;
  };
  const handleErrorsBank = () => {
    let status = childRef.current.validate();
    let status1 = childRefDoc.current.validateDoc();
    if (status && status1) {
      return true;
    }
    return false;
  };

  const validateNomineeDetails = (isSubmit) => {
    let isValid = true;

    nomineeDetails.forEach((item, index) => {
      nomineeErrors[index] = {};

      if (item.name) {
        if (!item.relation) {
          isValid = false;
          nomineeErrors[index].relation = 'Relation is required';
        }
        if (!item.share) {
          isValid = false;
          nomineeErrors[index].share = 'Share is required';
        }
      }
    });

    setNomineeErrors([...nomineeErrors]);

    const nomineeTotal = getNomineeTotal();

    if (nomineeTotal > 100) {
      setCommonNomineeError(
        'Total of share for all nominees should not exceed 100%'
      );
      isValid = false;
    } else if (isSubmit && nomineeTotal < 100) {
      setCommonNomineeError(
        'Total of share for all nominees should be exactly 100%'
      );
      isValid = false;
    } else {
      setCommonNomineeError('');
    }

    return isValid;
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (mobile_number) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
  };

  const handleOccupationChange = (value) => {
    setOccupation(value);
    errors['occupation'] = '';
    setErrors(errors);
  };

  const handleWealthSourceChange = (value) => {
    setWealthSource(value);
    errors['wealthSource'] = '';
    setErrors(errors);
  };

  const politicallyExposedChange = (event) => {
    const { checked } = event.target;
    setNotPoliticallyExposedCheck(checked);
    if (checked) {
      setPoliticallyExposedError(undefined);
    }
  };

  const handleNomineeDetailChange = (data) => {
    setNomineeDetails([...data]);
    nomineeErrors.forEach((err, index) => {
      if (err && err.relation) {
        if (data[index].relation) {
          err.relation = undefined;
        }
      }
      if (err && err.share) {
        if (data[index].share) {
          err.share = undefined;
        }
      }
    });
    setCommonNomineeError(undefined);
    setNomineeErrors([...nomineeErrors]);
  };

  const submitNomineeDetails = () => {
    saveNomineeDetails();
  };

  const getNomineeTotal = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    return shareCount;
  };

  const saveNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    if (shareCount > 100) {
      setCommonNomineeError(
        'Total of share for all nominee should be not be greater than 100%'
      );
    } else {
      const nomineesToAdded = [];

      // Update Nominee Details
      for (let i in nomineeDetails) {
        if (nomineeDetails[i].id) {
          updateNomineeDetails(nomineeDetails[i]);
        } else {
          nomineesToAdded.push(nomineeDetails[i]);
        }
      }

      // Add Nominee Details
      if (nomineesToAdded?.length > 0) {
        addNomineeDetails(nomineesToAdded);
      }
    }
  };

  const updateNomineeDetails = (data) => {
    if (data?.name) {
      let request = {
        name: data.name,
        relation: data.relation,
        share: parseInt(data.share),
        minor: false,
      };
      updateNominee(userId, data.id, request).then((response) => {
        setNomineeSubmited(true);
      });
    } else {
      setNomineeSubmited(true);
    }
  };

  const addNomineeDetails = (nominees) => {
    /**
     * @param nominees - [{name: string, relation: string, share: number, minor: boolean}]
     * @returns void
     */
    if (nominees?.length > 0) {
      const payload = {
        nominees: nominees.map((nominee) => {
          return {
            name: nominee.name,
            relation: nominee.relation,
            share: parseInt(nominee.share),
            minor: false,
          };
        }),
      };
      addNominee(userId, payload).then(() => {
        setNomineeSubmited(true);
      });
    } else {
      // Not expected to reach here...
      Toast.error('Failed to submit nominee details! Please try again!');
      setNomineeSubmited(false);
    }
  };

  const onRadioChange = (event) => {
    const { value } = event.target;
    setAnnualIncome(value);
  };

  return (
    <div>
      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-first-name'
            title={'First Name'}
            value={firstName}
            onChange={onFirstNameChange}
            onBlur={(e) => setFirstName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-last-name'
            title={'Last Name'}
            value={lastName}
            onChange={onLastNameChange}
            onBlur={(e) => setLastName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-address-line1'
            title={'Address Line 1'}
            value={addressLine1}
            onChange={onAddressChangeLine1}
            onBlur={(e) => setAddressLine1(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-address-line2'
            title={'Address Line 2'}
            value={addressLine2}
            onChange={onAddressChangeLine2}
            onBlur={(e) => setAddressLine2(handleInputs(e.target.value))}
            type='text'
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-address-line3'
            title={'Address Line 3'}
            value={addressLine3}
            onChange={onAddressChangeLine3}
            onBlur={(e) => setAddressLine3(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='Pincode'
            onChange={onPinCodeChange}
            title={'Pincode'}
            value={pinCode}
            inputMode={'numeric'}
            type='number'
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className='col-sm-4 mt-3'>
          <InputBox
            id='City'
            onChange={onCityPlaceChange}
            onBlur={(e) => setCityName(handleInputs(e.target.value))}
            title='City'
            value={cityName}
            type='text'
          />
        </div>
        <div className='col-sm-4 mt-3'>
          <InputBox
            title='State'
            value={stateName}
            id='State'
            onChange={onStateNameChange}
            onBlur={(e) => setStateName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-4 mt-3'>
          <InputBox
            id='Country'
            title={'Country'}
            value={countryName}
            onChange={onCountryChange}
            onBlur={(e) => setCountryName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          {/* TODO : CHANGE FORMAT TO YYYY/MM/DD */}
          <InputBox
            id='DOB'
            title={'Date Of Birth'}
            value={DOB}
            onChange={onDOBChange}
            type='date'
          />
        </div>
        <div className='col-sm-6'>
          <SelectBox
            id='Gender'
            title={'Gender'}
            value={gender}
            classes={{ root: 'rounded-none' }}
            onUpdate={handleGenderChange}
            options={genderList}
            placeholder='Select gender'
            err={errors && errors['gender']}
          />
        </div>
      </div>

      <div className='col-sm-12 mt-3'>
        <h6 className='font-weight-bold my-4'>Documents</h6>
        <DocumentUpload
          kycData={kycData}
          documentList={documentsList}
          panStatus={pan_kyc_status}
          singleDocUpload={handleSingleDocUpload}
          multiDocUpload={multiDocUpload}
          ref={childRefDoc}
        />
      </div>
      <section className='col-sm-12'>
        <h6 className='font-weight-bold my-4'>Bank Details</h6>
        <div className='border p-4 rounded'>
          {
            <EditBankAccount
              alignment='center'
              ref={childRef}
              buttonText='Submit'
              panStatus={pan_kyc_status}
              bankDetail={bankDetails}
              onBankSubmitted={onBankSubmitted}
              onboarding={true}
            />
          }
        </div>
      </section>
      <section className='col-sm-12'>
        <h6 className='font-weight-bold mt-4'>Details for FATCA</h6>
        <>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title='Occupation'
              value={occupation}
              id='Occupation'
              classes={{ root: 'col-sm-3 px-0 ' }}
              onUpdate={handleOccupationChange}
              options={occupationNewList}
              placeholder='Select occupation'
              err={errors && errors['occupation']}
            />
          </div>

          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title='Wealth Source'
              value={wealthSource}
              id='WealthSource'
              classes={{ root: 'col-sm-3 px-0 ' }}
              onUpdate={handleWealthSourceChange}
              options={incomeSource}
              placeholder='Select wealth source'
              err={errors && errors['wealthSource']}
            />
          </div>
          {/*<AnnualIncome value={annualIncome} onChange={setAnnualIncome} />*/}
          <div className='border p-4 rounded w-100 my-3'>
            <RadioGroups
              title='Annual Income'
              id='annualIncome'
              radioList={incomeSlab}
              selectedVal={annualIncome}
              onChange={onRadioChange}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <InputBox
              id='PlaceofBirth'
              className='col-sm-5'
              labelClass='opacity-75 h6'
              onChange={onBirthPlaceChange}
              title='Place of Birth'
              value={placeOfBirth}
              type='text'
              maxLength={40}
              err={placeOfBirthErr}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <NominationField
              nomineeDetailChange={handleNomineeDetailChange}
              errors={nomineeErrors}
              commonError={commonNomineeError}
              nomineeSubmited={nomineeSubmited}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <SelectBox
              title='Country of Birth'
              value={birthCountry}
              id='countryBirth'
              classes={{ root: 'col-sm-3 px-0' }}
              onUpdate={setBirthCountry}
              options={[{ label: 'India', value: 'India' }]}
              placeholder='Select country of birth'
              err={errors && errors['birthCountry']}
            />
          </div>
          <div className='border p-4 rounded w-100 my-3'>
            <Form.Check>
              <Form.Check.Input
                id='declaration'
                type='checkbox'
                checked={checked}
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                htmlFor='declaration'
                onChange={checkboxHandleChange}
                style={{ cursor: 'pointer' }}
              >
                {getConsentText(mobile_number, email, firstName, lastName)}
              </Form.Check.Label>
            </Form.Check>
            {declarationError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {declarationError}
              </div>
            )}
            <Form.Check className={'mt-3'}>
              <Form.Check.Input
                id='politicallyExposed'
                type='checkbox'
                checked={notPoliticallyExposedCheck}
                onChange={politicallyExposedChange}
                style={{ cursor: 'pointer' }}
              />
              <Form.Check.Label
                htmlFor='politicallyExposed'
                onChange={politicallyExposedChange}
                style={{ cursor: 'pointer' }}
              >
                {'I confirm I am not politically exposed'}
              </Form.Check.Label>
            </Form.Check>
            {politicallyExposedError && (
              <div className={'invalid-feedback d-block mt-2'}>
                {politicallyExposedError}
              </div>
            )}
          </div>
          <div className=' btn-container text-center my-4  col-sm-12'>
            <button
              type='button'
              className='btn btn-outline-primary m-2'
              onClick={saveFormData}
            >
              Save As Draft
            </button>
            <button
              type='button'
              className='btn btn-primary m-2 btn-width'
              onClick={submitFormData}
            >
              Submit
            </button>
          </div>
        </>
      </section>
    </div>
  );
};

export default IndividualOnboarding;
