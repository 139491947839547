export const resultLimit = 20;
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
export const mobileRegex = /^([6789]\d{9})$/;
export const placeofbirthRegex = /^[a-zA-Z0-9]+$/;
export const nomineeNameRegex = /^[A-Za-z\s]+$/;
export const entityNameRegex = /^[A-Za-z\s]+$/;
export const otherRelationRegex = /^[A-Za-z\s]+$/;
export const giinNumberRegex = /^[A-Za-z0-9]{19}$/;
export const annexureARegex = /^[A-N]$/;
export const annexureBRegex = /^(0+[1-7]|NA|N)$/;
export const nriaddressRegex = /^[,-.\w\s]+$/;
export const cityPlaceRegex = /^[a-zA-Z\s]+$/;
export const pincodeRegex = /\[D]$/;
export const stateNameRegex = /^[a-zA-Z.\s]+$/;
export const natureOfBusinessRegex = /^[A-Za-z]+$/;
export const redeemAmountRegex = /^(\d+\.?\d*)?$/;
export const UNAUTHORIZED_USER_ERR_MSG =
  'Session Expired, Please Sign in to continue.';

  export const API_ERROR_MESSAGES = {
    UNAUTHORIZED_USER_ERR_MSG: UNAUTHORIZED_USER_ERR_MSG,
    CLIENT_ERROR:
      'An error occurred with your request. Please check your input and try again or reach out to our customer support team',
    SERVER_ERROR:
      'Something went wrong. Please contact our customer support team.',
  };

export const TAX_STATUS = {
  LLP: '47',
  PARTNERSHIP: '06',
  PROPRIETORSHIP: '13',
  PRIVATE_LIMITED: '52',
  LIMITED: '51',
  HUF: '03',
  NRI: '11',
  NRE: '21',
  NRO: '24',
  TRUST: '08',
  MINOR: '02',
  INDIVIDUAL: '01',
  BANK: '75',
};

export const INVESTOR_TYPE_OPTIONS = {
  'P': [
    {
      id: TAX_STATUS.INDIVIDUAL,
      tax_status: 'Invest for myself',
    },
    {
      id: TAX_STATUS.NRI,
      tax_status: 'Invest for myself - NRI',
    },
    {
      id: TAX_STATUS.MINOR,
      tax_status: 'Invest for Minor',
    },
    {
      id: TAX_STATUS.PROPRIETORSHIP,
      tax_status: 'Invest for Proprietorship',
    },
  ],
  'C': [
    {
      id: TAX_STATUS.PRIVATE_LIMITED,
      tax_status: 'Invest for Private Limited Company',
    },
    {
      id: TAX_STATUS.LIMITED,
      tax_status: 'Invest for Limited Company',
    },
  ],
  'F': [
    {
      id: TAX_STATUS.PARTNERSHIP,
      tax_status: 'Invest for Partnership Firm',
    },
    {
      id: TAX_STATUS.LLP,
      tax_status: 'Invest for LLP Firm',
    },
  ],
  'H': [
    {
      id: TAX_STATUS.HUF,
      tax_status: 'Invest for HUF',
    }
  ],
  'T': [
    {
      id: TAX_STATUS.TRUST,
      tax_status: 'Invest for Trust',
    }
  ]
}

// TODO: Reverse map every tax status code
export const TAX_STATUS_CODE_TO_INVESTOR_TYPE = {
  '01': 'individual',
  '11': 'nri',
  '02': 'minor',
  '13': 'proprietor',
  '52': 'private_limited',
  '51': 'limited',
  '03': 'huf',
  '08': 'trust' ,
  '21': 'nre' ,
  '24': 'nro' ,
  '75': 'bank_cooperative_bank' ,
  '47': 'llp',
  '06': 'partnership'
};