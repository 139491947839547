import elevo_apis from '../elevo_apis';

export const postInvestorDocuments = (investorId: string, payload: any) => {
  return elevo_apis.post(`/investors/${investorId}/documents`, payload);
};

// DOC: https://docs.tarrakki.com/#tag/investor/operation/getInvestorDocuments
export const getInvestorDocuments = (investorId: string) => {
  return elevo_apis.get(`/investors/${investorId}/documents`);
};
