import React, { useEffect, useRef, useState } from 'react';
import { InputBox, RadioGroups, SelectBox } from '../../../common/form';
import { useSelector } from 'react-redux';
import { DocumentUpload } from '../../../common/on-boarding';
import { getInvestorDocuments, postInvestorDocuments } from '../../../api/mfApi/documents';
import Toast from '../../../common/toast/Toast';
import EditBankAccount from '../../../component/bank-accounts/EditBankAccount';
import { occupationNewList, incomeSource, genderList, incomeSlab, relationList, handleInputs } from '../../../utility/utility';
import { placeofbirthRegex } from '../../../utility/constant';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getInvestorDetails, saveInvestorDetails } from '../../../api/onboarding.api';
import { getInvestorBankDetails } from '../../../api/bank';


const MinorOnboarding = ({ kycData, setKycData }) => {
  const history = useHistory();
  const { pan, pan_kyc_status } = useSelector((state) => state.onboardingReducer);
  const { user_id: userId, email, mobile_number } = useSelector((state) => state.authReducer);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [minorFirstName, setMinorFirstName] = useState('');
  const [minorLastName, setMinorLastName] = useState('');
  const [dobMinor, setDobMinor] = useState();
  const [dobGuardian, setDobGuardian] = useState('');
  const [bankUpdated, setBankUpdated] = useState(false);
  const [fatcaUpdated, setFatcaUpdated] = useState(false);
  const [errors, setErrors] = useState({});
  const [placeOfBirth, setPlace] = useState('');
  const [cityName, setCityName] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [stateName, setStateName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [annualIncome, setAnnualIncome] = useState();
  const [wealthSource, setWealthSource] = useState();
  const [birthCountry, setBirthCountry] = useState('India');
  const [placeOfBirthErr, setPlaceOfBirthErr] = useState();
  const [checked, setChecked] = useState(false);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [gender, setGender] = useState('');
  const [notPoliticallyExposedCheck, setNotPoliticallyExposedCheck] = useState(false);
  const [politicallyExposedError, setPoliticallyExposedError] = useState();
  const [occupation, setOccupation] = useState('');
  const [relation, setRelation] = useState('');
  const [bankDetails, setBankDetails] = useState();
  const [investorType, setInvestorType] = useState(undefined);
  const [documentsList, setDocumentsList] = useState([
    {
      document_type: 'signature',
      id: '06',
      is_multiple_upload: false,
      name: 'Upload your signature',
      is_uploaded: false,
    },
    {
      id: '07',
      document_type: 'birth_certificate',
      is_multiple_upload: false,
      name: `Upload minor's birth certificate`,
      is_uploaded: false,
    },
  ]);

  const childRef = useRef();
  const childRefDoc = useRef();

  useEffect(() => {
    if (userId) {
      populateInvestorDetails(userId);
      populateInvestorBankDetails(userId);
      populateInvestorDocuments(userId);
    }
  }, [userId]);

  const multiDocUpload = () => {
    // getUserKycData();
  };

  const populateInvestorBankDetails = (id) => {
    // Fetch investor's bank details by investor ID (used when the form is marked as 'Save as Draft')
    getInvestorBankDetails(id).then((res) => {
      if (res.data && res.data.length !== 1) return;

      const bankDetail = {
        id: res.data[0].bank_id,
        account_type_bse: res.data[0].account_type,
        account_number: res.data[0].account_number,
        branch__ifsc_code: res.data[0].ifsc,
        verification_document: res.data[0].verification_document,
      };

      setBankDetails(bankDetail);
    });
  };

  const populateInvestorDetails = (id) => {
    // Fetch investor details by ID (used when the form is marked as 'Save as Draft')
    getInvestorDetails(id).then((res) => {
      const fatca_detail = res.data?.guardian_detail?.fatca_detail;
      setInvestorType(res?.data?.investor_type)

      setMinorFirstName(res?.data?.first_name)
      setMinorLastName(res?.data?.last_name)
      setDobMinor(res?.data?.dob)
      setGender(res?.data?.gender)

      setFirstName(res?.data?.guardian_detail?.first_name);
      setLastName(res?.data?.guardian_detail?.last_name);
      setRelation(res?.data?.guardian_detail?.relation)
      setDobGuardian(res?.data?.guardian_detail?.dob)

      setAddressLine1(res?.data?.guardian_detail?.address?.address_line_1)
      setAddressLine2(res?.data?.guardian_detail?.address?.address_line_2)
      setAddressLine3(res?.data?.guardian_detail?.address?.address_line_3)
      setCityName(res?.data?.guardian_detail?.address?.city)
      setStateName(res?.data?.guardian_detail?.address?.state)
      setCountryName(res?.data?.guardian_detail?.address?.country)
      setPinCode(res?.data?.guardian_detail?.address?.pincode)

      setOccupation(fatca_detail?.occupation);
      setAnnualIncome(fatca_detail?.income_slab);
      setPlace(fatca_detail?.birth_place);
      setWealthSource(fatca_detail?.income_source);
      setBirthCountry(fatca_detail?.birth_country);
      setfatcaData(res.data);
    });
  };
  
  const populateInvestorDocuments = (id) => {
    getInvestorDocuments(id).then((res) => {
      if (res.data.length === 0) return; 

    const updatedDocuments = documentsList.map((docState) => ({
      ...docState,
      is_uploaded: res.data.some((doc) => doc.document_type === docState.document_type),
    }));

    setDocumentsList(updatedDocuments);
    });
  };

  const handleSingleDocumentsUpload = (docObj, files) => {
    const postObj = new FormData();
    postObj.append("document_type", docObj.document_type);
  
    if (Array.isArray(files)) {
      files.forEach((file) => postObj.append("file", file));
    } else {
      postObj.append("file", files);
    }
  
    postInvestorDocuments(userId, postObj)
      .then((res) => {
        if (res.status === 201) {
          setDocumentsList((prev) =>
            prev.map((doc) =>
              doc.document_type === docObj.document_type ? { ...doc, is_uploaded: true } : doc
            )
          );
          Toast.success("Document uploaded successfully!");
        }
      })
      .catch((e) => {
        const errorMessage = e.response?.data?.message || "Failed to upload document.";
        Toast.error(errorMessage);
      });
  };

  const setfatcaData = (data) => {
    setNotPoliticallyExposedCheck(!data.is_entity_politically_exposed_person);
    if(data.mobile_number && data.mobile_declaration && data.email_declaration){
      setChecked(true);
      setMobileDeclaration(true)
      setEmailDeclaration(true)
    }
    if(!data.mobile_number && data.email_declaration){
      setChecked(true);
      setEmailDeclaration(true)
    }
  };

  const onBankSubmitted = () => {
    setBankUpdated(true);
  };

  const onMinorFirstNameChange = (event) => {
    setMinorFirstName(event.target.value);
  };

  const onMinorLastNameChange = (event) => {
    setMinorLastName(event.target.value);
  };

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const onMinorDobChange = (event) => {
    setDobMinor(event.target.value);
  };

  const onGuardianDobChange = (event) => {
    setDobGuardian(event.target.value);
  };

  const handleRelationChange = (value) => {
    setRelation(value);
    errors['relation'] = '';
    setErrors(errors);
  };

  const onAddressChangeLine1 = (event) => {
    setAddressLine1(event.target.value);
  };
  const onAddressChangeLine2 = (event) => {
    setAddressLine2(event.target.value);
  };
  const onAddressChangeLine3 = (event) => {
    setAddressLine3(event.target.value);
  };
  const onCountryChange = (event) => {
    setCountryName(event.target.value);
  };
  const onCityPlaceChange = (event) => {
    setCityName(event.target.value);
  };
  const onPinCodeChange = (event) => {
    setPinCode(event.target.value);
  };
  const onStateNameChange = (event) => {
    setStateName(event.target.value);
  };

  const handleWealthSourceChange = (value) => {
    setWealthSource(value);
    errors['wealthSource'] = '';
    setErrors(errors);
  };

  const handleOccupationChange = (value) => {
    setOccupation(value);
    errors['occupation'] = '';
    setErrors(errors);
  };

  const handleGenderChange = (value) => {
    setGender(value);
    errors['gender'] = '';
    setErrors(errors);
  };

  const getConsentText = (mobile, email, firstName, lastName) => {
    const investorName =
      !firstName || !lastName ? 'me' : `${firstName} ${lastName}`;
    if (!mobile) {
      return `I confirm that email ID ${email} belong to ${investorName}.`;
    }
    return `I confirm that mobile number ${mobile} and email ID ${email} belong to ${investorName}.`;
  };

  const saveFormData = () => {
    const minorDetails = {
      firstName: minorFirstName,
      lastName: minorLastName,
      dobMinor: dobMinor,
    };
    const formData = new FormData();
    formData.append('place_of_birth', placeOfBirth);
    formData.append('country_of_birth', birthCountry);
    if (wealthSource !== undefined || !wealthSource) {
      formData.append('wealth_src', wealthSource);
    }
    formData.append('income_slab', annualIncome);
    formData.append('first_name', minorDetails.firstName);
    formData.append('last_name', minorDetails.lastName);
    formData.append('dob_minor', minorDetails.dobMinor);
    formData.append('mobile_declaration', mobileDeclaration);
    formData.append('email_declaration', emailDeclaration);
    formData.append('is_entity_politically_exposed_person', !notPoliticallyExposedCheck);
    // updateFatcaData(formData).then((res) => {
    //   if (res.data.status.code === 1) {
    //     history.push('/home');
    //   }
    // });

    const req = {
      investor_type : investorType,
      first_name: minorFirstName,
      last_name: minorLastName,
      dob: dobMinor,
      gender : gender,
      guardian_detail: {
        first_name: firstName,
        last_name: lastName,
        relation: relation,
        pan: pan,
        dob: dobGuardian,
        fatca_detail: {
          occupation: occupation,
          income_source: wealthSource,
          income_slab: annualIncome,
          birth_place: placeOfBirth,
          birth_country: birthCountry
        },
        address: {
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          address_line_3: addressLine3,
          city: cityName,
          state: stateName,
          country: countryName,
          pincode: pinCode,
        },
      },
      email_declaration: emailDeclaration ? 'guardian' : '',
      mobile_declaration: mobileDeclaration ? 'guardian' : '',
    }

    saveInvestorDetails(userId, req).then(response => {
      history.push('/home');
    }).catch(error => {
      console.error(error)
    })

    onSubmit()
  };

const onSubmit = () => {
  if (bankDetails?.id) {
    childRef.current.updateNewBank();
  } else {
    childRef.current.addNewBank();
  }
};

const submitFormData = () => {
  const minorDetails = {
    firstName: minorFirstName,
    lastName: minorLastName,
    dobMinor: dobMinor,
  };
  if (validateData()){
    const formData = new FormData();
    formData.append('place_of_birth', placeOfBirth);
    formData.append('country_of_birth', birthCountry);
    if (wealthSource !== undefined || !wealthSource) {
      formData.append('wealth_src', wealthSource);
    }
    formData.append('income_slab', annualIncome);
    formData.append('first_name', minorDetails.firstName);
    formData.append('last_name', minorDetails.lastName);
    formData.append('mobile_declaration', mobileDeclaration);
    formData.append('email_declaration', emailDeclaration);
    formData.append('is_entity_politically_exposed_person', !notPoliticallyExposedCheck);
    // updateFatcaData(formData).then((res) => {
    //   if (res.data.status.code === 1) {
    //     history.push('/home');
    //   }
    // });
  
    const req = {
      investor_type : investorType,
      first_name: minorFirstName,
      last_name: minorLastName,
      dob: dobMinor,
      gender: gender,
      guardian_detail: {
        first_name: firstName,
        last_name: lastName,
        relation: relation,
        pan: pan,
        dob: dobGuardian,
        fatca_detail: {
          occupation: occupation,
          income_source: wealthSource,
          income_slab: annualIncome,
          birth_place: placeOfBirth,
          birth_country: birthCountry
        },
        address: {
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          address_line_3: addressLine3,
          city: cityName,
          state: stateName,
          country: countryName,
          pincode: pinCode,
        },
      },
      email_declaration: emailDeclaration ? 'guardian' : '',
      mobile_declaration: mobileDeclaration ? 'guardian' : '',
    }
    if (!notPoliticallyExposedCheck) {
      setPoliticallyExposedError('Please confirm you are not politically exposed')
    }
    if (!emailDeclaration) {
      setDeclarationError(`Please confirm your Email ${mobile_number ? 'and Mobile ' : ''}Declaration.`);
    }
    if (emailDeclaration && notPoliticallyExposedCheck) {
      saveInvestorDetails(userId, req)
        .then((response) => {
          setFatcaUpdated(true);
        })
        .catch((error) => {
          console.error(error);
        });
      onSubmit();
    }
  }
};

useEffect(() => {
  if (fatcaUpdated && bankUpdated) {
    history.push('/home');
  }
}, [fatcaUpdated, bankUpdated]);

const validateData = () => {
  let errors = [];
  if (!wealthSource || wealthSource.length === 0) {
    errors['wealthSource'] = 'Wealth Source is required.';
  }
  if (!birthCountry || birthCountry.length === 0) {
    errors['birthCountry'] = 'Country of Birth is required.';
  }
  if (!placeOfBirth || placeOfBirth.length === 0) {
    setPlaceOfBirthErr('Place of Birth is required.');
  }
  setErrors(errors);
  let status = handleErrorsBank();
  if ((errors && Object.keys(errors).length > 0) || !status) {
    return false;
  }
  return true;
}

const handleErrorsBank = () => {
  let status = childRef.current.validate()
  let status1 = childRefDoc.current.validateDoc()
  if (status && status1) {
    return true
  }
  return false;
}

const onBirthPlaceChange = (event) => {
  let inputValue = event.target.value || '',
    placeErr = '';
  if (!inputValue) {
    setPlace('');
  } else if (!placeofbirthRegex.test(inputValue)) {
    placeErr = 'Please enter a Place of birth without spaces or characters like("-", "&", "@", etc)';
  } else {
    placeErr = '';
    setPlace(inputValue);
  }
  setPlaceOfBirthErr(placeErr);
};

const checkboxHandleChange = (event) => {
  const { checked } = event.target;
  setChecked(checked);
  if (checked) {
    setEmailDeclaration(true);
    if (mobile_number) {
      setMobileDeclaration(true);
    }
    setDeclarationError(undefined);
  } else {
    setEmailDeclaration(false);
    setMobileDeclaration(false);
  }
};

const politicallyExposedChange = (event) => {
  const { checked } = event.target;
  setNotPoliticallyExposedCheck(checked)
  if (checked) {
    setPoliticallyExposedError(undefined)
  }
};

const onRadioChange = (event) => {
  const { value } = event.target;
  setAnnualIncome(value);
};

return (
  <>
    <div className='d-flex'>
      <div className='col-sm-6 mt-3'>
        <InputBox
          id='minor-first-name'
          title={`Minor's First Name`}
          value={minorFirstName}
          onChange={onMinorFirstNameChange}
          onBlur={(e) => setMinorFirstName(handleInputs(e.target.value))}
          type='text'
        />
      </div>
      <div className='col-sm-6 mt-3'>
        <InputBox
          id='minor-last-name'
          title={`Minor's Last Name`}
          value={minorLastName}
          onChange={onMinorLastNameChange}
          onBlur={(e) => setMinorLastName(handleInputs(e.target.value))}
          type='text'
        />
      </div>
    </div>

    <div className='d-flex'>
      <div className='col-sm-6 mt-3'>
        <InputBox
          id='MinorDOB'
          title={`Minor's Date Of Birth`}
          value={dobMinor}
          onChange={onMinorDobChange}
          type='date'
        />
      </div>
      <div className='col-sm-6'>
        <SelectBox
          id='Gender'
          title={`Minor's Gender`}
          value={gender}
          classes={{ root: 'rounded-none' }}
          onUpdate={handleGenderChange}
          options={genderList}
          placeholder='Select gender'
          err={errors && errors['gender']}
        />
      </div>
    </div>

    <section col-sm-12>
      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='guardian-first-name'
            title={`Guardian's First Name`}
            value={firstName}
            onChange={onFirstNameChange}
            onBlur={(e) => setFirstName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='guardian-last-name'
            title={`Guardian's Last Name`}
            value={lastName}
            onChange={onLastNameChange}
            onBlur={(e) => setLastName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='MinorDOB'
            title={`Guardian's Date Of Birth`}
            value={dobGuardian}
            onChange={onGuardianDobChange}
            type='date'
          />
        </div>
        <div className='col-sm-6'>
          <SelectBox
            title={`Guardian's Relation`}
            value={relation}
            id='Relation'
            onUpdate={handleRelationChange}
            options={relationList}
            placeholder='Select relation'
            err={errors && errors['relation']}
          />
        </div>
        
      </div>

      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-address-line1'
            title={'Address Line 1'}
            value={addressLine1}
            onChange={onAddressChangeLine1}
            onBlur={(e) => setAddressLine1(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-address-line2'
            title={'Address Line 2'}
            value={addressLine2}
            onChange={onAddressChangeLine2}
            onBlur={(e) => setAddressLine2(handleInputs(e.target.value))}
            type='text'
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='onboarding-address-line3'
            title={'Address Line 3'}
            value={addressLine3}
            onChange={onAddressChangeLine3}
            onBlur={(e) => setAddressLine3(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <InputBox
            id='Pincode'
            onChange={onPinCodeChange}
            title={'Pincode'}
            value={pinCode}
            inputMode={'numeric'}
            type='number'
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className='col-sm-4 mt-3'>
          <InputBox
            id='City'
            onChange={onCityPlaceChange}
            onBlur={(e) => setCityName(handleInputs(e.target.value))}
            title='City'
            value={cityName}
            type='text'
          />
        </div>
        <div className='col-sm-4 mt-3'>
          <InputBox
            title='State'
            value={stateName}
            id='State'
            onChange={onStateNameChange}
            onBlur={(e) => setStateName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
        <div className='col-sm-4 mt-3'>
          <InputBox
            id='Country'
            title={'Country'}
            value={countryName}
            onChange={onCountryChange}
            onBlur={(e) => setCountryName(handleInputs(e.target.value))}
            type='text'
          />
        </div>
      </div>
    </section>
    

    <div className="col-sm-12 mt-3">
      <h6 className="font-weight-bold my-4">Documents</h6>
      <DocumentUpload
        kycData={kycData}
        documentList={documentsList}
        panStatus={pan_kyc_status}
        singleDocUpload={handleSingleDocumentsUpload}
        multiDocUpload={multiDocUpload}
        ref={childRefDoc}
      />
    </div>

    <section className='col-sm-12'>
      <h6 className='font-weight-bold my-4'>Bank Details</h6>
      <div className='border p-4 rounded'>
        {
          <EditBankAccount
            alignment='center'
            ref={childRef}
            buttonText='Submit'
            panStatus={pan_kyc_status}
            bankDetail={bankDetails}
            onBankSubmitted={onBankSubmitted}
            onboarding={true}
          />
        }
      </div>
    </section>

    <section className='col-sm-12'>
      <h6 className='font-weight-bold mt-4'>Details for FATCA</h6>
      <>
        <div className='border p-4 rounded w-100 my-3'>
          <SelectBox
            title='Occupation'
            value={occupation}
            id='Occupation'
            classes={{ root: 'col-sm-3 px-0 ' }}
            onUpdate={handleOccupationChange}
            options={occupationNewList}
            placeholder='Select occupation'
            err={errors && errors['occupation']}
          />
        </div>

        <div className='border p-4 rounded w-100 my-3'>
          <SelectBox
            title='Wealth Source'
            value={wealthSource}
            id='WealthSource'
            classes={{ root: 'col-sm-3 px-0 ' }}
            onUpdate={handleWealthSourceChange}
            options={incomeSource}
            placeholder='Select wealth source'
            err={errors && errors['wealthSource']}
          />
        </div>
        {/*<AnnualIncome value={annualIncome} onChange={setAnnualIncome} />*/}
        <div className='border p-4 rounded w-100 my-3'>
          <RadioGroups
            title='Annual Income'
            id='annualIncome'
            radioList={incomeSlab}
            selectedVal={annualIncome}
            onChange={onRadioChange}
          />
        </div>
        <div className='border p-4 rounded w-100 my-3'>
          <InputBox
            id='PlaceofBirth'
            className='col-sm-5'
            labelClass='opacity-75 h6'
            onChange={onBirthPlaceChange}
            title='Place of Birth'
            value={placeOfBirth}
            type='text'
            maxLength={40}
            err={placeOfBirthErr}
          />
        </div>
        <div className='border p-4 rounded w-100 my-3'>
          <SelectBox
            title='Country of Birth'
            value={birthCountry}
            id='countryBirth'
            classes={{ root: 'col-sm-3 px-0' }}
            onUpdate={setBirthCountry}
            options={[{ label: 'India', value: 'India' }]}
            placeholder='Select country of birth'
            err={errors && errors['birthCountry']}
          />
        </div>
        <div className='border p-4 rounded w-100 my-3'>
          <Form.Check>
            <Form.Check.Input
              id='declaration'
              type='checkbox'
              checked={checked}
              onChange={checkboxHandleChange}
              style={{ cursor: 'pointer' }}
            />
            <Form.Check.Label
              htmlFor='declaration'
              onChange={checkboxHandleChange}
              style={{ cursor: 'pointer' }}
            >
              {getConsentText(mobile_number, email, firstName, lastName)}
            </Form.Check.Label>
          </Form.Check>
          {declarationError && (
            <div className={'invalid-feedback d-block mt-2'}>
              {declarationError}
            </div>
          )}
          <Form.Check className={'mt-3'}>
            <Form.Check.Input
              id='politicallyExposed'
              type='checkbox'
              checked={notPoliticallyExposedCheck}
              onChange={politicallyExposedChange}
              style={{ cursor: 'pointer' }}
            />
            <Form.Check.Label
              htmlFor='politicallyExposed'
              onChange={politicallyExposedChange}
              style={{ cursor: 'pointer' }}
            >
              {'I confirm I am not politically exposed'}
            </Form.Check.Label>
          </Form.Check>
          {politicallyExposedError && (
            <div className={'invalid-feedback d-block mt-2'}>
              {politicallyExposedError}
            </div>
          )}
        </div>
        <div className=' btn-container text-center my-4  col-sm-12'>
          <button
            type='button'
            className='btn btn-outline-primary m-2'
            onClick={saveFormData}
          >
            Save As Draft
          </button>
            <button type="button" className="btn btn-primary m-2 btn-width" onClick={submitFormData}>
              Submit
            </button>
        </div>
      </>
    </section>
  </>
);
};

export default MinorOnboarding;
