export const DocumentList = {
  proprietor: {
    documents: [
      {
        document_type: 'pan',
        id: '00',
        is_multiple_upload: false,
        name: 'PAN Card',
        is_uploaded: false,
      },
    ],
  },
  llp: {
    documents: [
      {
        document_type: 'pan',
        id: '00',
        is_multiple_upload: false,
        name: 'PAN Card copy of LLP firm',
        is_uploaded: false,
      },
      {
        document_type: 'board_resolution',
        id: '01',
        is_multiple_upload: false,
        name: 'Board resolution',
        is_uploaded: false,
      },
      {
        document_type: 'signed_fatca', // What to pass
        id: '05',
        is_multiple_upload: true,
        name: 'PAN card and Address proof of all the partners',
        is_uploaded: false,
      },
    ],
  },
  partnership: {
    documents: [
      {
        document_type: 'pan',
        id: '00',
        is_multiple_upload: false,
        name: 'PAN Card copy of Partnership firm',
        is_uploaded: false,
      },
      {
        document_type: 'partnership_deed',
        id: '02',
        is_multiple_upload: false,
        name: 'Partnership Deed',
        is_uploaded: false,
      },
      {
        document_type: 'signed_fatca',
        id: '05',
        is_multiple_upload: true,
        name: 'PAN card and Address proof of all the partners',
        is_uploaded: false,
      },
    ],
  },
  private_limited: {
    documents: [
      {
        document_type: 'pan',
        id: '00',
        is_multiple_upload: false,
        name: 'PAN Card',
        is_uploaded: false,
      },
      {
        document_type: 'authorized_signatory_list',
        id: '03',
        is_multiple_upload: false,
        name: 'Authorized signatory List',
        is_uploaded: false,
      },
      {
        document_type: 'board_resolution',
        id: '01',
        is_multiple_upload: false,
        name: 'Board resolution',
        is_uploaded: false,
      },
      {
        document_type: 'signed_fatca',
        id: '05',
        is_multiple_upload: true,
        name: 'PAN and address proof of directors',
        is_uploaded: false,
      },
    ],
  },
  Bank: {
    documents: [
      {
        document_type: 'pan',
        id: '00',
        is_multiple_upload: false,
        name: 'PAN Card',
        is_uploaded: false,
      },
      {
        document_type: 'authorized_signatory_list',
        id: '03',
        is_multiple_upload: false,
        name: 'Authorized signatory List',
        is_uploaded: false,
      },
      {
        document_type: 'board_resolution',
        id: '01',
        is_multiple_upload: false,
        name: 'Board resolution',
        is_uploaded: false,
      },
      {
        document_type: 'signed_fatca',
        id: '05',
        is_multiple_upload: true,
        name: 'PAN and address proof of directors',
        is_uploaded: false,
      },
    ],
  },
  trust: {
    documents: [
      {
        document_type: 'pan',
        id: '00',
        is_multiple_upload: false,
        name: 'PAN Card',
        is_uploaded: false,
      },
      {
        document_type: 'authorized_signatory_list',
        id: '03',
        is_multiple_upload: false,
        name: 'Authorized signatory List',
        is_uploaded: false,
      },
      {
        document_type: 'board_resolution',
        id: '01',
        is_multiple_upload: false,
        name: 'Board resolution',
        is_uploaded: false,
      },
      {
        document_type: 'signed_fatca',
        id: '05',
        is_multiple_upload: true,
        name: 'PAN card and Address proof of all the partners',
        is_uploaded: false,
      },
    ],
  },
  limited: {
    documents: [
      {
        document_type: 'pan',
        id: '00',
        is_multiple_upload: false,
        name: 'PAN Card',
        is_uploaded: false,
      },
      {
        document_type: 'authorized_signatory_list',
        id: '03',
        is_multiple_upload: false,
        name: 'Authorized signatory List',
        is_uploaded: false,
      },
      {
        document_type: 'board_resolution',
        id: '01',
        is_multiple_upload: false,
        name: 'Board resolution',
        is_uploaded: false,
      },
      {
        document_type: 'signed_fatca',
        id: '05',
        is_multiple_upload: true,
        name: 'PAN and address proof of directors',
        is_uploaded: false,
      },
    ],
  },
  huf: {
    documents: [
      {
        document_type: 'pan',
        id: '00',
        is_multiple_upload: false,
        name: 'HUF PAN copy',
        is_uploaded: false,
      },
      {
        document_type: 'signed_fatca',
        id: '05',
        is_multiple_upload: true,
        name: 'PAN and address proof of members',
        is_uploaded: false,
      },
    ],
  },
};
