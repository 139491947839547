export enum ReturnsTypeEnum {
  'Absolute' = 'absolute_return',
  'XIRR' = 'xirr',
}

export enum KycStatusEnum {
  processing = 'processing',
  pending = 'pending',
  initiated = 'initiated',
  registered = 'registered',
  validated = 'validated',
  rejected = 'rejected',
}

export enum GuardianRelation {
  Father = "father",
  Mother = "mother",
}

export enum GenderEnum{
  Male = "male",
  Female = "female",
  Transgender = "transgender",
}

export enum OccupationEnum {
  Business = "business",
  Service = "service",
  Professional = "professional",
  Agriculturist = "agriculturist",
  Retired = "retired",
  Housewife = "housewife",
  Student = "student",
  Others = "others",
  Doctor = "doctor",
  PrivateSectorService = "private_sector_service",
  PublicSectorService = "public_sector_service",
  ForexDealer = "forex_dealer",
  GovernmentService = "government_service",
  UnknownOrNotApplicable = "unknown_or_not_applicable",
}

export enum IncomeSourceEnum {
  Salary = "salary",
  Business = "business_income",
  Gift = "gift",
  AncestralProperty = "ancestral_property",
  RentalIncome = "rental_income",
  PrizeMoney = "prize_money",
  Royalty = "royalty",
  Others = "others",
}

export enum IncomeSlabEnum {
  BELOW_1_LAC= "below_1_lac",
  ABOVE_1_LAC_UPTO_5_LAC= "above_1_lac_upto_5_lac",
  ABOVE_5_LAC_UPTO_10_LAC= "above_5_lac_upto_10_lac",
  ABOVE_10_LAC_UPTO_25_LAC= "above_10_lac_upto_25_lac",
  ABOVE_25_LAC_UPTO_1_CRORE= "above_25_lac_upto_1_crore",
  ABOVE_1_CRORE= "above_1_crore",
}

export enum Routes{
  Dashboard = '/home',
  Portfolio = '/my-portfolio',
  Transactions = '/transactions',
}