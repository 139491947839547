import { InputBox, RadioGroups, SelectBox } from '../../../common/form';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AnnexureAExamption, AnnexureBExamption,
  CompanyCategory,
  DocumentUpload, NatureBusiness,
  NetWorth, NonFinancialCategory,
  PoliticallyExposed,
} from '../../../common/on-boarding';
import EditBankAccount from '../../../component/bank-accounts/EditBankAccount';
import Toast from '../../../common/toast/Toast';
import taxStatus from '../../../utility/taxStatus';
import {
  incomeSlab,
  NonFinancallyListNew,
  occupationNewList,
  handleInputs
} from '../../../utility/utility';
import { DocumentList } from '../../../utility/documentsUtility';
import { getInvestorBankDetails } from '../../../api/bank';
import { getInvestorDocuments, postInvestorDocuments } from '../../../api/mfApi/documents';
import {
  getInvestorDetails,
  saveInvestorDetails,
} from '../../../api/onboarding.api';
import NominationField from '../../../component/nomination-field';
import { addNominee, updateNominee } from '../../../api/nominee';
import { TAX_STATUS } from '../../../utility/constant';
import { Form } from 'react-bootstrap';


const NonIndividualOnboarding = ({ kycData, setKycData }) => {
  const history = useHistory();
  const { pan, pan_kyc_status } = useSelector(
    (state) => state.onboardingReducer
  );
  const { user_id: userId, email, mobile_number } = useSelector((state) => state.authReducer);
  const [investorType, setInvestorType] = useState(undefined);
  const [entityName, setEntityName] = useState();
  const [fatcaUpdated, setFatcaUpdated] = useState(false);
  const [bankUpdated, setBankUpdated] = useState(false);
  const [errors, setErrors] = useState({});
  const [annualIncome, setAnnualIncome] = useState();
  const [netWorth, setNetWorth] = useState({});
  const [netWorthInr, setNetWorthInr] = useState();
  const [occupation, setOccupation] = useState();
  // TODO: UNCOMMENT IF GIIN NUMBER REQUIRED
  // const [giinNumber, setGiinNumber] = useState();
  const [occupationOther, setOccupationOther] = useState();
  const [companyCategory, setCompanyCategory] = useState();
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState();
  const [annexureA, setAnnexureA] = useState();
  const [natureBusiness, setNatureBusiness] = useState(undefined);
  const [nonFinancialEntityCategory, setNonFinancialEntityCategory] =
    useState();
  const [annexureB, setAnnexureB] = useState();
  const [bankDetails, setBankDetails] = useState();
  const [doi, setDoi] = useState();
  const [documentsList, setDocumentsList] = useState([]); // Set by using investor type
  const [checked, setChecked] = useState(false);
  const [cityName, setCityName] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [stateName, setStateName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [nomineeSubmited, setNomineeSubmited] = useState(false);
  const [nomineeDetails, setNomineeDetails] = useState();
  const [nomineeErrors, setNomineeErrors] = useState([]);
  const [mobileDeclaration, setMobileDeclaration] = useState(false);
  const [emailDeclaration, setEmailDeclaration] = useState(false);
  const [declarationError, setDeclarationError] = useState();
  const [commonNomineeError, setCommonNomineeError] = useState();

  const childRef = useRef();
  const childRefDoc = useRef();

  const handleEntityChange = (event) => {
    setEntityName(event.target.value);
  };

  const getTitle = () => {
    if (
      pan_kyc_status === taxStatus.partnership ||
      pan_kyc_status === taxStatus.llp
    ) {
      return 'Number of Partners';
    } else if (pan_kyc_status === taxStatus.huf) {
      return 'Number of HUF members';
    } else if (pan_kyc_status === taxStatus.trust) {
      return 'Number of Trustees';
    } else if (
      pan_kyc_status === taxStatus.private_limited ||
      pan_kyc_status === taxStatus.limited ||
      pan_kyc_status === taxStatus.bank_cooperative_bank
    ) {
      return 'Number of Directors';
    }
  };

  useEffect(() => {
    if (userId) {
      populateInvestorDetails(userId);
      populateInvestorBankDetails(userId);
      populateInvestorDocuments(userId);
    }
  }, [userId]);

  const populateInvestorBankDetails = (id) => {
    getInvestorBankDetails(id).then((res) => {
      if (res.data && res.data.length !== 1) return;

      const bankDetail = {
        id: res.data[0].bank_id,
        account_type_bse: res.data[0].account_type,
        account_number: res.data[0].account_number,
        branch__ifsc_code: res.data[0].ifsc,
        verification_document: res.data[0].verification_document,
      };

      setBankDetails(bankDetail);
    });
  };

  const populateInvestorDetails = (id) => {
    // Fetch investor details by ID (used when the form is marked as 'Save as Draft')
    getInvestorDetails(id).then((res) => {
      const fatcaDetails = res.data?.fatca_detail;

      // Set the initial List of the documents and prefill if already uploaded
      populateInvestorDocuments(id, res.data?.investor_type);

      // Prefill Data
      setInvestorType(res.data?.investor_type);
      setEntityName(res.data?.name);
      setDoi(res.data?.doi);
      setAnnualIncome(fatcaDetails.income_slab);
      setNetWorth({ value: fatcaDetails.net_worth_as_on_date?.split('-')[0] });
      setOccupation(fatcaDetails.occupation);
      setNetWorthInr(String(res.data.fatca_detail.net_worth)); // TODO: Streamline the datatype to number only
      setCompanyCategory(fatcaDetails.ffi_drnfe);
      setIsPoliticallyExposed(
        fatcaDetails.politically_exposed_entity ? 'true' : 'false'
      );
      setAnnexureA(fatcaDetails.exemption_code);
      setNatureBusiness(fatcaDetails.nature_of_business);
      setNonFinancialEntityCategory(fatcaDetails.non_financial_entity);
      setAnnexureB(fatcaDetails.non_financial_sub_category);
      prefillAddressDetails(res.data?.address ? res.data.address : {});
    });
  };

  const prefillAddressDetails = (address) => {
    setAddressLine1(address?.address_line_1);
    setAddressLine2(address?.address_line_2);
    setAddressLine3(address?.address_line_3);
    setCityName(address?.city);
    setStateName(address?.state);
    setCountryName(address?.country);
    setPinCode(address?.pincode);
  };

  const populateInvestorDocuments = (id, investorType) => {
    if (investorType) {
      setDocumentsList(DocumentList[investorType]['documents']);
    }
    getInvestorDocuments(id).then((res) => {
      if (res.data.length === 0) return;

      setDocumentsList((prev) => {
        return prev.map((docState) => ({
          ...docState,
          is_uploaded: res.data.some(
            (doc) => doc.document_type === docState.document_type
          ),
        }));
      });
    });
  };

  const handleSingleDocumentsUpload = (docObj, files) => {
    const postObj = new FormData();
    postObj.append('document_type', docObj.document_type);

    if (Array.isArray(files)) {
      files.forEach((file) => postObj.append('file', file));
    } else {
      postObj.append('file', files);
    }

    postInvestorDocuments(userId, postObj)
      .then((res) => {
        if (res.status === 201) {
          setDocumentsList((prev) =>
            prev.map((doc) =>
              doc.document_type === docObj.document_type
                ? { ...doc, is_uploaded: true }
                : doc
            )
          );
          Toast.success('Document uploaded successfully!');
        }
      })
      .catch((e) => {
        const errorMessage =
          e.response?.data?.message || 'Failed to upload document.';
        Toast.error(errorMessage);
      });
  };

  const onPoliticallyChange = (value) => {
    setIsPoliticallyExposed(value.value);
    errors['isPoliticallyExposed'] = undefined;
    setErrors(errors);
  };

  // const multiDocUpload = () => {
  //   setDocumentsList(prevList =>
  //     prevList.map(doc =>
  //       doc.is_multiple_upload ? { ...doc, is_uploaded: true } : doc
  //     )
  //   );
  // };

  const handleNomineeDetailChange = (data) => {
    setNomineeDetails([...data]);
    nomineeErrors.forEach((err, index) => {
      if (err && err.relation) {
        if (data[index].relation) {
          err.relation = undefined;
        }
      }
      if (err && err.share) {
        if (data[index].share) {
          err.share = undefined;
        }
      }
    });
    setCommonNomineeError(undefined);
    setNomineeErrors([...nomineeErrors]);
  };

  const updateNomineeDetails = (data) => {
    if (data?.name) {
      let request = {
        name: data.name,
        relation: data.relation,
        share: parseInt(data.share),
        minor: false,
      };
      updateNominee(userId, data.id, request).then((response) => {
        setNomineeSubmited(true);
      });
    } else {
      setNomineeSubmited(true);
    }
  };

  const addNomineeDetails = (nominees) => {
    /**
     * @param nominees - [{name: string, relation: string, share: number, minor: boolean}]
     * @returns void
     */
    if (nominees?.length > 0) {
      const payload = {
        nominees: nominees.map((nominee) => {
          return {
            name: nominee.name,
            relation: nominee.relation,
            share: parseInt(nominee.share),
            minor: false,
          };
        }),
      };
      addNominee(userId, payload).then(() => {
        setNomineeSubmited(true);
      });
    } else {
      // Not expected to reach here...
      Toast.error('Failed to submit nominee details! Please try again!');
      setNomineeSubmited(false);
    }
  };

  const saveNomineeDetails = () => {
    let shareCount = 0;
    nomineeDetails.forEach((item) => {
      shareCount = shareCount + parseInt(item.share);
    });
    if (shareCount > 100) {
      setCommonNomineeError(
        'Total of share for all nominee should be not be greater than 100%'
      );
    } else {
      const nomineesToAdded = [];

      // Update Nominee Details
      for (let i in nomineeDetails) {
        if (nomineeDetails[i].id) {
          updateNomineeDetails(nomineeDetails[i]);
        } else {
          nomineesToAdded.push(nomineeDetails[i]);
        }
      }

      // Add Nominee Details
      if (nomineesToAdded?.length > 0) {
        addNomineeDetails(nomineesToAdded);
      }
    }
  };

  const saveFormData = () => {
    let req = {
      investor_type: investorType,
      name: entityName,
      pan: pan,
      doi: doi,
      address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        address_line_3: addressLine3,
        city: cityName,
        state: stateName,
        country: countryName,
        pincode: pinCode,
      },
      fatca_detail: {
        income_slab: annualIncome,
        net_worth_as_on_date:
          netWorth.value && netWorth.value !== ''
            ? `${netWorth.value}-03-31`
            : '',
        net_worth: Number(netWorthInr),
        occupation: occupation,
        occupation_other: occupationOther,
        ffi_drnfe: companyCategory,
        // "giin_no": "giinNumber",
        politically_exposed_entity:
          isPoliticallyExposed === 'true' ? true : false,
        exemption_code: annexureA,
        nature_of_business: natureBusiness ? natureBusiness : null,
        non_financial_entity: nonFinancialEntityCategory,
        non_financial_sub_category: annexureB,
      },
      email_declaration: emailDeclaration ? 'self' : undefined,
      mobile_declaration: mobileDeclaration ? 'self' : undefined,
    };
    console.debug('PATCH Investor payload:', req);
    saveInvestorDetails(userId, req)
      .then((response) => {
        history.push('/home');
      })
      .catch((error) => {
        console.error(error);
      });

    onSubmit();
    if ([TAX_STATUS.PROPRIETORSHIP].includes(pan_kyc_status)) {
      saveNomineeDetails();
    }
  };

  const submitFormData = () => {
    // if (validate()) {
    let req = {
      investor_type: investorType,
      name: entityName,
      pan: pan,
      doi: doi,
      address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        address_line_3: addressLine3,
        city: cityName,
        state: stateName,
        country: countryName,
        pincode: pinCode,
      },
      fatca_detail: {
        income_slab: annualIncome,
        net_worth_as_on_date:
          netWorth.value && netWorth.value !== ''
            ? `${netWorth.value}-03-31`
            : '',
        net_worth: Number(netWorthInr),
        occupation: occupation,
        occupation_other: occupationOther,
        ffi_drnfe: companyCategory,
        giin_no: '', // TODO: What to pass?
        politically_exposed_entity:
          isPoliticallyExposed === 'true' ? true : false,
        exemption_code: annexureA,
        nature_of_business: natureBusiness ? natureBusiness : null,
        non_financial_entity: nonFinancialEntityCategory,
        non_financial_sub_category: annexureB,
      },
      email_declaration: emailDeclaration ? 'self' : undefined,
      mobile_declaration: mobileDeclaration ? 'self' : undefined,
    };
    saveInvestorDetails(userId, req)
      .then((response) => {
        setFatcaUpdated(true);
      })
      .catch((error) => {
        console.error(error);
      });
    onSubmit();
    if ([TAX_STATUS.PROPRIETORSHIP].includes(pan_kyc_status)) {
      saveNomineeDetails();
    }
    // }
  };

  useEffect(() => {
    if (netWorth?.value && netWorthInr?.length > 0) {
      errors['netWorth'] = undefined;
      setErrors(errors);
    }
  }, [netWorth, netWorthInr]);

  const onAddressChangeLine1 = (event) => {
    setAddressLine1(event.target.value);
  };
  const onAddressChangeLine2 = (event) => {
    setAddressLine2(event.target.value);
  };
  const onAddressChangeLine3 = (event) => {
    setAddressLine3(event.target.value);
  };
  const onCountryChange = (event) => {
    setCountryName(event.target.value);
  };
  const onCityPlaceChange = (event) => {
    setCityName(event.target.value);
  };
  const onPinCodeChange = (event) => {
    setPinCode(event.target.value);
  };
  const onStateNameChange = (event) => {
    setStateName(event.target.value);
  };
  const onDOIChange = (event) => {
    setDoi(event.target.value);
  };

  const handleOccupationChange = (value) => {
    if (value === 'others') {
      setOccupationOther('others');
    }
    setOccupation(value);
    errors['occupation'] = '';
    setErrors(errors);
  };

  // TODO: UNCOMMENT IF GIIN NUMBER REQUIRED
  // const handleGiinNumberChange = (value) => {
  //   setGiinNumber(value);
  //   errors['giin number'] = '';
  //   setErrors(errors);
  // };

  const handleAnnexureAChange = (value) => {
    setAnnexureA(value);
    errors['annexureA'] = undefined;
    setErrors(errors);
  };

  const handleAnnexureBChange = (value) => {
    setAnnexureB(value);
    errors['annexureB'] = undefined;
    setErrors(errors);
  };

  const handleNatureOfBusinessChange = (value) => {
    setNatureBusiness(value);
    errors['natureBusiness'] = undefined;
    setErrors(errors);
  };

  const handleNonFinCategoryChange = (value) => {
    setNonFinancialEntityCategory(value);
    errors['nonFinancialEntityCategory'] = undefined;
    setErrors(errors);
  };

  const handleCompCategoryChange = (value) => {
    setCompanyCategory(value);
    errors['companyCategory'] = undefined;
    setErrors(errors);
  };

  const onSubmit = () => {
    if (bankDetails?.id) {
      childRef.current.updateNewBank();
    } else {
      childRef.current.addNewBank();
    }
  };


  const onBankSubmitted = () => {
    setBankUpdated(true);
  };

  const getConsentText = (mobile, email, name) => {
    const investorName = !name ? 'me' : `${name}`;
    if (!mobile) {
      return `I confirm that email ID ${email} belong to ${investorName}.`;
    }
    return `I confirm that mobile number ${mobile} and email ID ${email} belong to ${investorName}.`;
  };

  const checkboxHandleChange = (event) => {
    const { checked } = event.target;
    setChecked(checked);
    if (checked) {
      setEmailDeclaration(true);
      if (mobile_number) {
        setMobileDeclaration(true);
      }
      setDeclarationError(undefined);
    } else {
      setEmailDeclaration(false);
      setMobileDeclaration(false);
    }
  };

  useEffect(() => {
    if (fatcaUpdated && bankUpdated) {
      history.push('/home');
    }
  }, [fatcaUpdated, bankUpdated]);

  const onRadioChange = (event) => {
    const { value } = event.target;
    setAnnualIncome(value);
  };

  return (
    <>
      <div className='col-sm-12'>
        <InputBox
          id='onboarding-name'
          title={'Name of the Entity'}
          value={entityName}
          onChange={handleEntityChange}
          onBlur={(e) => setEntityName(handleInputs(e.target.value))}
        />
      </div>

      {/* {kycData.tax_status_tcs === taxStatus.proprietor ? (
        <div className='col-sm-12'>
          <InputBox
            id='onboarding-proprietor'
            title={'Name of the Proprietor'}
            value={proprietorName}
            onChange={handleProprietorChange}
          />
        </div>
      ) : null} */}

      <section>
        <div className='d-flex'>
          <div className='col-sm-6'>
            <InputBox
              id='onboarding-address-line1'
              title={'Address Line 1'}
              value={addressLine1}
              onChange={onAddressChangeLine1}
              onBlur={(e) => setAddressLine1(handleInputs(e.target.value))}
              type='text'
            />
          </div>
          <div className='col-sm-6'>
            <InputBox
              id='onboarding-address-line2'
              title={'Address Line 2'}
              value={addressLine2}
              onChange={onAddressChangeLine2}
              onBlur={(e) => setAddressLine2(handleInputs(e.target.value))}
              type='text'
            />
          </div>
        </div>

        <div className='d-flex'>
          <div className='col-sm-6'>
            <InputBox
              id='onboarding-address-line3'
              title={'Address Line 3'}
              value={addressLine3}
              onChange={onAddressChangeLine3}
              onBlur={(e) => setAddressLine3(handleInputs(e.target.value))}
              type='text'
            />
          </div>
          <div className='col-sm-6'>
            <InputBox
              id='Pincode'
              onChange={onPinCodeChange}
              title={'Pincode'}
              value={pinCode}
              inputMode={'numeric'}
              type='number'
            />
          </div>
        </div>

        <div className='d-flex'>
          <div className='col-sm-4'>
            <InputBox
              id='City'
              onChange={onCityPlaceChange}
              onBlur={(e) => setCityName(handleInputs(e.target.value))}
              title='City'
              value={cityName}
              type='text'
            />
          </div>
          <div className='col-sm-4'>
            <InputBox
              title='State'
              value={stateName}
              id='State'
              onChange={onStateNameChange}
              onBlur={(e) => setStateName(handleInputs(e.target.value))}
              type='text'
            />
          </div>
          <div className='col-sm-4'>
            <InputBox
              id='Country'
              title={'Country'}
              value={countryName}
              onChange={onCountryChange}
              onBlur={(e) => setCountryName(handleInputs(e.target.value))}
              type='text'
            />
          </div>
        </div>
      </section>

      <div className='col-sm-12'>
        <InputBox
          id='DOI'
          title={'Date Of Incorporation'}
          value={doi}
          onChange={onDOIChange}
          type='date'
        />
      </div>

      <div className='col-sm-12 mt-3'>
        <h6 className='font-weight-bold my-4'>Documents</h6>
        <DocumentUpload
          kycData={kycData} // To detect investor type
          documentList={documentsList}
          panStatus={pan_kyc_status}
          singleDocUpload={handleSingleDocumentsUpload}
          title={getTitle()}
          ref={childRefDoc}
        />
      </div>

      <section className='col-sm-12'>
        <h6 className='font-weight-bold my-4'>Bank Details</h6>
        <div className='border p-4 rounded'>
          <EditBankAccount
            alignment='center'
            ref={childRef}
            buttonText='Submit'
            panStatus={pan_kyc_status}
            bankDetail={bankDetails}
            onBankSubmitted={onBankSubmitted}
            onboarding={true}
          />
        </div>
      </section>

      <section className='col-sm-12'>
        <h6 className='font-weight-bold mt-4'>Details for FATCA</h6>
        <div className='border p-4 rounded w-100 my-3'>
          <RadioGroups
            title='Annual Income'
            id='annualIncome'
            radioList={incomeSlab}
            selectedVal={annualIncome}
            onChange={onRadioChange}
            err={errors && errors['annualIncome']}
          />
        </div>

        <NetWorth
          networthObj={netWorth}
          amount={netWorthInr}
          onNetWorthChange={(event, data) => {
            setNetWorth(data);
          }}
          onNetWorthInrChange={setNetWorthInr}
          err={errors && errors['netWorth']}
        />

        <div className='border p-4 rounded w-100 my-3'>
          <SelectBox
            title='Occupation'
            value={occupation}
            id='Occupation'
            classes={{ root: 'col-sm-3 px-0 ' }}
            onUpdate={handleOccupationChange}
            options={occupationNewList}
            placeholder='Select occupation'
            err={errors && errors['occupation']}
          />
        </div>

        {[TAX_STATUS.PROPRIETORSHIP].includes(pan_kyc_status) && (
          <div className='border p-4 rounded w-100 my-3'>
            <NominationField
              nomineeDetailChange={handleNomineeDetailChange}
              errors={nomineeErrors}
              commonError={commonNomineeError}
              nomineeSubmited={nomineeSubmited}
            />
          </div>
        )}

        <CompanyCategory
          value={companyCategory}
          onChange={handleCompCategoryChange}
          err={errors && errors['companyCategory']}
        />

        {/* TODO: UNCOMMENT IF GIIN NUMBER REQUIRED */}
        {/* <div className="border p-4 rounded w-100 my-3">
          <InputBox
            id="giin-number"
            title={'Enter GIIN number'}
            value={giinNumber}
            onChange={handleGiinNumberChange}
          />
        </div> */}

        <PoliticallyExposed
          politicallyExposedValue={isPoliticallyExposed}
          onChange={onPoliticallyChange}
          err={errors && errors['isPoliticallyExposed']}
        />

        <AnnexureAExamption
          value={annexureA}
          onChange={handleAnnexureAChange}
          err={errors && errors['annexureA']}
        />

        <NonFinancialCategory
          options={NonFinancallyListNew}
          value={nonFinancialEntityCategory}
          onChange={handleNonFinCategoryChange}
          err={errors && errors['nonFinancialEntityCategory']}
        />

        {['active_neffe', 'passive_neffe'].includes(
          nonFinancialEntityCategory
        ) && (
          <NatureBusiness
            value={natureBusiness}
            onChange={handleNatureOfBusinessChange}
            infoIcon={true}
            infoMsg='Ex: Manufacturing, Trading, Service Industry'
            err={errors && errors['natureBusiness']}
          />
        )}
        <AnnexureBExamption
          value={annexureB}
          onChange={handleAnnexureBChange}
          err={errors && errors['annexureB']}
        />
        <div className='border p-4 rounded w-100 my-3'>
          <Form.Check>
            <Form.Check.Input
              id='declaration'
              type='checkbox'
              checked={checked}
              onChange={checkboxHandleChange}
              style={{ cursor: 'pointer' }}
            />
            <Form.Check.Label
              htmlFor='declaration'
              onChange={checkboxHandleChange}
              style={{ cursor: 'pointer' }}
            >
              {getConsentText(mobile_number, email, entityName)}
            </Form.Check.Label>
          </Form.Check>
          {declarationError && (
            <div className={'invalid-feedback d-block mt-2'}>
              {declarationError}
            </div>
          )}
        </div>

        <div className='btn-container text-center my-4 col-sm-12'>
          <button
            type='button'
            className='btn btn-outline-primary m-2'
            onClick={saveFormData}
          >
            Save As Draft
          </button>
          <button
            type='button'
            className='btn btn-primary m-2 btn-width'
            onClick={submitFormData}
          >
            Submit
          </button>
        </div>
      </section>
    </>
  );
};

export default NonIndividualOnboarding;