import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DropdownList from '../../common/form/dropdown-list/DropdownList';
import { getNominee, removeNominee } from '../../api/nominee';
import { useSelector } from 'react-redux';

const nomineeRelations = [
  { label: 'Spouse', value: 'spouse' },
  { label: 'Son', value: 'son' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Brother', value: 'brother' },
  { label: 'Sister', value: 'sister' },
  { label: 'Other', value: 'other' },
];

const NominationField = ({nomineeDetailChange, errors, commonError, nomineeSubmited}) => {
  const userId = useSelector((state) => state.authReducer.user_id);
  const [nomineeList, setNomineeList] = useState([{
    id: undefined,
    name: undefined,
    relation: undefined,
    share: undefined,
  }]);

  useEffect(() => {
    getNomineeList()
  }, [])

  useEffect(() => {

  }, [nomineeSubmited]);

  const getNomineeList = () => {
    // TODO: Integrate the new List Nominee API
    // DOCS: https://docs.tarrakki.com/#tag/nominee/operation/getInvestorNominees
    getNominee(userId).then((response) => {
      let data = response.data.results;
      if (data) {
        data.forEach((item) => {
          item.share = parseInt(item.share);
          if (item.other_relation) {
            item.others = true;
            item.otherRelation = item.other_relation;
          }
        });
        if (data && data.length > 0) {
          setNomineeList(data);
        }
      }
    });
  };

  const addNomineeField = () => {
    if(nomineeList.length <= 3) {
      nomineeList.push({
        id: undefined,
        name: undefined,
        relation: undefined,
        share: undefined,
        minor: false
      });
      setNomineeList([...nomineeList])
    }
  };

  const removeNomineeDetail = (index, id) => {
    nomineeList.splice(index, 1);
    setNomineeList([...nomineeList]);
    if (id) {
      removeNominee(userId, id).then((response) => {});
    }
  };

  const handleNomineeNameChange = (e, index) => {
    nomineeList[index].name = e.target.value;
    setNomineeList([...nomineeList])
  };

  const handleRelationChange = (selected, index) => {
    nomineeList[index].relation = selected.value;
    if(selected.value==='other'){
      nomineeList[index].others=true
    }
    else{
      nomineeList[index].others=false
    }
    setNomineeList([...nomineeList])
  };

  const handleShareChange = (e, index) => {
    let value = e.target.value;
    if(value <= 100) {
      nomineeList[index].share = e.target.value;
      setNomineeList([...nomineeList])
    } else {
      e.preventDefault();
    }
  };

  const handleRelationNameChange= (e, index) => {
    nomineeList[index].otherRelation = e.target.value;
    setNomineeList([...nomineeList])
  };

  useEffect(() => {
    nomineeDetailChange(nomineeList);
  }, [JSON.stringify(nomineeList)]);

  return (
    <div>
      {nomineeList.map((item, index) => (
        <div className={'row'}>
          <Form.Group className="col-sm-3 my-3">
            <Form.Label className=" opacity-50 mb-1">Name of nominee</Form.Label>
            <Form.Control
              type="text"
              id="nomineeName"
              data-cy="nomineeName"
              placeholder=""
              onChange={(e) => handleNomineeNameChange(e, index)}
              value={item.name}
              className={'updated-input font-weight-medium pl-0 ' + (false ? 'is-invalid' : '')}
            />
            {errors && errors[index] && errors[index]['name'] && <div className={'invalid-feedback d-block ml-0'}>{errors && errors[index] && errors[index]['name']}</div>}
          </Form.Group>
          <Form.Group className="col-sm-2 my-3">
            <Form.Label className=" opacity-50 mb-1">Relationship</Form.Label>
            <DropdownList
              selectedOption={item.relation}
              id="relation"
              data-cy="relation"
              classes={{ input: 'font-weight-medium updated-input form-control pl-0' }}
              placeholder=""
              controlFunc={(e, value) => handleRelationChange(value, index)}
              options={nomineeRelations}
            />
            {errors && errors[index] && errors[index]['relation'] && <div className={'invalid-feedback d-block'}>{errors && errors[index] && errors[index]['relation']}</div>}
          </Form.Group>
          {item.others && <Form.Group className="col-sm-3 my-3">
            <Form.Label className=" opacity-50 mb-1">Specify Relatioship</Form.Label>
            <Form.Control
              type="text"
              id="otherRelation"
              data-cy="otherRelation"
              placeholder=""
              onChange={(e) => handleRelationNameChange(e, index)}
              value={item.otherRelation}
              className={'updated-input font-weight-medium pl-0 ' + (false ? 'is-invalid' : '')}
            />
            {errors && errors[index] && errors[index]['otherRelation'] && <div className={'invalid-feedback d-block ml-0'}>{errors && errors[index] && errors[index]['otherRelation']}</div>}
          </Form.Group>}
          <Form.Group className="col-sm-1 my-3">
            <Form.Label className=" opacity-50 mb-1">Share</Form.Label>
            <div className={'d-flex'}>
              <Form.Control
                type="number"
                id="share"
                data-cy="share"
                placeholder=""
                max={100}
                min={0}
                onWheel={ event => event.currentTarget.blur() }
                onChange={(e) => handleShareChange(e, index)}
                value={item.share}
                className={'updated-input font-weight-medium text-right pl-0 ' + (errors && errors[index] && errors[index]['share'] ? 'is-invalid' : '')}
              />
              <span className={'mt-2'}>%</span>
            </div>
            {errors && errors[index] && errors[index]['share'] && <div className={'invalid-feedback d-block'}>{errors && errors[index] && errors[index]['share']}</div>}
          </Form.Group>
          {nomineeList.length > 1 &&
            <div className={"d-flex align-items-end"}>
              <button className={'btn btn-link mb-3 text-red red'} style={{color: 'red'}} title={"Remove nominee"} onClick={() => removeNomineeDetail(index, item.id)}>(-)</button>
            </div>
          }
        </div>
      ))}
      {nomineeList.length < 3 &&
        <div>
          <button className={'btn btn-link'} onClick={addNomineeField}>+ Add another nominee</button>
        </div>
      }
      {commonError && <div className={'invalid-feedback d-block'}>{commonError}</div>}
    </div>
  );
};


export default NominationField;
