import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  TOKEN_KEY,
  getLoader,
  redirectUserForAuthentication,
  removeLoader,
  removeToken,
} from '../../utility/utility';

export function isTokenInvalid(tokenStr: string) {
  try {
    const token: JwtPayload = jwtDecode(tokenStr);
    return token.exp && Date.now() >= token.exp * 1000;
  } catch (error) {
    return true;
  }
}

const createClient = (
  baseURL: string,
  headers?: {
    [key: string]: string;
  }
) => {
  const instance = axios.create({
    baseURL,
    headers,
  });

  instance.interceptors.request.use(
    function (config) {
      document.body.appendChild(getLoader()!);
      const token = localStorage.getItem(TOKEN_KEY);
      
      if (!token){
        return Promise.reject(new Error("Token not found!"));
      }

      if (token && isTokenInvalid(token)) {
        removeToken();
        redirectUserForAuthentication();
        return config;
      }

      config.headers = {
        Authorization: `Bearer ${token}`,
      };

      return config;
    },
    function (error) {
      removeLoader();
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      removeLoader();
      return res;
    },
    (error) => {
      removeLoader();
      if (error.response?.status === 401) {
        removeToken();
        redirectUserForAuthentication();
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const client = createClient(process.env.REACT_APP_FD_API_URL!);

const headersForTransactions: { [key: string]: string } = {};

export const clientForTransactions = createClient(
  process.env.REACT_APP_TARRAKKI_API_URL!,
  headersForTransactions
);

