import axios from './axios';
import elevo_apis from './elevo_apis';

const getTaxStatus = (panCard) => {
  return axios.get('/tcs/tax-status/?pan=' + panCard);
};

const kycRequestedToAdmin = (postData) => {
  return axios.post('/tcs/kyc-request-to-admin/', postData);
};
const getKycData = (options) => {
  return axios.post('/tcs/kyc-details/', options);
};
const getKycDataNri = (id) => {
  return axios.get(`elevo/investors/${id}`);
}
const kycFatcaDataNri = (options) => {
  return elevo_apis.post('/investors', options);
};
const setFatcaDataNri = (id, options) => {
  return axios.patch(`elevo/investors/${id}`, options);
};
const updateFatcaDataNri = (id, options) => {
  return axios.put(`elevo/investors/${id}`, options);
};
const getFatcaData = () => {
  return axios.get('/tcs/fatca/');
};
const updateFatcaData = (postData) => {
  return axios.post('/tcs/fatca/', postData);
};
const uploadSingleDocument = (postData) => {
  return axios.post('/tcs/user-documents/', postData);
};
const getKYCStatus = (panCard) => {
  return elevo_apis.get(`/kyc?pan=${panCard}`);
}
const uploadDocumentNri = (id, postData) => {
  return elevo_apis.post(`/investors/${id}/documents`, postData);
};
const getDocumentNri = (id) => {
  return elevo_apis.get(`/investors/${id}/documents`);
};
const updatePanAndAddresses = (id, postData) => {
  return axios.put(`/tcs/pan-address-proofs/${id}/`, postData);
};
const uploadPanAndAddresses = (postData) => {
  return axios.post('/tcs/pan-address-proofs/', postData);
};
const getPanAndAddresses = () => {
  return axios.get('/tcs/pan-address-proofs/');
};
const getSignzyUrl = (userId) => {
  return axios.get(`/ekyc/create-investor/${userId}/`);
};
const getInvestorStatus = (id) => {
  return elevo_apis.get(`/investors/${id}/status`);
};
const sendMessage = (postData) => {
  return axios.post('/users/message/', postData);
};

export {
  getPanAndAddresses,
  getTaxStatus,
  getKYCStatus,
  kycRequestedToAdmin,
  getKycData,
  uploadSingleDocument,
  updatePanAndAddresses,
  uploadPanAndAddresses,
  getFatcaData,
  updateFatcaData,
  getSignzyUrl,
  kycFatcaDataNri,
  updateFatcaDataNri,
  getKycDataNri,
  uploadDocumentNri,
  getDocumentNri,
  setFatcaDataNri,
  getInvestorStatus,
  sendMessage,
};
